import {
  DEAL_TERMS_ATTRIBUTION_KEYS,
  ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEYS,
} from "@frontend/common";

import {
  DealDirectionValue,
  DealTermsContent,
} from "@EditArticleContainer/shared/types/paragraphContents/contents/dealTermsContent.types";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

const getTargetPartnerLicenseeTable = (
  dealTerms: DealTermsContent,
  dealDirection: DealDirectionValue,
) => {
  const LICENSEE_TABLE_PATH =
    dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable];
  const LICENSEE_TABLE_INDEX =
    LICENSEE_TABLE_PATH.findIndex(
      (licenseeData) =>
        licenseeData[DEAL_TERMS_ATTRIBUTION_KEYS?.attributionInfo] &&
        licenseeData[DEAL_TERMS_ATTRIBUTION_KEYS?.attributionInfo]?.[
          DEAL_TERMS_ATTRIBUTION_KEYS.dealDirection
        ] === dealDirection,
    ) || 0;

  return LICENSEE_TABLE_PATH.at(LICENSEE_TABLE_INDEX);
};

export const licenseeNameText = (
  dealTerms: DealTermsContent,
  dealDirection: DealDirectionValue,
) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms, dealDirection)?.[
        DEAL_TERMS_KEYS.licenseeName
      ]?.value?.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeResponsibilityText = (
  dealTerms: DealTermsContent,
  dealDirection: DealDirectionValue,
) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms, dealDirection)?.[
        DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms
      ][DEAL_TERMS_KEYS.licenseeResponsibilityRightsScLa]?.value?.map(
        (item) => item.text ?? "",
      ) ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeUpfrontText = (
  dealTerms: DealTermsContent,
  dealDirection: DealDirectionValue,
) =>
  getTargetPartnerLicenseeTable(dealTerms, dealDirection)?.[
    DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms
  ][DEAL_TERMS_KEYS.upfrontPayment].value.at(0)?.text ?? "";

export const licenseeMilestoneText = (
  dealTerms: DealTermsContent,
  dealDirection: DealDirectionValue,
) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms, dealDirection)?.[
        DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms
      ][DEAL_TERMS_KEYS.milestonePaymentAndCondition].map((item) =>
        [
          item[DEAL_TERMS_KEYS.milestonePayment].value.at(0)?.text?.trim() ??
            "",
          item[DEAL_TERMS_KEYS.milestoneConditionTitle].value
            ?.at(0)
            ?.text?.trim()
            ? `(${item[DEAL_TERMS_KEYS.milestoneConditionTitle].value
                .at(0)
                ?.text?.trim()})`
            : "",
        ]
          .filter((text) => text !== "")
          .join(" "),
      ) ?? [],
    parseString: (text) => `- ${text}`,
    bulletStartIndex: 1,
  });

export const licenseeOtherPaymentText = (
  dealTerms: DealTermsContent,
  dealDirection: DealDirectionValue,
) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms, dealDirection)?.[
        DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms
      ][DEAL_TERMS_KEYS.otherPaymentAndDetailScLa]?.map((item) =>
        [
          item[DEAL_TERMS_KEYS.otherPaymentScLa].value.at(0)?.text?.trim() ??
            "",
          item[DEAL_TERMS_KEYS.otherPaymentDetailScLa]?.value
            ?.at(0)
            ?.text?.trim()
            ? `(${item[DEAL_TERMS_KEYS.otherPaymentDetailScLa]?.value
                ?.at(0)
                ?.text?.trim()})`
            : "",
        ]
          .filter((text) => text !== "")
          .join(" "),
      ) ?? [],
    parseString: (text) => `- ${text}`,
    bulletStartIndex: 1,
  });

export const licenseeTotalPriceText = (
  dealTerms: DealTermsContent,
  dealDirection: DealDirectionValue,
) =>
  getTargetPartnerLicenseeTable(dealTerms, dealDirection)?.[
    DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms
  ][DEAL_TERMS_KEYS.totalPaymentScLa].value.at(0)?.text ?? "";

export const licenseeRoyaltyText = (
  dealTerms: DealTermsContent,
  dealDirection: DealDirectionValue,
) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms, dealDirection)?.[
        DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms
      ][DEAL_TERMS_KEYS.royalty].value.map((item) => item.text ?? "") ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });

export const licenseeOtherFinancialTermsText = (
  dealTerms: DealTermsContent,
  dealDirection: DealDirectionValue,
) =>
  createMultiLineText({
    textList:
      getTargetPartnerLicenseeTable(dealTerms, dealDirection)?.[
        DEAL_TERMS_KEYS.strategicCollaborationLicenseAgreementTerms
      ][DEAL_TERMS_KEYS.otherFinancialTermsScLa].value.map(
        (item) => item.text ?? "",
      ) ?? [],
    parseString: (text) => `- ${text?.trim()}`,
  });
