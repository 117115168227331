import React, { useEffect } from "react";

import {
  CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS,
  LICENSE_AGREEMENT_SHELL_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import {
  DefaultShellTitle,
  TableCreatorTextFieldContainer,
} from "@FillInTableContainer/shared";
import useCheckPressedShell from "@FillInTableContainer/shared/hooks/useCheckPressedShell";
import { useGetInfoOfShell } from "@FillInTableContainer/shared/hooks/useGetInfoOfShell";
import {
  getShellPlaceholder,
  isRequiredShell,
} from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getShellTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { padStart } from "@utils/padStart/padStart";

import * as S from "./DealTermsCrossLicenseShell.style";
import { useCreateCrossLicenseShell } from "./hooks/useCreateCrossLicenseShell";
import { useGetNumberOfCrossLicenseSupGroupShell } from "../../hooks/useGetNumberOfCrossLicenseSubGroupShell";

export interface PositionInfoProps {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  groupShellIndex: number;
  subGroupShellIndex: number;
  subGroupShellKey: string | null;
  tableKey: string | null;
  tableIndex: number;
  shellKey: string;
  shellIndex: number;
}

interface Props extends PositionInfoProps {
  subGroupShellIndex: number;
  subGroupShellKey: string | null;

  isFocusedGroupShell: boolean;
  isClearGroupShell: boolean;
  isDeleteGroupShell: boolean;
  isAlertGroupShell: boolean;

  isClearSubGroupShell: boolean;
  isDeleteSubGroupShell: boolean;
}

const DealTermsCrossLicenseShell = ({
  groupTableKey,
  groupTableIndex,
  groupShellKey,
  groupShellIndex,
  tableKey,
  shellKey,
  tableIndex,
  shellIndex,
  subGroupShellIndex,
  subGroupShellKey,
  isClearGroupShell,
  isDeleteGroupShell,
  isAlertGroupShell,
  isClearSubGroupShell,
  isDeleteSubGroupShell,
}: Props) => {
  const positionPropsInfo: PositionInfoProps = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableKey,
    tableIndex,
    shellKey,
    shellIndex,
  };

  const {
    isFocused,
    isClearShell,
    isEnableEdit,
    isAlertShell,
    focusStatus,
    columnSize,
    isClearTable,
    isDeleteTable,
    handleFocusTextFieldWrapper,
    handleClickToDeleteShell,
    handleFocusOutShell,
  } = useCreateCrossLicenseShell(positionPropsInfo);
  const { isPressed, handleChangePressedStatus } = useCheckPressedShell();
  const { subGroupShellCount } = useGetNumberOfCrossLicenseSupGroupShell();

  const articleSection = useArticleSection();
  const { shellValue } = useGetInfoOfShell(groupTableKey);
  const { register } = useScroll();

  const customTableTitle = () => {
    const LICENSING_PARTNER = "Licensing Partner";

    const isLicenseeNameShell =
      shellKey === LICENSE_AGREEMENT_SHELL_KEYS.licenseeName;
    const isPartnerAGroupShell =
      groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.licensingPartnerA;
    const isPartnerBGroupShell =
      groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.licensingPartnerB;

    if (!isLicenseeNameShell) return getShellTitle(articleSection, shellKey);

    if (isPartnerAGroupShell) return `${LICENSING_PARTNER} A`;
    if (isPartnerBGroupShell) return `${LICENSING_PARTNER} B`;

    return getShellTitle(articleSection, shellKey);
  };

  useEffect(() => {
    const handleCheckClickOutsideShell = () => {
      if (isFocused) handleFocusOutShell();
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleFocusOutShell, isFocused]);

  const isMultipleSubGroupShell =
    subGroupShellCount(subGroupShellKey, tableIndex, groupShellIndex) > 1;

  // NOTE: 유효셩 검사 시 scroll 이벤트 매칭을 위해 GroupShellKey를 다른 DealType과 동일하게 null로 설정
  return (
    <S.ShellWrapper
      column={columnSize}
      {...register(
        `${groupTableKey}__${groupTableIndex}__${tableKey}__${tableIndex}__${null}__${groupShellIndex}__${shellKey}__${shellIndex}`,
      )}
    >
      {isClearShell && isEnableEdit && (
        <S.DeleteTableButtonWrapper>
          <S.DeleteTableButton type="button" onClick={handleClickToDeleteShell}>
            <img src={IconXMark} alt="button for clear" />
            {focusStatus.type === "clear" ? "Clear" : null}
          </S.DeleteTableButton>
        </S.DeleteTableButtonWrapper>
      )}

      <S.ShellBorderWrapper
        isFocused={isFocused && focusStatus.type !== "dragging"}
        isClearStatus={
          isClearShell ||
          isClearGroupShell ||
          isClearSubGroupShell ||
          isClearTable
        }
        isDeleteStatus={
          isDeleteGroupShell || isDeleteSubGroupShell || isDeleteTable
        }
        isAlertStatus={isAlertShell || isAlertGroupShell}
        isDisableEdit={!isEnableEdit}
        $isDragging={isFocused && focusStatus.type === "dragging"}
        $isPressed={isPressed}
        onClick={handleFocusTextFieldWrapper}
        onMouseDown={(e) => handleChangePressedStatus(e, true)}
        onMouseUp={(e) => handleChangePressedStatus(e, false)}
      >
        <DefaultShellTitle
          isFocused={isFocused}
          isClearShell={isClearShell}
          isAlertShell={isAlertShell}
          isRequiredShell={isRequiredShell(articleSection, shellKey)}
          shellTitle={customTableTitle()}
          shellTitleNumbering={
            isMultipleSubGroupShell ? `${padStart(subGroupShellIndex + 1)}` : ""
          }
          columnSize={columnSize}
        />

        <TableCreatorTextFieldContainer
          placeholder={
            getShellPlaceholder(articleSection, shellKey) ||
            getShellTitle(articleSection, shellKey)
          }
          shellValue={shellValue(
            shellKey,
            tableIndex,
            groupShellIndex,
            shellIndex,
            subGroupShellIndex,
          )}
          shellKey={shellKey}
          shellIndex={shellIndex}
          groupTableKey={groupTableKey}
          groupTableIndex={groupTableIndex}
          groupShellKey={groupShellKey}
          groupShellIndex={groupShellIndex}
          subGroupShellKey={subGroupShellKey}
          subGroupShellIndex={subGroupShellIndex}
          tableKey={tableKey}
          tableIndex={tableIndex}
        />
      </S.ShellBorderWrapper>
    </S.ShellWrapper>
  );
};

export default DealTermsCrossLicenseShell;
