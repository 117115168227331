import { DISABLED_PHASE_VALUES } from "@frontend/common";

import ArticleDetailIndex from "@EditArticleContainer/shared/components/ArticleDetailParagraphIndex/ArticleDetailParagraphIndex";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";

import DrugProfileTableGroup from "./components/DrugProfileTableGroup/DrugProfileTableGroup";
import { checkDrugProfileValue } from "./components/DrugProfileTableGroup/utils/checkDrugProfileValue";
import { convertPhaseToDevelopmentStage } from "./components/DrugProfileTableGroup/utils/convertPhaseToDevelopmentStage";
import * as S from "./VentureCapitalParagraphDrugProfile.style";
import { checkAllDrugProfileTableFieldsEmpty } from "../../../utils/checkAllDrugProfileTableFieldsEmpty";

const PARAGRAPH_TITLE = "Drug Profile";

const VentureCapitalParagraphDrugProfile = () => {
  const paragraph = useParagraphEditedData("TC503");
  const isCheckValue = checkDrugProfileValue(paragraph);

  if (!paragraph) return null;
  if (!isCheckValue) return null;

  const isHideRowByStage = paragraph.text["Drug profile"]
    .map(
      (drugProfileRow) =>
        drugProfileRow?.["Development Stage"]?.value?.map(
          (item) => item.text ?? "",
        ) ||
        drugProfileRow?.Phase?.value?.map(
          (item) => convertPhaseToDevelopmentStage(item.text ?? "") ?? "",
        ) ||
        [],
    )
    .map((itemList) =>
      itemList.filter((item) =>
        DISABLED_PHASE_VALUES.includes(item.toLocaleLowerCase()),
      ),
    )
    .every((item) => item.length > 0);

  const isAllDrugProfileTableEmpty =
    checkAllDrugProfileTableFieldsEmpty(paragraph);

  const isHideDrugProfileSection = !!(
    isHideRowByStage === true || isAllDrugProfileTableEmpty === true
  );

  return (
    <S.ParagraphDrugProfileWrapper
      className={`is-hide-drug-profile-table-${isHideDrugProfileSection}`}
    >
      <ArticleDetailIndex title={PARAGRAPH_TITLE} />
      <S.ParagraphDrugProfileContentWrapper>
        <DrugProfileTableGroup
          key={paragraph.updateDt}
          drugProfile={paragraph}
        />
      </S.ParagraphDrugProfileContentWrapper>
    </S.ParagraphDrugProfileWrapper>
  );
};

export default VentureCapitalParagraphDrugProfile;
