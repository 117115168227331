import {
  DEAL_TERMS_DIRECTION_VALUE,
  ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEYS,
} from "@frontend/common";
import dayjs from "dayjs";

import { RIGHTS_SCOPE_INDEX_POS } from "@EditArticleContainer/components/EditArticle/components/ArticleDetailBody/components/ParagraphDealTerms/hooks/useCreateRightsTables";
import { useArticleContentRefMap } from "@EditArticleContainer/shared/contexts/ArticleContentRefContext";
import {
  REF_CODE_ACQUIRED_COMPANY_NAME,
  REF_CODE_ACQUIRED_COMPANY_RESPONSIBILITY,
  REF_CODE_ACQUIRED_COMPANY_RIGHTS,
  REF_CODE_ACQUIRING_COMPANY_NAME,
  REF_CODE_ACQUIRING_COMPANY_RESPONSIBILITY,
  REF_CODE_DEAL_CONTINGENT_MILESTONE_MA,
  REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
  REF_CODE_DEAL_CONTINGENT_PAYMENT_MA,
  REF_CODE_DEAL_CONTINGENT_PRICE_PER_SHARE_MA,
  REF_CODE_DEAL_MERGER_FINANCIAL_TERMS_MA,
  REF_CODE_DEAL_OTHER_PAYMENT_SC_LA,
  REF_CODE_DEAL_UPFRONT_PAYMENT_MA,
  REF_CODE_DEAL_UPFRONT_PRICE_PER_SHARE_MA,
  REF_CODE_FINANCIAL_TERMS,
  REF_CODE_KEY_ASSETS,
  REF_CODE_LICENSEE_DRUG,
  REF_CODE_LICENSEE_EXCLUSIVITY,
  REF_CODE_LICENSEE_INDICATION,
  REF_CODE_LICENSEE_NAME,
  REF_CODE_LICENSEE_RESPONSIBILITY,
  REF_CODE_LICENSEE_TERRITORY,
  REF_CODE_LICENSOR_NAME,
  REF_CODE_LICENSOR_RESPONSIBILITY,
  REF_CODE_LICENSOR_RIGHTS,
  REF_CODE_MILESTONE,
  REF_CODE_NUMBER_OF_ACQUIRING_SHARES,
  REF_CODE_ORIGINATOR_NAME,
  REF_CODE_ORIGINATOR_RESPONSIBILITY,
  REF_CODE_ORIGINATOR_RIGHTS,
  REF_CODE_OTHER_FINANCIAL_TERMS,
  REF_CODE_PARTNER_NAME,
  REF_CODE_PARTNER_RESPONSIBILITY,
  REF_CODE_PRICE_PER_SHARE,
  REF_CODE_RIGHTS_SCOPE,
  REF_CODE_ROYALTY,
  REF_CODE_SEPARATOR,
  REF_CODE_TOTAL_PRICE,
  REF_CODE_TOTAL_PRICE_UP_TO,
  REF_CODE_UPFRONT,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import { DealTermsContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/dealTermsContent.types";
import {
  createContentFromHTML,
  createEmptyContent,
  findRefCodeAndCreateContentFromHTML,
} from "@EditArticleContainer/shared/utils/getValueFromRefMap";
import { createIndexList } from "@utils/createIndexList";

const LICENSEE_TABLE_INDEX_POS = 1;

export const useCreateDealTermsContent = () => {
  const refMap = useArticleContentRefMap();
  const { partnerA, partnerB } = DEAL_TERMS_DIRECTION_VALUE;

  return async () => {
    const licenseeTableLength =
      [...refMap.keys()]
        .filter((key) => key.includes(REF_CODE_LICENSEE_NAME))
        .map((key) =>
          Number(key.split(REF_CODE_SEPARATOR)[LICENSEE_TABLE_INDEX_POS]),
        )
        .reduce((acc, cur) => (cur > acc ? cur : acc), 0) + 1;

    const isSingleLicenseeTable = licenseeTableLength === 1;
    const rightsScopeLength =
      [...refMap.keys()]
        .filter((key) => key.includes(REF_CODE_RIGHTS_SCOPE))
        .map((key) =>
          Number(key.split(REF_CODE_SEPARATOR)[RIGHTS_SCOPE_INDEX_POS]),
        )
        .reduce((acc, cur) => (cur > acc ? cur : acc), 0) + 1;

    const rightsScopeDrugLength = (rightsScopeIndex: number) =>
      [...refMap.keys()]
        .filter((key) =>
          key.includes(
            `${REF_CODE_RIGHTS_SCOPE}||${REF_CODE_LICENSEE_DRUG}||${rightsScopeIndex}`,
          ),
        )
        .map((key) => Number(key.split(REF_CODE_SEPARATOR)[3]))
        .reduce((acc, cur) => (cur > acc ? cur : acc), 0) + 1;
    const rightsScopeIndexList = createIndexList(rightsScopeLength);
    const licenseeTableIndexList = createIndexList(licenseeTableLength);
    const rightsScopeDrugIndexList = (rightsScopeIndex: number) =>
      createIndexList(rightsScopeDrugLength(rightsScopeIndex));

    const contingentPaymentGroupLength = [...refMap.keys()].filter((key) =>
      key.includes(
        `${REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP}||${REF_CODE_DEAL_CONTINGENT_PAYMENT_MA}`,
      ),
    ).length;
    const contingentPaymentGroupIndexList = createIndexList(
      contingentPaymentGroupLength,
    );

    const content: DealTermsContent = {
      text: {
        "Deal Terms": {
          "Licensor Table": {
            "Licensor Name": findRefCodeAndCreateContentFromHTML({
              refMap,
              candidateRefCodeList: [
                REF_CODE_LICENSOR_NAME,
                REF_CODE_ACQUIRED_COMPANY_NAME,
                REF_CODE_ORIGINATOR_NAME,
              ],
            }),
            "Licensor Responsibility & Rights":
              findRefCodeAndCreateContentFromHTML({
                refMap,
                candidateRefCodeList: [
                  REF_CODE_LICENSOR_RESPONSIBILITY,
                  REF_CODE_ACQUIRED_COMPANY_RESPONSIBILITY,
                  REF_CODE_ORIGINATOR_RESPONSIBILITY,
                ],
              }),
            "Licensor Rights": findRefCodeAndCreateContentFromHTML({
              refMap,
              candidateRefCodeList: [
                REF_CODE_ORIGINATOR_RIGHTS,
                REF_CODE_LICENSOR_RIGHTS,
                REF_CODE_ACQUIRED_COMPANY_RIGHTS,
              ],
            }),
          },

          "Licensee Table": isSingleLicenseeTable
            ? [
                {
                  "Licensee Name": findRefCodeAndCreateContentFromHTML({
                    refMap,
                    candidateRefCodeList: [
                      REF_CODE_LICENSEE_NAME,
                      REF_CODE_ACQUIRING_COMPANY_NAME,
                      REF_CODE_PARTNER_NAME,
                    ],
                  }),
                  "Merge & Acquisition terms": {
                    "Licensee Responsibility & Rights (MA)":
                      createContentFromHTML(
                        refMap,
                        REF_CODE_ACQUIRING_COMPANY_RESPONSIBILITY,
                      ),
                    "Number of acquiring shares": createContentFromHTML(
                      refMap,
                      REF_CODE_NUMBER_OF_ACQUIRING_SHARES,
                    ),
                    "Other Financial Terms (MA)": createContentFromHTML(
                      refMap,
                      REF_CODE_OTHER_FINANCIAL_TERMS,
                    ),
                    "Price Per Share": createContentFromHTML(
                      refMap,
                      REF_CODE_PRICE_PER_SHARE,
                    ),
                    "Total Payment (MA)": createContentFromHTML(
                      refMap,
                      REF_CODE_TOTAL_PRICE,
                    ),
                    "Key Assets And Indication": createContentFromHTML(
                      refMap,
                      REF_CODE_KEY_ASSETS,
                    ),
                    [DEAL_TERMS_KEYS.mergerFinancialTermsMa]:
                      createContentFromHTML(
                        refMap,
                        REF_CODE_DEAL_MERGER_FINANCIAL_TERMS_MA,
                      ),
                    [DEAL_TERMS_KEYS.upfrontPaymentMa]: createContentFromHTML(
                      refMap,
                      REF_CODE_DEAL_UPFRONT_PAYMENT_MA,
                    ),
                    [DEAL_TERMS_KEYS.upfrontPricePerShareMa]:
                      createContentFromHTML(
                        refMap,
                        REF_CODE_DEAL_UPFRONT_PRICE_PER_SHARE_MA,
                      ),
                    [DEAL_TERMS_KEYS.contingentPayment]:
                      contingentPaymentGroupIndexList?.map((index) => ({
                        [DEAL_TERMS_KEYS.contingentPaymentMa]:
                          createContentFromHTML(
                            refMap,
                            createRefCode(
                              REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
                              REF_CODE_DEAL_CONTINGENT_PAYMENT_MA,
                              index,
                            ),
                          ),
                        [DEAL_TERMS_KEYS.contingentPricePerShareMa]:
                          createContentFromHTML(
                            refMap,
                            createRefCode(
                              REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
                              REF_CODE_DEAL_CONTINGENT_PRICE_PER_SHARE_MA,
                              index,
                            ),
                          ),
                        [DEAL_TERMS_KEYS.contingentMilestoneMa]:
                          createContentFromHTML(
                            refMap,
                            createRefCode(
                              REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
                              REF_CODE_DEAL_CONTINGENT_MILESTONE_MA,
                              index,
                            ),
                          ),
                      })),
                  },
                  "Strategic Collaboration & License Agreement terms": {
                    "Licensee Responsibility & Rights (SC & LA)":
                      findRefCodeAndCreateContentFromHTML({
                        refMap,
                        candidateRefCodeList: [
                          REF_CODE_LICENSEE_RESPONSIBILITY,
                          REF_CODE_PARTNER_RESPONSIBILITY,
                        ],
                      }),
                    "Other Financial Terms (SC & LA)":
                      findRefCodeAndCreateContentFromHTML({
                        refMap,
                        candidateRefCodeList: [
                          REF_CODE_FINANCIAL_TERMS,
                          REF_CODE_OTHER_FINANCIAL_TERMS,
                        ],
                      }),
                    "Total Payment (SC & LA)": createContentFromHTML(
                      refMap,
                      REF_CODE_TOTAL_PRICE_UP_TO,
                    ),
                    "Upfront Payment": createContentFromHTML(
                      refMap,
                      REF_CODE_UPFRONT,
                    ),
                    Royalty: createContentFromHTML(refMap, REF_CODE_ROYALTY),
                    // NOTE: Milestone은 첫번째 행만 사용하고, 나머지는 사용하지 않는다.
                    //       따라서 "Milestone Condition Title"은 빈 값으로 초기화한다.
                    "Milestone Payment and Condition": [
                      {
                        "Milestone Payment": createContentFromHTML(
                          refMap,
                          REF_CODE_MILESTONE,
                        ),
                        "Milestone Condition Title": createEmptyContent(),
                      },
                    ],
                    [DEAL_TERMS_KEYS.otherPaymentAndDetailScLa]: [
                      {
                        [DEAL_TERMS_KEYS.otherPaymentScLa]:
                          createContentFromHTML(
                            refMap,
                            REF_CODE_DEAL_OTHER_PAYMENT_SC_LA,
                          ),
                        [DEAL_TERMS_KEYS.otherPaymentDetailScLa]:
                          createEmptyContent(),
                      },
                    ],
                  },
                  "Rights Scope": rightsScopeIndexList.map((index) => ({
                    Drug: rightsScopeDrugIndexList(index).map((_, drugIndex) =>
                      createContentFromHTML(
                        refMap,
                        createRefCode(
                          REF_CODE_RIGHTS_SCOPE,
                          REF_CODE_LICENSEE_DRUG,
                          index,
                          drugIndex,
                        ),
                      ),
                    ),
                    Indication: rightsScopeDrugIndexList(index).map(
                      (_, indicationIndex) =>
                        createContentFromHTML(
                          refMap,
                          createRefCode(
                            REF_CODE_RIGHTS_SCOPE,
                            REF_CODE_LICENSEE_INDICATION,
                            index,
                            indicationIndex,
                          ),
                        ),
                    ),
                    Exclusivity: createContentFromHTML(
                      refMap,
                      createRefCode(
                        REF_CODE_RIGHTS_SCOPE,
                        REF_CODE_LICENSEE_EXCLUSIVITY,
                        index,
                      ),
                    ),
                    Territory: createContentFromHTML(
                      refMap,
                      createRefCode(
                        REF_CODE_RIGHTS_SCOPE,
                        REF_CODE_LICENSEE_TERRITORY,
                        index,
                      ),
                    ),
                  })),
                },
              ]
            : licenseeTableIndexList.map((tableIndex) => {
                return {
                  "Licensee Name": findRefCodeAndCreateContentFromHTML({
                    refMap,
                    candidateRefCodeList: [
                      createRefCode(REF_CODE_LICENSEE_NAME, tableIndex),
                      REF_CODE_ACQUIRING_COMPANY_NAME,
                      REF_CODE_PARTNER_NAME,
                    ],
                  }),
                  "Merge & Acquisition terms": {
                    "Licensee Responsibility & Rights (MA)":
                      createContentFromHTML(
                        refMap,
                        REF_CODE_ACQUIRING_COMPANY_RESPONSIBILITY,
                      ),
                    "Number of acquiring shares": createContentFromHTML(
                      refMap,
                      REF_CODE_NUMBER_OF_ACQUIRING_SHARES,
                    ),
                    "Other Financial Terms (MA)": createContentFromHTML(
                      refMap,
                      REF_CODE_OTHER_FINANCIAL_TERMS,
                    ),
                    "Price Per Share": createContentFromHTML(
                      refMap,
                      REF_CODE_PRICE_PER_SHARE,
                    ),
                    "Total Payment (MA)": createContentFromHTML(
                      refMap,
                      REF_CODE_TOTAL_PRICE,
                    ),
                    "Key Assets And Indication": createContentFromHTML(
                      refMap,
                      REF_CODE_KEY_ASSETS,
                    ),
                    [DEAL_TERMS_KEYS.mergerFinancialTermsMa]:
                      createContentFromHTML(
                        refMap,
                        REF_CODE_DEAL_MERGER_FINANCIAL_TERMS_MA,
                      ),
                    [DEAL_TERMS_KEYS.upfrontPaymentMa]: createContentFromHTML(
                      refMap,
                      REF_CODE_DEAL_UPFRONT_PAYMENT_MA,
                    ),
                    [DEAL_TERMS_KEYS.upfrontPricePerShareMa]:
                      createContentFromHTML(
                        refMap,
                        REF_CODE_DEAL_UPFRONT_PRICE_PER_SHARE_MA,
                      ),
                    [DEAL_TERMS_KEYS.contingentPayment]:
                      contingentPaymentGroupIndexList?.map((index) => ({
                        [DEAL_TERMS_KEYS.contingentPaymentMa]:
                          createContentFromHTML(
                            refMap,
                            createRefCode(
                              REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
                              REF_CODE_DEAL_CONTINGENT_PAYMENT_MA,
                              index,
                            ),
                          ),
                        [DEAL_TERMS_KEYS.contingentPricePerShareMa]:
                          createContentFromHTML(
                            refMap,
                            createRefCode(
                              REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
                              REF_CODE_DEAL_CONTINGENT_PRICE_PER_SHARE_MA,
                              index,
                            ),
                          ),
                        [DEAL_TERMS_KEYS.contingentMilestoneMa]:
                          createContentFromHTML(
                            refMap,
                            createRefCode(
                              REF_CODE_DEAL_CONTINGENT_PAYMENT_GROUP,
                              REF_CODE_DEAL_CONTINGENT_MILESTONE_MA,
                              index,
                            ),
                          ),
                      })),
                  },
                  "Strategic Collaboration & License Agreement terms": {
                    "Licensee Responsibility & Rights (SC & LA)":
                      findRefCodeAndCreateContentFromHTML({
                        refMap,
                        candidateRefCodeList: [
                          createRefCode(
                            REF_CODE_LICENSEE_RESPONSIBILITY,
                            tableIndex,
                          ),
                          REF_CODE_PARTNER_RESPONSIBILITY,
                        ],
                      }),
                    "Other Financial Terms (SC & LA)":
                      findRefCodeAndCreateContentFromHTML({
                        refMap,
                        candidateRefCodeList: [
                          createRefCode(REF_CODE_FINANCIAL_TERMS, tableIndex),
                          createRefCode(
                            REF_CODE_OTHER_FINANCIAL_TERMS,
                            tableIndex,
                          ),
                        ],
                      }),
                    "Total Payment (SC & LA)": createContentFromHTML(
                      refMap,
                      createRefCode(REF_CODE_TOTAL_PRICE_UP_TO, tableIndex),
                    ),
                    "Upfront Payment": createContentFromHTML(
                      refMap,
                      createRefCode(REF_CODE_UPFRONT, tableIndex),
                    ),
                    Royalty: createContentFromHTML(
                      refMap,
                      createRefCode(REF_CODE_ROYALTY, tableIndex),
                    ),
                    // NOTE: Milestone은 첫번째 행만 사용하고, 나머지는 사용하지 않는다.
                    //       따라서 "Milestone Condition Title"은 빈 값으로 초기화한다.
                    "Milestone Payment and Condition": [
                      {
                        "Milestone Payment": createContentFromHTML(
                          refMap,
                          createRefCode(REF_CODE_MILESTONE, tableIndex),
                        ),
                        "Milestone Condition Title": createEmptyContent(),
                      },
                    ],
                    [DEAL_TERMS_KEYS.otherPaymentAndDetailScLa]: [
                      {
                        [DEAL_TERMS_KEYS.otherPaymentScLa]:
                          createContentFromHTML(
                            refMap,
                            createRefCode(
                              REF_CODE_DEAL_OTHER_PAYMENT_SC_LA,
                              tableIndex,
                            ),
                          ),
                        [DEAL_TERMS_KEYS.otherPaymentDetailScLa]:
                          createEmptyContent(),
                      },
                    ],
                  },
                  "Rights Scope": rightsScopeIndexList.map((index) => ({
                    Drug: rightsScopeDrugIndexList(index).map((_, drugIndex) =>
                      createContentFromHTML(
                        refMap,
                        createRefCode(
                          REF_CODE_RIGHTS_SCOPE,
                          REF_CODE_LICENSEE_DRUG,
                          index,
                          drugIndex,
                          tableIndex + 1,
                        ),
                      ),
                    ),
                    Indication: rightsScopeDrugIndexList(index).map(
                      (_, indicationIndex) =>
                        createContentFromHTML(
                          refMap,
                          createRefCode(
                            REF_CODE_RIGHTS_SCOPE,
                            REF_CODE_LICENSEE_INDICATION,
                            index,
                            indicationIndex,
                            tableIndex + 1,
                          ),
                        ),
                    ),
                    Exclusivity: createContentFromHTML(
                      refMap,
                      createRefCode(
                        REF_CODE_RIGHTS_SCOPE,
                        REF_CODE_LICENSEE_EXCLUSIVITY,
                        index,
                        tableIndex + 1,
                      ),
                    ),
                    Territory: createContentFromHTML(
                      refMap,
                      createRefCode(
                        REF_CODE_RIGHTS_SCOPE,
                        REF_CODE_LICENSEE_TERRITORY,
                        index,
                        tableIndex + 1,
                      ),
                    ),
                  })),
                  attributionInfo: {
                    dealDirection: tableIndex === 0 ? partnerA : partnerB,
                  },
                };
              }),
        },
      },
      updateDt: dayjs().toISOString(),
    };

    return content;
  };
};
