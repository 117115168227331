import { DrugProfileContent } from "@EditArticleContainer/shared/types/paragraphContents/contents/drugProfileContent.types";

import { isExistDrugProfileTableValue } from "./isExistDrugProfileTableValue";

export const checkAllDrugProfileTableFieldsEmpty = (
  paragraphData: DrugProfileContent,
) => {
  return paragraphData.text["Drug profile"]
    .map((drugProfileTable) => isExistDrugProfileTableValue(drugProfileTable))
    .every((isExistData) => isExistData === false);
};
