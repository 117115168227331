import { ProcessStatusCode } from "@/types/code.types";
import { useCreateEntryDataMutation } from "@api/createEntryData/createEntryData";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import {
  checkSuperAdmin,
  useAuthorityType,
} from "@libs/roles/hooks/useAuthorityType";
import { PressListData } from "@PressListContainer/pressList.types";
import { handleDraftError } from "@utils/handleDraftError";

import PressStatus from "./components/PressStatus/PressStatus";

type Props = {
  data: PressListData;
};

const PressListStatusContainer = ({ data }: Props) => {
  const { mutateAsync: createEntryData } = useCreateEntryDataMutation();

  const authorityType = useAuthorityType();
  const isSuperAdmin = checkSuperAdmin(authorityType);

  const checkDisabledStatusButton = (status: ProcessStatusCode) => {
    if (status !== DRAFT_STATUS_CODE.aiEntryDone) return false;
    if (isSuperAdmin) return false;
    return true;
  };

  const createDAE = (id: number) => {
    if (!isSuperAdmin) return;
    createEntryData(id);
  };

  return (
    <PressStatus
      data={data}
      isDraftError={handleDraftError(data)[0]}
      draftErrorMessage={handleDraftError(data)[1]}
      createDAE={createDAE}
      disabled={checkDisabledStatusButton(data.draftArticleStatusHighest)}
    />
  );
};

export default PressListStatusContainer;
