import {
  CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS,
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS,
} from "@frontend/common";

type DefaultObjectType = {
  [key: string]: string;
};

export const PLUS_BAR_BUTTON_TEXT_SUB_GROUP_SHELL: DefaultObjectType = {
  [LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone]: "MILESTONE",
  [LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail]:
    "OTHER PAYMENT",
  [MERGE_ACQUISITION_SUB_GROUP_SHELL_KEYS.contingentPayment]:
    "CONTINGENT PAYMENT",
  [CLINICAL_TRIAL_DESIGN_SUB_GROUP_SHELL_KEYS.arm]: "ARM DATA",
  [CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerA]:
    "RIGHT SCOPE",
  [CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerB]:
    "RIGHT SCOPE",
};
