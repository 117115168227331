import { ENTRY_DATA_LICENSEE_TABLE } from "../../../constants";

export const CROSS_LICENSE_AGREEMENT_KEY = "Cross License Agreement";

export const CROSS_LICENSE_AGREEMENT_GROUP_TABLE_KEY =
  ENTRY_DATA_LICENSEE_TABLE;
export const CROSS_LICENSE_AGREEMENT_TABLE_KEYS = {
  dealPurpose: "Deal Purpose",
  licensee: "Cross Licensee",
  additionalInformation: "Additional Information",
  rightsScope: "Cross License Rights Scope",
  financialTerms: "Cross License Financial terms SC & LA",
  otherFinancialTerms: "Cross License Other Financial Terms",
  none: null,
} as const;

export const CROSS_LICENSE_AGREEMENT_GROUP_SHELL_SECTION_KEYS = {
  rightsScope: "Rights Scope",
  financialTerms: "Financial terms SC & LA",
  otherFinancialTerms: "Other Financial Terms",
  none: null,
} as const;

export const CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS = {
  dealPurpose: "Deal Purpose",
  rightsScopePartnerA: "Rights Scope Partner A",
  rightsScopePartnerB: "Rights Scope Partner B",
  financialTermsPartnerA: "Financial terms Partner A",
  financialTermsPartnerB: "Financial terms Partner B",
  otherFinancialTerms: "Other Financial Terms",
  additionalInformation: "Additional Information",
  licensingPartnerA: "Licensing Partner A",
  licensingPartnerB: "Licensing Partner B",
  none: null,
} as const;

export const CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS = {
  upfront: "Upfront",
  milestone: "Milestone",
  total: "Total",
  rightsScopePartnerA: "Rights Scope Partner A",
  rightsScopePartnerB: "Rights Scope Partner B",
  otherFinancialTerms: "Other Financial Terms",
  otherPaymentAndDetail: "Other Payment and Detail",
  none: null,
} as const;

export const CROSS_LICENSE_DEAL_TERMS_DIRECTION = {
  partnerA: "Partner A -> Partner B",
  partnerB: "Partner B -> Partner A",
} as const;
