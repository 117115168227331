import { CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS } from "@frontend/common";

export const checkIsFinancialTermsGroup = (
  groupShellKey: string | null,
): boolean => {
  return (
    groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTermsPartnerA ||
    groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTermsPartnerB
  );
};
