import styled from "styled-components";

export const PressTitleTableShell = styled.td`
  position: relative;
  color: transparent !important;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.8rem;
  padding: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
  vertical-align: top;
  cursor: pointer;

  &:hover {
    background: var(
      --Color-Common-Bg-Interactive-Primary-Subtle-Hovered,
      #e0f4ff
    );
  }
`;

export const TitleWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 1rem;
  height: 100%;

  & > div {
    color: ${({ theme }) => theme.color.neural.gray08};
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 130%;
    letter-spacing: -0.012rem;
  }

  &:hover {
    & + .press-hover-modal {
      height: auto;
      padding: 1.2rem 1.6rem;
      border: 1px solid var(--Color-Common-Border-Secondary, #b1b8c4);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16),
        0px 0px 4px 0px rgba(0, 0, 0, 0.12);
    }
  }

  .translate-id {
    color: var(--Color-Common-Text-Success, #0198e9);
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.012rem;
  }

  .reupload-id {
    color: var(--Color-Text-Progress, #eb8d00);
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.012rem;
  }
`;

export const TranslatedId = styled.span`
  color: var(--Color-Text-Progress, #eb8d00);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.012rem;
`;
