import React from "react";

import { DealsSection } from "@/types/deals.types";
import { useScroll } from "@FillInTableContainer/components/FillInTable/hooks/useScroll";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorGroupTableTitle } from "@FillInTableContainer/shared";
import { useCanEditGroupTable } from "@FillInTableContainer/shared/hooks/useCanEditGroupTable";
import { useGetNumberOfTable } from "@FillInTableContainer/shared/hooks/useGetNumberOfTable";
import { TableCreatorSubGroupShellTableList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { isRequiredGroupTable } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import { getGroupTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";

import DealTermsCrossLicenseTable from "./components/DealTermsCrossLicenseTable/DealTermsCrossLicenseTable";
import * as S from "./DealTermsCrossLicenseGroupTable.style";

interface Props {
  groupTableKey: DealsSection;
  tableList: TableCreatorSubGroupShellTableList;
  groupTableIndex: number;
}

const DealTermsCrossLicenseGroupTable = ({
  groupTableKey,
  groupTableIndex,
  tableList,
}: Props) => {
  const { register } = useScroll();
  const { tableCount } = useGetNumberOfTable();
  const articleSection = useArticleSection();

  const { checkDisableEdit } = useCanEditGroupTable();

  return (
    <S.GroupTableWrapper
      isDisableEdit={checkDisableEdit(groupTableKey)}
      {...register(groupTableKey)}
    >
      <TableCreatorGroupTableTitle
        title={getGroupTableTitle(articleSection, groupTableKey)}
        isRequired={isRequiredGroupTable(articleSection, groupTableKey)}
      />
      <S.TableCreatorTableListWrapper
        isDisableEdit={checkDisableEdit(groupTableKey)}
      >
        {tableList.map(({ tableKey, groupShellList }, tableIndex) => {
          return (
            <DealTermsCrossLicenseTable
              key={`table--${String(tableIndex)}`}
              tableKey={tableKey}
              groupShellList={groupShellList}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              tableIndex={tableIndex}
              tableCount={tableCount(groupTableKey)}
            />
          );
        })}
      </S.TableCreatorTableListWrapper>
    </S.GroupTableWrapper>
  );
};

export default DealTermsCrossLicenseGroupTable;
