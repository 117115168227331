import {
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  deleteCrossLicenseSubGroupShell: () => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    subGroupShellIndex: number;
    subGroupShellKey: string | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useDeleteCrossLicenseSubGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { checkDisabledDeleteGroupShell } = useCheckDisabledDelete();
  const PARTNER_A_TABLE_INDEX = 1;
  const PARTNER_B_TABLE_INDEX = 0;

  const deleteCrossLicenseSubGroupShell = () => {
    if (checkDisabledDeleteGroupShell()) return;

    const deleteRightScopeSubGroupShell = (
      partnerTableIndex: number,
    ): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === partnerTableIndex) {
                return {
                  ...tableInfo,
                  "Rights Scope": tableInfo["Rights Scope"]?.filter(
                    (_, groupShellInfoIndex) =>
                      groupShellInfoIndex !== info.groupShellIndex,
                  ),
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const deleteMilestoneSubGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Milestone Payment and Condition": tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ]["Milestone Payment and Condition"].filter(
                      (_, subGroupShellInfoIndex) =>
                        subGroupShellInfoIndex !== info.subGroupShellIndex,
                    ),
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const deleteOtherPaymentSubGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ],
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                      tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .otherPaymentAndDetailScLa
                      ].filter(
                        (_, subGroupShellInfoIndex) =>
                          subGroupShellInfoIndex !== info.subGroupShellIndex,
                      ),
                  },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    if (
      info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerA
    ) {
      setEntryData(deleteRightScopeSubGroupShell(PARTNER_A_TABLE_INDEX));
      changeCurrentHistory({
        entryData: deleteRightScopeSubGroupShell(PARTNER_A_TABLE_INDEX),
        changedDataInfo: {
          target: "subGroupShell",
          action: "delete",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
    }

    if (
      info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerB
    ) {
      setEntryData(deleteRightScopeSubGroupShell(PARTNER_B_TABLE_INDEX));
      changeCurrentHistory({
        entryData: deleteRightScopeSubGroupShell(PARTNER_B_TABLE_INDEX),
        changedDataInfo: {
          target: "subGroupShell",
          action: "delete",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
    }

    if (
      info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone
    ) {
      setEntryData(deleteMilestoneSubGroupShell());
      changeCurrentHistory({
        entryData: deleteMilestoneSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "delete",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
    }

    if (
      info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail
    ) {
      setEntryData(deleteOtherPaymentSubGroupShell());
      changeCurrentHistory({
        entryData: deleteOtherPaymentSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "delete",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
    }

    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
    changeInfo(DEFAULT_POSITION_INFO);
  };

  return {
    deleteCrossLicenseSubGroupShell,
  };
};
