import React, { useEffect, useRef } from "react";

import {
  CROSS_LICENSE_AGREEMENT_TABLE_KEYS,
  LICENSE_AGREEMENT_TABLE_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorTableTitle } from "@FillInTableContainer/shared";
import { TableCreatorSubGroupGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { getTableTitle } from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";

import DealTermsCrossLicenseGroupShell from "./components/DealTermsCrossLicenseGroupShell/DealTermsCrossLicenseGroupShell";
import * as S from "./DealTermsCrossLicenseTable.style";
import { useCreateCrossLicenseTable } from "./hooks/useCreateCrossLicenseTable";

export interface Props {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupShellList: TableCreatorSubGroupGroupShellList;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}

const SEPARATE_GROUP_SHELL_TABLE: string[] = [
  CROSS_LICENSE_AGREEMENT_TABLE_KEYS.licensee,
  CROSS_LICENSE_AGREEMENT_TABLE_KEYS.rightsScope,
];

const FINANCIAL_TERMS_TABLE: string[] = [
  CROSS_LICENSE_AGREEMENT_TABLE_KEYS.financialTerms,
];

const PARTNER_A_TABLE: string[] = [
  CROSS_LICENSE_AGREEMENT_TABLE_KEYS.otherFinancialTerms,
];

const DealTermsCrossLicenseTable = ({
  groupShellList,
  groupTableIndex,
  groupTableKey,
  tableCount,
  tableIndex,
  tableKey,
}: Props) => {
  const tableTitleRef = useRef<HTMLDivElement>(null);
  const articleSection = useArticleSection();
  const partnerA = groupShellList[0];
  const partnerATableIndex = 1;
  const partnerAGroupShellIndex = 0;

  const partnerB = groupShellList[1];
  const partnerBTableIndex = 0;
  const partnerBGroupShellIndex = 0;
  const currentTableIndex = 0;

  const tablePropsInfo: Props = {
    groupTableKey,
    tableKey,
    groupTableIndex,
    tableIndex: currentTableIndex,
    tableCount,
    groupShellList,
  };

  const customTableTitle = () => {
    if (tableKey === LICENSE_AGREEMENT_TABLE_KEYS.additionalInformation)
      return "Additional Information";

    if (tableKey === CROSS_LICENSE_AGREEMENT_TABLE_KEYS.licensee)
      return "Licensing Partner";

    return "";
  };

  const {
    focusStatus,
    isFocused,
    handleClickDeleteButton,
    focusEventToChangePositionInfo,
    handleChangeDefaultFocus,
  } = useCreateCrossLicenseTable(tablePropsInfo);

  const isClearTable =
    isFocused &&
    focusStatus.container === "table" &&
    focusStatus.type === "clear";

  const isDeleteTable =
    isFocused &&
    focusStatus.container === "table" &&
    focusStatus.type === "delete";

  const title = getTableTitle(articleSection, tableKey);

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;

        const clickSelectButton = tableTitleRef.current?.contains(target);

        if (clickSelectButton) return;
        if (target.dataset.button) return;

        handleChangeDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleChangeDefaultFocus, isFocused]);

  const handleFocusTableTitle = () => {
    focusEventToChangePositionInfo({
      type: "clear",
      container: "table",
    });
  };

  const returnGroupShellList = () => {
    if (tableKey && SEPARATE_GROUP_SHELL_TABLE.includes(tableKey)) {
      return (
        <>
          <DealTermsCrossLicenseGroupShell
            key={`group-shell-${String(groupTableIndex)}-${String(
              partnerATableIndex,
            )}-${String(partnerAGroupShellIndex)}`}
            subGroupShellList={partnerA.subGroupShellList}
            groupTableKey={groupTableKey}
            groupTableIndex={groupTableIndex}
            tableKey={tableKey}
            tableIndex={partnerATableIndex}
            groupShellKey={partnerA.groupShellKey}
            groupShellIndex={partnerAGroupShellIndex}
          />
          <DealTermsCrossLicenseGroupShell
            key={`group-shell-${String(groupTableIndex)}-${String(
              partnerBTableIndex,
            )}-${String(partnerBGroupShellIndex)}`}
            subGroupShellList={partnerB.subGroupShellList}
            groupTableKey={groupTableKey}
            groupTableIndex={groupTableIndex}
            tableKey={tableKey}
            tableIndex={partnerBTableIndex}
            groupShellKey={partnerB.groupShellKey}
            groupShellIndex={partnerBGroupShellIndex}
          />
        </>
      );
    }

    if (tableKey && FINANCIAL_TERMS_TABLE.includes(tableKey)) {
      return (
        <>
          <DealTermsCrossLicenseGroupShell
            key={`group-shell-${String(groupTableIndex)}-${String(
              partnerBTableIndex,
            )}-${String(partnerBGroupShellIndex)}`}
            subGroupShellList={partnerB.subGroupShellList}
            groupTableKey={groupTableKey}
            groupTableIndex={groupTableIndex}
            tableKey={tableKey}
            tableIndex={partnerBTableIndex}
            groupShellKey={partnerB.groupShellKey}
            groupShellIndex={partnerBGroupShellIndex}
          />
          <DealTermsCrossLicenseGroupShell
            key={`group-shell-${String(groupTableIndex)}-${String(
              partnerATableIndex,
            )}-${String(partnerAGroupShellIndex)}`}
            subGroupShellList={partnerA.subGroupShellList}
            groupTableKey={groupTableKey}
            groupTableIndex={groupTableIndex}
            tableKey={tableKey}
            tableIndex={partnerATableIndex}
            groupShellKey={partnerA.groupShellKey}
            groupShellIndex={partnerAGroupShellIndex}
          />
        </>
      );
    }

    if (tableKey && PARTNER_A_TABLE.includes(tableKey)) {
      return (
        <DealTermsCrossLicenseGroupShell
          key={`group-shell-${String(groupTableIndex)}-${String(
            tableIndex,
          )}-${String(partnerAGroupShellIndex)}`}
          subGroupShellList={partnerA.subGroupShellList}
          groupTableKey={groupTableKey}
          groupTableIndex={groupTableIndex}
          tableKey={tableKey}
          tableIndex={partnerATableIndex}
          groupShellKey={partnerA.groupShellKey}
          groupShellIndex={partnerAGroupShellIndex}
        />
      );
    }

    return groupShellList.map(
      ({ groupShellKey, subGroupShellList }, groupShellIndex) => (
        <DealTermsCrossLicenseGroupShell
          key={`group-shell-${String(groupTableIndex)}-${String(
            tableIndex,
          )}-${String(groupShellIndex)}`}
          subGroupShellList={subGroupShellList}
          groupTableKey={groupTableKey}
          groupTableIndex={groupTableIndex}
          tableKey={tableKey}
          tableIndex={0}
          groupShellKey={groupShellKey}
          groupShellIndex={0}
        />
      ),
    );
  };

  return (
    <>
      <S.TableWrapper isFocused={isFocused} isClearTable={isClearTable}>
        {(isClearTable || isDeleteTable) && (
          <S.DeleteTableButtonWrapper>
            <S.DeleteTableButton
              data-button="delete-button"
              onClick={handleClickDeleteButton}
            >
              <img src={IconXMark} alt="button for clear table" />
              {focusStatus.type === "clear" ? `Clear ${title} Table` : null}
              {focusStatus.type === "delete" ? `Delete ${title} Table` : null}
            </S.DeleteTableButton>
          </S.DeleteTableButtonWrapper>
        )}
        <div ref={tableTitleRef}>
          {getTableTitle(articleSection, tableKey) && (
            <TableCreatorTableTitle
              tableKey={tableKey}
              groupTableKey={groupTableKey}
              groupTableIndex={groupTableIndex}
              tableIndex={tableIndex}
              onClickHandler={handleFocusTableTitle}
              tableTitle={customTableTitle()}
            />
          )}
        </div>
        {returnGroupShellList()}
      </S.TableWrapper>
    </>
  );
};

export default DealTermsCrossLicenseTable;
