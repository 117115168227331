"use client";

import styled from "styled-components";

export const MainBannerWrapper = styled.div<{ $imageUrl: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 4rem 0 3.8rem;

  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -2;
  }
`;

export const SectionAndMarketWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    align-items: flex-start;
  }
`;

export const MainBannerContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;

  width: calc(100% - 3.2rem);
  max-width: 100rem;
  height: 100%;
`;

export const ArticleSectionText = styled.div`
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 100%;
`;

export const MarketInfoWrapper = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    flex-direction: column;
    align-items: flex-end;
  }
`;
export const SameDayText = styled.div`
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);

  font-size: 1.2rem;
  font-weight: 500;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
  height: 2rem;
`;

export const DateText = styled.div`
  height: 100%;
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.2rem;

  width: 100%;

  @media ${({ theme }) => theme.mediaQuery.mobile[767]} {
    align-items: flex-start;
    flex-direction: column;
    gap: 1.2rem;
  }
`;

export const Title = styled.div`
  flex: 1;
  font-family: var(--font-pt-serif);
  color: var(--Color-Common-Text-Interactive-Inverse, #fff);
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 140%;
`;

export const MainLabelWrapper = styled.div`
  width: 100%;
`;

export const UrlWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  width: fit-content;
  min-width: 22rem;
  height: 2rem;
  color: var(--Color-Common-Text-Interactive-Inverse, #fff) !important;
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  text-decoration: underline !important;
  cursor: pointer;
  white-space: nowrap;

  path {
    fill: var(--Color-Common-Text-Interactive-Inverse, #fff) !important;
  }
`;

export const BottomRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
