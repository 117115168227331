import styled, { css } from "styled-components";

export const PressListTableHead = styled.thead`
  height: 4.4rem;
`;

export const TableRow = styled.tr``;

export const TableHeadShell = styled.th<{ isFirst?: boolean }>`
  padding: 0.5rem 0.8rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.neural.gray03};
  vertical-align: middle;
`;

export const TableHeadShellContents = styled.ul<{ isOrdering: boolean }>`
  display: flex;
  align-items: top;
  gap: 0.6rem;
  width: 100%;
  height: 100%;
  cursor: pointer;

  ${({ isOrdering }) =>
    isOrdering &&
    css`
      color: ${({ theme }) => theme.color.node.blue};
    `}
`;

export const TableHeadShellTitle = styled.li<{
  isOrdering: boolean;
  isFirst?: boolean;
}>`
  height: 100%;
  font-weight: 600;
  font-size: 0.8rem;
  text-align: left;
  line-height: 1.3rem;

  ${(props) =>
    props.isFirst &&
    `
      font-size: 1.1rem;
      font-weight: 500;  
    `}

  &:hover,
  &:active {
    strong {
      color: ${({ theme }) => theme.color.node.blue};
    }
  }

  ${({ isOrdering }) =>
    isOrdering &&
    css`
      strong {
        color: ${({ theme }) => theme.color.node.blue};
      }
    `}
`;

export const TableHeadShellTitleWord = styled.strong<{ isFirst?: boolean }>`
  display: block;
  font-weight: 600;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.color.neural.gray08};

  ${(props) =>
    props.isFirst &&
    `
       font-size: 1.1rem;
       font-weight: 500;
    `}
`;

export const OrderingIconWrapper = styled.li``;
