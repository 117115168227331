import { useState } from "react";

import { ProcessStatusCode } from "@/types/code.types";
import useModal from "@hooks/useModal";
import { PressListData } from "@PressListContainer/pressList.types";

import PressListCheckStatusGuide from "./components/PressListTableShellForPressTitle/components/PressListCheckStatusGuide/PressListCheckStatusGuide";
import PressListTableShellForPressTitle from "./components/PressListTableShellForPressTitle/PressListTableShellForPressTitle";
import PressListGuideModal from "../PressLIstGuideModal/PressListGuideModal";
import PressListPageEntryGuide from "../PressListPageEntryGuide/PressListPageEntryGuide";

type Props = {
  pressListItem: PressListData;
  updatePressList: () => void;
  pressListData: PressListData[];
};

const PressListPressTitleContainer = ({
  updatePressList,
  pressListItem,
  pressListData,
}: Props) => {
  const [targetPressId, setTargetPressId] = useState(0);
  const [currentStatus, setCurrentStatus] = useState<ProcessStatusCode | "">(
    "",
  );

  const { isModal, closeModal, openModal } = useModal();
  const {
    isModal: isGuideModal,
    closeModal: closeGuideModal,
    openModal: openGuideModal,
  } = useModal();
  const {
    isModal: isCheckStatusModal,
    closeModal: closeCheckStatusModal,
    openModal: openCheckStatusModal,
  } = useModal();

  const handleTargetPressId = (id: number) => {
    setTargetPressId(id);
  };

  const handleCurrentStatus = (status: ProcessStatusCode | "") => {
    setCurrentStatus(status);
  };

  const openAccessDeniedModal = (pressId: number) => {
    handleTargetPressId(pressId);
    openGuideModal();
  };

  const openConcurrentAccessDeniedModal = (pressId: number) => {
    handleTargetPressId(pressId);
    openModal();
  };

  return (
    <>
      <PressListTableShellForPressTitle
        key={pressListItem.pressId}
        pressData={pressListItem}
        openCheckStatusModal={openCheckStatusModal}
        openAccessDeniedModal={() =>
          openAccessDeniedModal(pressListItem.pressId)
        }
        openConcurrentAccessDeniedModal={() =>
          openConcurrentAccessDeniedModal(pressListItem.pressId)
        }
        handleTargetPressId={() => handleTargetPressId(pressListItem.pressId)}
        handleCurrentStatus={handleCurrentStatus}
      />

      {isModal && (
        <PressListPageEntryGuide
          closeModal={closeModal}
          draftArticleId={targetPressId}
          updatePressList={updatePressList}
        />
      )}
      {isGuideModal && (
        <PressListGuideModal
          closeModal={closeGuideModal}
          draftArticleId={targetPressId}
        />
      )}
      {isCheckStatusModal && (
        <PressListCheckStatusGuide
          closeModal={closeCheckStatusModal}
          draftArticleId={targetPressId}
          updatePressList={updatePressList}
          pressListData={pressListData || []}
          openAccessDeniedModal={openGuideModal}
          openConcurrentAccessDeniedModal={openModal}
          currentStatus={currentStatus}
        />
      )}
    </>
  );
};

export default PressListPressTitleContainer;
