import React from "react";

import * as S from "./ArticleDetailDrugProfileTable.style";
import {
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
  useCheckWindowSize,
} from "../../../../shared";
import ArticleDetailTableTitleDrugName from "../ArticleDetailTableTitleDrugName/ArticleDetailTableTitleDrugName";

type Props = {
  tableData: {
    "Drug Name": React.ReactNode;
    "Originator/Developer": React.ReactNode;
    Modality: React.ReactNode;
    Target: React.ReactNode;
    "Target Indication": React.ReactNode;
    ROA: React.ReactNode;
    Stage: React.ReactNode;
    "Other Characteristics": React.ReactNode;
    "Generic / Biosimilar": React.ReactNode;
    "Ref.Product Name": React.ReactNode;
  };
  isEditMode?: boolean;
  existValueInfo?: {
    "Drug Name": boolean;
    "Originator/Developer": boolean;
    Modality: boolean;
    Target: boolean;
    "Target Indication": boolean;
    ROA: boolean;
    Stage: boolean;
    "Other Characteristics": boolean;
    "Generic / Biosimilar": boolean;
    "Ref.Product Name": boolean;
  };
};

const ArticleDetailDrugProfileTable = ({
  tableData,
  isEditMode = false,
  existValueInfo,
}: Props) => {
  const { isMobileScreen, isTabletScreen, isDesktopScreen } =
    useCheckWindowSize();
  const VALUE_SHELL_PADDING = isEditMode ? "0.2rem" : undefined;

  const isHideByEmptyData = !existValueInfo
    ? false
    : Object.values(existValueInfo).every((value) => !value);

  if (isEditMode || isDesktopScreen)
    return (
      <S.TableWrapper className={`is-hidden-table-${isHideByEmptyData}`}>
        <ArticleDetailTableTitleDrugName>
          {tableData["Drug Name"]}
        </ArticleDetailTableTitleDrugName>
        <TableContainer>
          {!(
            tableData["Originator/Developer"] === null &&
            tableData.Modality === null &&
            tableData.Target === null
          ) && (
            <>
              <TableRowContainer
                className={`is-hidden-row-${
                  existValueInfo?.["Originator/Developer"] === false &&
                  existValueInfo?.Modality === false &&
                  existValueInfo?.Target === false
                }`}
              >
                <TableShellTitle>Company</TableShellTitle>
                <TableShellTitle>Modality</TableShellTitle>
                <TableShellTitle>Target / MOA</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer
                className={`is-hidden-row-${
                  existValueInfo?.["Originator/Developer"] === false &&
                  existValueInfo?.Modality === false &&
                  existValueInfo?.Target === false
                }`}
              >
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Originator/Developer"] ?? "-"}
                </TableShellValue>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData.Modality ?? "-"}
                </TableShellValue>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData.Target ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {!(
            tableData.ROA === null &&
            tableData["Target Indication"] === null &&
            tableData.Stage === null
          ) && (
            <>
              <TableRowContainer
                className={`is-hidden-row-${
                  existValueInfo?.ROA === false &&
                  existValueInfo?.["Target Indication"] === false &&
                  existValueInfo?.Stage === false
                }`}
              >
                <TableShellTitle>ROA</TableShellTitle>
                <TableShellTitle>Target Indication</TableShellTitle>
                <TableShellTitle>Stage</TableShellTitle>
              </TableRowContainer>

              <TableRowContainer
                className={`is-hidden-row-${
                  existValueInfo?.ROA === false &&
                  existValueInfo?.["Target Indication"] === false &&
                  existValueInfo?.Stage === false
                }`}
              >
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData.ROA ?? "-"}
                </TableShellValue>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Target Indication"] ?? "-"}
                </TableShellValue>
                <TableShellValue padding={VALUE_SHELL_PADDING} isCapitalize>
                  {tableData.Stage ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {tableData["Other Characteristics"] !== null && (
            <>
              <TableRowContainer
                className={`is-hidden-row-${
                  existValueInfo?.["Other Characteristics"] === false
                }`}
              >
                <TableShellTitle>Other Characteristics</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer
                className={`is-hidden-row-${
                  existValueInfo?.["Other Characteristics"] === false
                }`}
              >
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Other Characteristics"] ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {!(
            tableData["Generic / Biosimilar"] === null &&
            tableData["Ref.Product Name"] === null
          ) && (
            <>
              <TableRowContainer
                className={`is-hidden-row-${
                  existValueInfo?.["Generic / Biosimilar"] === false &&
                  existValueInfo?.["Ref.Product Name"] === false
                }`}
              >
                <TableShellTitle>Generic / Biosimilar</TableShellTitle>
                <TableShellTitle>Reference Product Name</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer
                className={`is-hidden-row-${
                  existValueInfo?.["Generic / Biosimilar"] === false &&
                  existValueInfo?.["Ref.Product Name"] === false
                }`}
              >
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Generic / Biosimilar"] ?? "-"}
                </TableShellValue>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Ref.Product Name"] ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
        </TableContainer>
      </S.TableWrapper>
    );
  if (isTabletScreen) {
    return (
      <S.TableWrapper className={`is-hidden-table-${isHideByEmptyData}`}>
        <ArticleDetailTableTitleDrugName>
          {tableData["Drug Name"] ?? "-"}
        </ArticleDetailTableTitleDrugName>
        <TableContainer>
          {!(
            tableData["Originator/Developer"] === null &&
            tableData.Modality === null
          ) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Company</TableShellTitle>
                <TableShellTitle>Modality</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Originator/Developer"] ?? "-"}
                </TableShellValue>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData.Modality ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {!(tableData.Target === null && tableData.ROA === null) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Target / MOA</TableShellTitle>
                <TableShellTitle>ROA</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData.Target ?? "-"}
                </TableShellValue>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData.ROA ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {!(
            tableData["Target Indication"] === null && tableData.Stage === null
          ) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Target Indication</TableShellTitle>
                <TableShellTitle>Stage</TableShellTitle>
              </TableRowContainer>

              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Target Indication"] ?? "-"}
                </TableShellValue>
                <TableShellValue padding={VALUE_SHELL_PADDING} isCapitalize>
                  {tableData.Stage ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {tableData["Other Characteristics"] !== null && (
            <>
              <TableRowContainer>
                <TableShellTitle>Other Characteristics</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Other Characteristics"] ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {!(
            tableData["Generic / Biosimilar"] === null &&
            tableData["Ref.Product Name"] === null
          ) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Generic / Biosimilar</TableShellTitle>
                <TableShellTitle>Reference Product Name</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Generic / Biosimilar"] ?? "-"}
                </TableShellValue>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Ref.Product Name"] ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
        </TableContainer>
      </S.TableWrapper>
    );
  }
  if (isMobileScreen) {
    return (
      <S.TableWrapper className={`is-hidden-table-${isHideByEmptyData}`}>
        <ArticleDetailTableTitleDrugName>
          {tableData["Drug Name"]}
        </ArticleDetailTableTitleDrugName>
        <TableContainer>
          {!(tableData["Originator/Developer"] === null) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Company</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Originator/Developer"] ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {!(tableData.Modality === null) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Modality</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData.Modality ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {!(tableData.Target === null) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Target / MOA</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData.Target ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {!(tableData.ROA === null) && (
            <>
              <TableRowContainer>
                <TableShellTitle>ROA</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData.ROA}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {!(tableData["Target Indication"] === null) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Target Indication</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Target Indication"] ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {!(tableData.Stage === null) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Stage</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING} isCapitalize>
                  {tableData.Stage ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {tableData["Other Characteristics"] !== null && (
            <>
              <TableRowContainer>
                <TableShellTitle>Other Characteristics</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Other Characteristics"] ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {!(tableData["Generic / Biosimilar"] === null) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Generic / Biosimilar</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Generic / Biosimilar"] ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {!(tableData["Ref.Product Name"] === null) && (
            <>
              <TableRowContainer>
                <TableShellTitle>Reference Product Name</TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue padding={VALUE_SHELL_PADDING}>
                  {tableData["Ref.Product Name"] ?? "-"}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
        </TableContainer>
      </S.TableWrapper>
    );
  }
};

export default ArticleDetailDrugProfileTable;
