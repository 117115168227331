import React from "react";

import {
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import { useTableCreatorPositionInfo } from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useCheckDisabledDelete } from "@FillInTableContainer/shared/hooks/useCheckDisabledDelete";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useDeleteCrossLicenseSubGroupShell } from "./useDeleteCrossLicenseSubGroupShell";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface UseHandleGroupShellReturn {
  handleClickToDeleteMilestoneSubGroupShell: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  handleKeyDownToDeleteMilestoneSubGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    subGroupShellIndex: number;
    subGroupShellKey: string | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useClearCrossLicenseSubGroupShell = ({
  positionPropsInfo,
}: Props): UseHandleGroupShellReturn => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    subGroupShellIndex,
    subGroupShellKey,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info } = useTableCreatorPositionInfo();
  const { checkDisabledDeleteGroupShell } = useCheckDisabledDelete();
  const { deleteCrossLicenseSubGroupShell } =
    useDeleteCrossLicenseSubGroupShell({ positionPropsInfo });

  const PARTNER_A_INDEX = 1;
  const PARTNER_B_INDEX = 0;

  const handleClearSubGroupShell = () => {
    const clearPartnerARightScopeGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === PARTNER_A_INDEX) {
                return {
                  ...tableInfo,
                  "Rights Scope": tableInfo["Rights Scope"]?.map(
                    (groupShellInfo, subGroupShellInfoIndex) => {
                      if (subGroupShellInfoIndex === info.subGroupShellIndex) {
                        return {
                          ...groupShellInfo,
                          Drug: {
                            ...groupShellInfo.Drug,
                            value: [],
                          },
                          Exclusivity: {
                            ...groupShellInfo.Exclusivity,
                            value: [],
                          },
                          Indication: {
                            ...groupShellInfo.Indication,
                            value: [],
                          },
                          Territory: {
                            ...groupShellInfo.Territory,
                            value: [],
                          },
                        };
                      }
                      return groupShellInfo;
                    },
                  ),
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };
    const clearPartnerBRightScopeGroupShell = (): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === PARTNER_B_INDEX) {
                return {
                  ...tableInfo,
                  "Rights Scope": tableInfo["Rights Scope"]?.map(
                    (groupShellInfo, subGroupShellInfoIndex) => {
                      if (subGroupShellInfoIndex === info.subGroupShellIndex) {
                        return {
                          ...groupShellInfo,
                          Drug: {
                            ...groupShellInfo.Drug,
                            value: [],
                          },
                          Exclusivity: {
                            ...groupShellInfo.Exclusivity,
                            value: [],
                          },
                          Indication: {
                            ...groupShellInfo.Indication,
                            value: [],
                          },
                          Territory: {
                            ...groupShellInfo.Territory,
                            value: [],
                          },
                        };
                      }
                      return groupShellInfo;
                    },
                  ),
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearMilestoneSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Milestone Payment and Condition": tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ]["Milestone Payment and Condition"].map(
                      (subGroupShellInfo, subGroupShellInfoIndex) => {
                        if (
                          subGroupShellInfoIndex === info.subGroupShellIndex
                        ) {
                          return {
                            ...subGroupShellInfo,
                            "Milestone Condition Title": {
                              ...subGroupShellInfo["Milestone Condition Title"],
                              value: [],
                            },
                            "Milestone Payment": {
                              ...subGroupShellInfo["Milestone Payment"],
                              value: [],
                            },
                          };
                        }

                        return subGroupShellInfo;
                      },
                    ),
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearUpfrontSupGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Upfront Payment": {
                      ...tableInfo[
                        "Strategic Collaboration & License Agreement terms"
                      ]["Upfront Payment"],
                      value: [],
                    },
                    Royalty: {
                      ...tableInfo[
                        "Strategic Collaboration & License Agreement terms"
                      ].Royalty,
                      value: [],
                    },
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearTotalSupGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === info.tableIndex) {
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Total Payment (SC & LA)": {
                      ...tableInfo[
                        "Strategic Collaboration & License Agreement terms"
                      ]["Total Payment (SC & LA)"],
                      value: [],
                    },
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa]:
                      {
                        ...tableInfo[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS
                            .strategicCollaborationLicenseAgreementTerms
                        ][
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .disclosedPaymentsSumScLa
                        ],
                        value: [],
                      },
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearOtherPaymentAndDetailSubGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_LICENSEE_TABLE]: entryData.Deals?.[
            ENTRY_DATA_LICENSEE_TABLE
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === info.tableIndex) {
              return {
                ...tableInfo,
                [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                  {
                    ...tableInfo[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS
                        .strategicCollaborationLicenseAgreementTerms
                    ],
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                      tableInfo[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ][
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .otherPaymentAndDetailScLa
                      ].map((subGroupShellInfo, subGroupShellInfoIndex) => {
                        if (
                          subGroupShellInfoIndex === info.subGroupShellIndex
                        ) {
                          return {
                            ...subGroupShellInfo,
                            [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa]:
                              {
                                ...subGroupShellInfo[
                                  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                    .otherPaymentScLa
                                ],
                                value: [],
                              },
                            [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa]:
                              {
                                ...subGroupShellInfo[
                                  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                    .otherPaymentDetailScLa
                                ],
                                value: [],
                              },
                          };
                        }

                        return subGroupShellInfo;
                      }),
                  },
              };
            }

            return tableInfo;
          }),
        },
      };
    };

    if (
      info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerA
    ) {
      const milestoneLength =
        entryData?.Deals?.["Licensee Table"]?.[PARTNER_A_INDEX]?.[
          "Rights Scope"
        ]?.length || 0;

      setEntryData(clearPartnerARightScopeGroupShell());
      changeCurrentHistory({
        entryData: clearPartnerARightScopeGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(
        milestoneLength > 1 && !checkDisabledDeleteGroupShell()
          ? {
              type: "delete",
              container: "subGroupShell",
            }
          : DEFAULT_FOCUS_STATUS,
      );
    }

    if (
      info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerB
    ) {
      const milestoneLength =
        entryData?.Deals?.["Licensee Table"]?.[PARTNER_B_INDEX]?.[
          "Rights Scope"
        ]?.length || 0;

      setEntryData(clearPartnerBRightScopeGroupShell());
      changeCurrentHistory({
        entryData: clearPartnerBRightScopeGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(
        milestoneLength > 1 && !checkDisabledDeleteGroupShell()
          ? {
              type: "delete",
              container: "subGroupShell",
            }
          : DEFAULT_FOCUS_STATUS,
      );
    }

    if (
      info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone
    ) {
      const milestoneLength =
        entryData?.Deals?.["Licensee Table"]?.[info.tableIndex]?.[
          "Strategic Collaboration & License Agreement terms"
        ]["Milestone Payment and Condition"]?.length || 0;

      setEntryData(clearMilestoneSubGroupShell());
      changeCurrentHistory({
        entryData: clearMilestoneSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(
        milestoneLength > 1 && !checkDisabledDeleteGroupShell()
          ? {
              type: "delete",
              container: "subGroupShell",
            }
          : DEFAULT_FOCUS_STATUS,
      );
    }

    if (
      info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail
    ) {
      const otherPaymentAndDetailLength =
        entryData?.Deals?.[ENTRY_DATA_LICENSEE_TABLE]?.[info.tableIndex]?.[
          ENTRY_DATA_LICENSEE_TABLE_KEYS
            .strategicCollaborationLicenseAgreementTerms
        ][
          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
            .otherPaymentAndDetailScLa
        ]?.length || 0;

      setEntryData(clearOtherPaymentAndDetailSubGroupShell());
      changeCurrentHistory({
        entryData: clearOtherPaymentAndDetailSubGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(
        otherPaymentAndDetailLength > 1 && !checkDisabledDeleteGroupShell()
          ? {
              type: "delete",
              container: "subGroupShell",
            }
          : DEFAULT_FOCUS_STATUS,
      );
    }

    if (
      info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.upfront
    ) {
      setEntryData(clearUpfrontSupGroupShell());
      changeCurrentHistory({
        entryData: clearUpfrontSupGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (
      info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.total
    ) {
      setEntryData(clearTotalSupGroupShell());
      changeCurrentHistory({
        entryData: clearTotalSupGroupShell(),
        changedDataInfo: {
          target: "subGroupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex,
            subGroupShellKey,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }
  };

  const handleClickToDeleteMilestoneSubGroupShell = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    if (focusStatus.type === "clear") {
      handleClearSubGroupShell();
    }
    if (focusStatus.type === "delete") {
      deleteCrossLicenseSubGroupShell();
    }
  };

  const handleKeyDownToDeleteMilestoneSubGroupShell = (
    event: KeyboardEvent,
  ) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearSubGroupShell();
    }
    if (focusStatus.type === "delete" && event.key === "Delete") {
      deleteCrossLicenseSubGroupShell();
    }
  };

  return {
    handleClickToDeleteMilestoneSubGroupShell,
    handleKeyDownToDeleteMilestoneSubGroupShell,
  };
};
