import {
  CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import {
  useTableCreatorFocusStatus,
  DEFAULT_FOCUS_STATUS,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import {
  useTableCreatorPositionInfo,
  DEFAULT_POSITION_INFO,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface Return {
  handleClickToClearLicenseeTableGroupShell: () => void;
  handleKeyDownToDeleteLicenseeTableGroupShell: (event: KeyboardEvent) => void;
}

interface Props {
  positionPropsInfo: {
    groupTableKey: DealsSection;
    groupTableIndex: number;
    groupShellKey: string | null;
    groupShellIndex: number | null;
    tableKey: string | null;
    tableIndex: number;
  };
}

export const useClearCrossLicenseGroupShell = ({
  positionPropsInfo,
}: Props): Return => {
  const {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  } = positionPropsInfo;

  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { changeCurrentHistory } = useTableCreatorCurrentHistory();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { info, changeInfo } = useTableCreatorPositionInfo();

  const PARTNER_A_INDEX = 1;
  const PARTNER_B_INDEX = 0;
  /** Licensing Partner A ✅
   *  Licensing Partner B ✅
   *  Rights Scope Partner A ✅
   *  Rights Scope Partner B ✅
   *  Financial Terms Partner A ✅
   *  Financial Terms Partner B ✅
   *  Other Financial Terms ✅
   *  Deal Purpose ✅
   *  Additional Information ✅
   *
   */

  const handleClearGroupShell = () => {
    const clearRightScopeGroupShell = (
      partnerTableIndex: number,
    ): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === partnerTableIndex) {
                return {
                  ...tableInfo,
                  "Rights Scope": tableInfo["Rights Scope"]?.map(
                    (groupShellInfo) => {
                      return {
                        ...groupShellInfo,
                        Drug: {
                          ...groupShellInfo.Drug,
                          value: [],
                        },
                        Exclusivity: {
                          ...groupShellInfo.Exclusivity,
                          value: [],
                        },
                        Indication: {
                          ...groupShellInfo.Indication,
                          value: [],
                        },
                        Territory: {
                          ...groupShellInfo.Territory,
                          value: [],
                        },
                      };
                    },
                  ),
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearFinancialTermsGroupShell = (
      partnerTableIndex: number,
    ): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === partnerTableIndex) {
                const targetSCandLaData =
                  tableInfo?.[
                    "Strategic Collaboration & License Agreement terms"
                  ];
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...targetSCandLaData,
                    "Milestone Payment and Condition": targetSCandLaData[
                      "Milestone Payment and Condition"
                    ].map((subGroupShellInfo) => {
                      return {
                        ...subGroupShellInfo,
                        "Milestone Condition Title": {
                          ...subGroupShellInfo["Milestone Condition Title"],
                          value: [],
                        },
                        "Milestone Payment": {
                          ...subGroupShellInfo?.["Milestone Payment"],
                          value: [],
                        },
                      };
                    }),
                    Royalty: {
                      ...targetSCandLaData.Royalty,
                      value: [],
                    },
                    "Total Payment (SC & LA)": {
                      ...targetSCandLaData["Total Payment (SC & LA)"],
                      value: [],
                    },
                    "Upfront Payment": {
                      ...targetSCandLaData["Upfront Payment"],
                      value: [],
                    },
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa]:
                      {
                        ...targetSCandLaData[
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .disclosedPaymentsSumScLa
                        ],
                        value: [],
                      },
                    [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                      targetSCandLaData[
                        ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                          .otherPaymentAndDetailScLa
                      ].map((subGroupShellInfo) => {
                        return {
                          ...subGroupShellInfo,
                          [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa]:
                            {
                              ...subGroupShellInfo[
                                ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                  .otherPaymentDetailScLa
                              ],
                              value: [],
                            },
                          [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa]:
                            {
                              ...subGroupShellInfo?.[
                                ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                  .otherPaymentScLa
                              ],
                              value: [],
                            },
                        };
                      }),
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearOtherFinancialTermsGroupShell = (): ArticleEntryData => {
      if (info.groupShellIndex === null) return entryData;
      if (info.groupShellKey !== "Other Financial Terms") return entryData;

      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === PARTNER_A_INDEX) {
                const targetSCandLaData =
                  tableInfo?.[
                    "Strategic Collaboration & License Agreement terms"
                  ];
                return {
                  ...tableInfo,
                  "Strategic Collaboration & License Agreement terms": {
                    ...targetSCandLaData,
                    "Other Financial Terms (SC & LA)": {
                      ...targetSCandLaData["Other Financial Terms (SC & LA)"],
                      value: [],
                    },
                  },
                };
              }

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearLicenseeGroupShell = (
      partnerTableIndex: number,
    ): ArticleEntryData => {
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Licensee Table": entryData.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === partnerTableIndex)
                return {
                  ...tableInfo,
                  "Licensee Name": {
                    ...tableInfo["Licensee Name"],
                    value: [],
                  },
                  "Strategic Collaboration & License Agreement terms": {
                    ...tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ],
                    "Licensee Responsibility & Rights (SC & LA)": {
                      ...tableInfo[
                        "Strategic Collaboration & License Agreement terms"
                      ]["Licensee Responsibility & Rights (SC & LA)"],
                      value: [],
                    },
                  },
                };

              return tableInfo;
            },
          ),
        },
      };
    };

    const clearDealPurposeGroupShell = (): ArticleEntryData => {
      const targetData = entryData.Deals?.["Additional Information (Deal)"];
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Additional Information (Deal)": {
            ...targetData,
            "Purpose of the Deal": {
              ...targetData?.["Purpose of the Deal"],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    const clearAdditionalInformationGroupShell = (): ArticleEntryData => {
      const targetData = entryData.Deals?.["Additional Information (Deal)"];
      return {
        ...entryData,
        Deals: {
          ...entryData.Deals,
          "Additional Information (Deal)": {
            ...targetData,
            "Additional Information": {
              ...targetData?.["Additional Information"],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    if (
      info.tableKey === "Deal Purpose" &&
      info.groupShellKey === "Deal Purpose"
    ) {
      setEntryData(clearDealPurposeGroupShell());
      changeCurrentHistory({
        entryData: clearDealPurposeGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
    if (
      info.tableKey === "Additional Information" &&
      info.groupShellKey === "Additional Information"
    ) {
      setEntryData(clearAdditionalInformationGroupShell());
      changeCurrentHistory({
        entryData: clearAdditionalInformationGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });
      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.licensingPartnerA
    ) {
      setEntryData(clearLicenseeGroupShell(PARTNER_A_INDEX));
      changeCurrentHistory({
        entryData: clearLicenseeGroupShell(PARTNER_A_INDEX),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.licensingPartnerB
    ) {
      setEntryData(clearLicenseeGroupShell(PARTNER_B_INDEX));
      changeCurrentHistory({
        entryData: clearLicenseeGroupShell(PARTNER_B_INDEX),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScopePartnerA
    ) {
      setEntryData(clearRightScopeGroupShell(PARTNER_A_INDEX));
      changeCurrentHistory({
        entryData: clearRightScopeGroupShell(PARTNER_A_INDEX),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScopePartnerB
    ) {
      setEntryData(clearRightScopeGroupShell(PARTNER_B_INDEX));
      changeCurrentHistory({
        entryData: clearRightScopeGroupShell(PARTNER_B_INDEX),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTermsPartnerA
    ) {
      setEntryData(clearFinancialTermsGroupShell(PARTNER_A_INDEX));
      changeCurrentHistory({
        entryData: clearFinancialTermsGroupShell(PARTNER_A_INDEX),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTermsPartnerB
    ) {
      setEntryData(clearFinancialTermsGroupShell(PARTNER_B_INDEX));
      changeCurrentHistory({
        entryData: clearFinancialTermsGroupShell(PARTNER_B_INDEX),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }

    if (
      info.groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.otherFinancialTerms
    ) {
      setEntryData(clearOtherFinancialTermsGroupShell());
      changeCurrentHistory({
        entryData: clearOtherFinancialTermsGroupShell(),
        changedDataInfo: {
          target: "groupShell",
          action: "clear",
          position: {
            groupShellKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupTableKey,
            groupShellIndex,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
      changeInfo(DEFAULT_POSITION_INFO);
    }
  };

  const handleClickToClearLicenseeTableGroupShell = () => {
    if (focusStatus.type === "clear") {
      handleClearGroupShell();
    }
  };

  const handleKeyDownToDeleteLicenseeTableGroupShell = (
    event: KeyboardEvent,
  ) => {
    if (focusStatus.type === "clear" && event.key === "Delete") {
      handleClearGroupShell();
    }
  };

  return {
    handleClickToClearLicenseeTableGroupShell,
    handleKeyDownToDeleteLicenseeTableGroupShell,
  };
};
