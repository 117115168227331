import styled from "styled-components";

export const PressContentHoverModal = styled.div`
  position: absolute;
  top: calc(100% + 0.4rem);
  left: 0.4rem;

  width: 100rem;
  height: 0;
  max-height: 28.8rem;
  border-radius: var(--Border-Radius-4, 0.4rem);
  background: var(--Color-Common-Bg-Primary, #fff);
  overflow: hidden;
  z-index: 2;
`;

export const PressContents = styled.div`
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 12;
  align-self: stretch;

  color: var(--Color-Common-Text-Primary, #2a2f37);
  text-overflow: ellipsis;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 145%;
  max-height: 26.4rem;
  overflow: hidden;
  white-space: pre-wrap;
`;
