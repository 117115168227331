import React from "react";

import * as S from "./ArticleContentMainBanner.style";
import { StatusChipList } from "../../../business/Market";
import { CompanyInfo } from "../../../business/Market/types/marketInfo.types";
import { checkHasValidStockData } from "../../../business/Market/utils/checkHasValidStockData/checkHasValidStockData";
import CompanyImageGroup from "../../../shared/components/CompanyImageGroup/CompanyImageGroup";
import { IconExternalLink } from "../../../shared/Icons";

const IconCalendar = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9583 3.66666C15.9583 3.32148 15.6785 3.04166 15.3333 3.04166C14.9882 3.04166 14.7083 3.32148 14.7083 3.66666V4.70834H9.29166V3.66666C9.29166 3.32148 9.01184 3.04166 8.66666 3.04166C8.32149 3.04166 8.04166 3.32148 8.04166 3.66666V4.70834H6.16667C4.90101 4.70834 3.875 5.73436 3.875 7.00001V10.3333V18.6667C3.875 19.9323 4.90101 20.9583 6.16667 20.9583H17.8333C19.099 20.9583 20.125 19.9323 20.125 18.6667V10.3333V7.00001C20.125 5.73436 19.099 4.70834 17.8333 4.70834H15.9583V3.66666ZM18.875 9.70834V7.00001C18.875 6.42471 18.4086 5.95834 17.8333 5.95834H15.9583V6.99999C15.9583 7.34517 15.6785 7.62499 15.3333 7.62499C14.9882 7.62499 14.7083 7.34517 14.7083 6.99999V5.95834H9.29166V6.99999C9.29166 7.34517 9.01184 7.62499 8.66666 7.62499C8.32149 7.62499 8.04166 7.34517 8.04166 6.99999V5.95834H6.16667C5.59137 5.95834 5.125 6.42471 5.125 7.00001V9.70834H18.875ZM5.125 10.9583H18.875V18.6667C18.875 19.242 18.4086 19.7083 17.8333 19.7083H6.16667C5.59137 19.7083 5.125 19.242 5.125 18.6667V10.9583Z"
      fill="white"
    />
  </svg>
);

interface Props {
  articleSection: string;
  title: React.ReactNode;
  image: string;
  mainLabels: React.ReactNode;
  date: string;
  articleId?: number;
  mainCompanyLogos?: string[] | null;
  pressUrl?: string;
  isDisplayMarketInfo?: boolean;
  companyData?: CompanyInfo[];
  realTimeStockData?: CompanyInfo[];
}

const ArticleContentMainBanner = ({
  articleSection,
  articleId,
  title,
  image,
  mainLabels,
  date,
  mainCompanyLogos,
  pressUrl,
  companyData,
  isDisplayMarketInfo = false,
  realTimeStockData,
}: Props) => {
  return (
    <S.MainBannerWrapper $imageUrl={image}>
      <S.MainBannerContentsWrapper>
        <S.SectionAndMarketWrapper>
          <S.ArticleSectionText>{articleSection}</S.ArticleSectionText>
          {isDisplayMarketInfo &&
            companyData &&
            checkHasValidStockData({
              articleId,
              companyData,
              realTimeCompanyData: realTimeStockData,
            }) && (
              <S.MarketInfoWrapper>
                <S.SameDayText>Same Day :</S.SameDayText>
                <StatusChipList
                  marketInfoList={companyData}
                  realTimeCompanyData={realTimeStockData}
                />
              </S.MarketInfoWrapper>
            )}
        </S.SectionAndMarketWrapper>
        <S.TitleWrapper>
          {mainCompanyLogos && mainCompanyLogos.length > 0 && (
            <CompanyImageGroup
              imageUrlList={mainCompanyLogos}
              imageSize={44}
              containerSize={48}
              type="vertical"
            />
          )}
          <S.Title>{title}</S.Title>
        </S.TitleWrapper>
        <S.MainLabelWrapper>{mainLabels}</S.MainLabelWrapper>
        <S.BottomRowWrapper>
          <S.DateWrapper>
            {IconCalendar}
            <S.DateText>{date}</S.DateText>
          </S.DateWrapper>
          {pressUrl && (
            <S.UrlWrapper href={pressUrl} target="_blank">
              full press or related doc.
              <IconExternalLink width={20} height={20} />
            </S.UrlWrapper>
          )}
        </S.BottomRowWrapper>
      </S.MainBannerContentsWrapper>
    </S.MainBannerWrapper>
  );
};

export default ArticleContentMainBanner;
