import { CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS } from "@frontend/common";

export const checkIsRightScopeGroup = (
  groupShellKey: string | null,
): boolean => {
  return (
    groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScopePartnerA ||
    groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScopePartnerB
  );
};
