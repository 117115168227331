import styled, { css } from "styled-components";

export const PressListTable = styled.table`
  width: 100%;
  background-color: ${({ theme }) => theme.color.neural.gray00};
  margin-top: 2.4rem;
`;

export const CommonTdStyle = css`
  position: relative;
  padding: 1rem;
  color: ${({ theme }) => theme.color.neural.gray08};
  font-size: 1.2rem;
  line-height: 1.8rem;
  border: 0.1rem solid ${({ theme }) => theme.color.neural.gray02};
  font-weight: 700;
  vertical-align: top;
`;

export const CommonErrorStyles = css`
  background-color: ${({ theme }) => theme.color.neural.gray01};
  color: rgba(60, 71, 87, 0.5);

  a {
    color: rgba(60, 71, 87, 0.5);
  }

  span {
    color: rgba(60, 71, 87, 1);
  }
`;

// NOTE : 스타일은 없지만, 가독성 높이기 위해 정의
export const PressListTableBody = styled.tbody``;

export const PressListTableTr = styled.tr<{
  isError: boolean;
}>`
  ${({ isError }) =>
    isError &&
    css`
      td {
        ${CommonErrorStyles}
      }
    `}
`;

export const PressListTablePressId = styled.td`
  ${CommonTdStyle}
  width: 6.4rem;
  background-color: ${({ theme }) => theme.color.neural.gray01};
  font-weight: 500;
`;

export const PressListTableCompany = styled.td`
  ${CommonTdStyle}
  width: 12rem;
  font-weight: 500;
`;

export const PressListTableEditor = styled.td`
  ${CommonTdStyle}
  width: 8.8rem;
`;

export const PressListTableClassification = styled.td`
  ${CommonTdStyle}
  width: 13.6rem;
`;

export const PressListTableDate = styled.td`
  ${CommonTdStyle}
  width: 10.4rem;
  font-weight: 600;
`;

export const PressListTableUnsuitable = styled.td<{ isUnsuitable: boolean }>`
  ${CommonTdStyle}
  width: 8.8rem;
  background-color: ${({ isUnsuitable }) =>
    isUnsuitable ? "rgba(219, 15, 114, 0.1)" : ""};
`;

export const PressListTablePublishable = styled.td`
  ${CommonTdStyle}
  width: 8.8rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  vertical-align: middle;
`;

export const StatusTableData = styled.td<{ code: string }>`
  ${CommonTdStyle}
  width: 7.2rem;
  text-align: center;
  vertical-align: middle;
`;

export const ClassificationTableData = styled.td<{
  status: string;
}>`
  ${CommonTdStyle}
  width: 13.6rem;

  ${({ status, theme }) =>
    ["S300"].includes(status) &&
    css`
      background-color: ${theme.color.neural.gray01};
      opacity: 0.5;
    `}
`;
