import React from "react";

import * as S from "./StatusChipList.style";
import { CompanyInfo } from "../../types/marketInfo.types";
import StatusChip from "../StatusChip/StatusChip";

type Props = {
  marketInfoList: CompanyInfo[];
  realTimeCompanyData?: CompanyInfo[] | undefined;
  size?: "md" | "lg";
  isColumnAligned?: boolean;
  isInteractive?: boolean;
  isMobileTextColumn?: boolean;
};

const checkCurrentStockViewStatus = (roc: number | null) => {
  if (roc === null) return "keep";

  if (roc > 0) return "up";
  if (roc < 0) return "down";
  if (roc === 0) return "keep";

  return "keep";
};

const StatusChipList = ({
  marketInfoList,
  size,
  isColumnAligned,
  realTimeCompanyData,
  isInteractive,
  isMobileTextColumn = false,
}: Props) => {
  return (
    <S.StatusChipList
      $isColumnAligned={isColumnAligned}
      className="stock-chip-list"
    >
      {marketInfoList.map((marketChipItem) => {
        const { articleId, pressCompanyId, exchange, symbol } = marketChipItem;

        let marketInfo = marketChipItem;

        if (realTimeCompanyData && realTimeCompanyData.length > 0) {
          const realTimeDataItem = realTimeCompanyData.find(
            (item) =>
              item.articleId === articleId &&
              item.pressCompanyId === pressCompanyId &&
              item.exchange === exchange &&
              item.symbol === symbol,
          );

          if (realTimeDataItem) {
            marketInfo = realTimeDataItem;
          }
        }

        return (
          marketInfo.roc !== null && (
            <StatusChip
              key={marketChipItem.companyName}
              marketInfo={marketInfo}
              chipStatus={checkCurrentStockViewStatus(marketInfo.roc)}
              isInteractive={isInteractive}
              size={size}
              isMobileTextColumn={isMobileTextColumn}
            />
          )
        );
      })}
    </S.StatusChipList>
  );
};

export default StatusChipList;
