/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ENTRY_DATA_SECTION_KEYS as SECTION_KEYS,
  ENTRY_DATA_DRUG_PROFILE_GROUP_TABLE_KEY as DRUG_PROFILE,
  ENTRY_DATA_DRUG_PROFILE_SHELL_KEYS as DRUG_PROFILE_SHELL_KEYS,
  ENTRY_DATA_KEY_COMMENTS_GROUP_TABLE_KEY as KEY_COMMENT,
  ENTRY_DATA_FUNDED_COMPANY_GROUP_TABLE_KEY as FUNDED_COMPANY,
  ENTRY_DATA_ROUND_TYPE_AND_STATUS_GROUP_TABLE_KEY as ROUND_TYPE_AND_STATUS,
  ENTRY_DATA_FINANCING_INFORMATION_GROUP_TABLE_KEY as FINANCING_INFORMATION,
  ENTRY_DATA_ADDITIONAL_INFORMATION_VENTURE_CAPITAL_GROUP_TABLE_KEY as ADDITIONAL_INFORMATION_VENTURE_CAPITAL,
  ENTRY_DATA_INVESTORS_GROUP_TABLE_KEY as INVESTORS,
  MERGE_ACQUISITION_SHELL_KEYS,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS,
  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
  ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_DEAL_TYPE_AND_STATUS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN,
  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS,
  ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS,
} from "@frontend/common";

import { DealTypeAndStatusKey } from "@/types/deals.types";
import {
  ExpeditedProgramContentsGroupShellKey,
  ExpeditedProgramDrugProfileShellKeysNotAttributionId,
  ExpeditedProgramKeyCommentShellKeys,
} from "@/types/expeditedProgram.types";
import {
  EntryDataInvestorValue,
  VentureCapitalAdditionalInformationShellKeys,
  VentureCapitalFinancingInformationShellKeys,
  VentureCapitalFundedCompanyShellKeys,
  VentureCapitalInvestorsShellKeys,
  VentureCapitalRoundTypeAndStatusShellKeys,
} from "@/types/ventureCapital.types";
import { createRandomString } from "@organisms/TableCreatorComment/components/CommentModalContainer/components/CommentModalMessageComposer/utils/createRandomString";

import {
  EXPEDITED_PROGRAM,
  EXPEDITED_PROGRAM_GROUP_KEY as eg,
} from "./expeditedProgramKey.constants";
import { createAttributionId } from "../utils/createAttributionId";

import type { GroupTableKeyType } from "../types/tableCreator.types";
import type { ArticleEntryData } from "@/types/articleEntryData.types";
import type {
  ClinicalTrialDesign1ShellKeysWithoutTrialIntervention,
  ClinicalTrialsClinicalEndpointsDescriptionShellKey,
  ClinicalTrialsClinicalResultsShellKey,
  ClinicalTrialsKeyCommentShellKey,
  DesignGroupInformationShellKey,
  DrugProfileShellKeysNotIncludeAttributionId,
  EndpointsGroupInformation,
  EndpointsValue,
} from "@/types/clinicalTrials.types";
import type {
  ApprovalProcessTypeShellKey,
  QuoteAndSpokesPersonType,
  RegulatoryDrugProfileShellKeysNotAttributionId,
} from "@/types/regulatory.types";

export const TEXTFIELD_PATH_TO_ENTER_ENTRY_DATA: Record<
  string,
  {
    [key in GroupTableKeyType]?: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) => ArticleEntryData;
  }
> = {
  "Clinical Trials": {
    "Drug Profile": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) => ({
      ...data,
      "Clinical Trials": {
        ...data["Clinical Trials"],
        "Drug Profile": data["Clinical Trials"]?.["Drug Profile"]?.map(
          (item, index) => {
            if (index === tableIndex) {
              if (shellKey === "Therapeutic Area") {
                return {
                  ...item,
                  attributionInfo: {
                    attributionId:
                      item?.attributionInfo?.attributionId ||
                      createRandomString(8),
                  },
                  "Therapeutic Area": {
                    ...item["Therapeutic Area"],
                    attributionId:
                      item["Therapeutic Area"]?.attributionId ||
                      createRandomString(8),
                    value:
                      item["Therapeutic Area"] &&
                      item["Therapeutic Area"]?.value.length > valueIndex
                        ? item["Therapeutic Area"].value
                            ?.map((valueItem, valueItemIndex) => {
                              if (valueItemIndex === valueIndex) {
                                const checkDictionaryList = dictionaryList
                                  .map((listItem) => {
                                    const currentListItem =
                                      listItem.toLocaleLowerCase();
                                    const currentValueText =
                                      valueText.toLocaleLowerCase();

                                    if (currentListItem === currentValueText)
                                      return listItem;

                                    return null;
                                  })
                                  .filter(
                                    (listValueItem) => listValueItem !== null,
                                  );

                                return {
                                  text: checkDictionaryList[0] || "",
                                  is_modified: true,
                                };
                              }

                              return valueItem;
                            })
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text.toLowerCase() ===
                                  valueItem?.text.toLowerCase(),
                              );

                              return valueItemIndex === lastValueIndex;
                            })
                        : [
                            ...item["Therapeutic Area"].value,
                            { text: valueText, is_modified: true },
                          ]
                            ?.map(
                              (
                                valueItem: {
                                  text: string;
                                  is_modified: boolean;
                                },
                                valueItemIndex: number,
                              ) => {
                                if (valueItemIndex === valueIndex) {
                                  const checkDictionaryList =
                                    dictionaryList.filter((listItem) => {
                                      const currentListItem =
                                        listItem.toLocaleLowerCase();
                                      const currentValueText =
                                        valueText.toLocaleLowerCase();

                                      return (
                                        currentListItem === currentValueText
                                      );
                                    });

                                  return {
                                    text: checkDictionaryList[0],
                                    is_modified: true,
                                  };
                                }

                                return valueItem;
                              },
                            )
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text?.toLowerCase() ===
                                  valueItem?.text?.toLowerCase(),
                              );
                              return valueItemIndex === lastValueIndex;
                            }),
                  },
                };
              }

              return {
                ...item,
                attributionInfo: {
                  attributionId:
                    item?.attributionInfo?.attributionId ||
                    createRandomString(8),
                },
                [shellKey as DrugProfileShellKeysNotIncludeAttributionId]: {
                  ...item[
                    shellKey as DrugProfileShellKeysNotIncludeAttributionId
                  ],
                  attributionId:
                    item[
                      shellKey as DrugProfileShellKeysNotIncludeAttributionId
                    ]?.attributionId || createRandomString(8),
                  value:
                    item[
                      shellKey as DrugProfileShellKeysNotIncludeAttributionId
                    ] &&
                    item[
                      shellKey as DrugProfileShellKeysNotIncludeAttributionId
                    ]?.value.length > valueIndex
                      ? item[
                          shellKey as DrugProfileShellKeysNotIncludeAttributionId
                        ].value
                          ?.map((valueItem, valueItemIndex) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return { text: valueText, is_modified: true };
                            }

                            return valueItem;
                          })
                          .filter((valueItem) => valueItem !== null)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          })
                      : [
                          ...item[
                            shellKey as DrugProfileShellKeysNotIncludeAttributionId
                          ].value,
                          { text: valueText, is_modified: true },
                        ]
                          ?.map(
                            (
                              valueItem: {
                                text: string;
                                is_modified: boolean;
                              },
                              valueItemIndex: number,
                            ) => {
                              if (valueItemIndex === valueIndex) {
                                if (valueText === "") {
                                  return null;
                                }
                                return { text: valueText, is_modified: true };
                              }

                              return valueItem;
                            },
                          )
                          .filter((valueItem) => valueItem !== null)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          }),
                },
              };
            }
            return item;
          },
        ),
      },
    }),
    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) =>
      ({
        ...data,
        [ENTRY_DATA_SECTION_KEYS.clinicalTrial]: {
          ...data[ENTRY_DATA_SECTION_KEYS.clinicalTrial],
          [ENTRY_DATA_CLINICAL_TRIAL_DESIGN]: data[
            ENTRY_DATA_SECTION_KEYS.clinicalTrial
          ]?.[ENTRY_DATA_CLINICAL_TRIAL_DESIGN]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableIndex === tableInfoIndex) {
                const clinicalDesign1Data =
                  tableInfo[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1
                  ];

                const clinicalDesign2Data =
                  tableInfo[
                    ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2
                  ];

                if (
                  groupShellKey ===
                  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign1
                )
                  return {
                    ...tableInfo,
                    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign1]:
                      {
                        ...clinicalDesign1Data,
                        [shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention]:
                          {
                            ...clinicalDesign1Data[
                              shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                            ],
                            attributionId:
                              clinicalDesign1Data[
                                shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                              ]?.attributionId || createRandomString(8),
                            value:
                              clinicalDesign1Data[
                                shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                              ] &&
                              clinicalDesign1Data[
                                shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                              ]?.value.length > valueIndex
                                ? clinicalDesign1Data[
                                    shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                                  ].value
                                    ?.map((valueItem, valueItemIndex) => {
                                      if (valueItemIndex === valueIndex) {
                                        if (valueText === "") {
                                          return null;
                                        }
                                        return {
                                          text: valueText,
                                          is_modified: true,
                                        };
                                      }

                                      return valueItem;
                                    })
                                    .filter((valueItem) => valueItem !== null)
                                    .filter(
                                      (
                                        valueItem,
                                        valueItemIndex,
                                        valueList,
                                      ) => {
                                        const lastValueIndex =
                                          valueList.findIndex(
                                            (target) =>
                                              target?.text.toLowerCase() ===
                                              valueItem?.text.toLowerCase(),
                                          );
                                        return (
                                          valueItemIndex === lastValueIndex
                                        );
                                      },
                                    )
                                : [
                                    ...clinicalDesign1Data[
                                      shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                                    ].value,
                                    { text: valueText, is_modified: true },
                                  ]
                                    ?.map(
                                      (
                                        valueItem: {
                                          text: string;
                                          is_modified: boolean;
                                        },
                                        valueItemIndex: number,
                                      ) => {
                                        if (valueItemIndex === valueIndex) {
                                          if (valueText === "") {
                                            return null;
                                          }
                                          return {
                                            text: valueText,
                                            is_modified: true,
                                          };
                                        }

                                        return valueItem;
                                      },
                                    )
                                    .filter((valueItem) => valueItem !== null)
                                    .filter(
                                      (
                                        valueItem,
                                        valueItemIndex,
                                        valueList,
                                      ) => {
                                        const lastValueIndex =
                                          valueList.findIndex(
                                            (target) =>
                                              target?.text.toLowerCase() ===
                                              valueItem?.text.toLowerCase(),
                                          );
                                        return (
                                          valueItemIndex === lastValueIndex
                                        );
                                      },
                                    ),
                          },
                      },
                  };

                if (
                  groupShellKey ===
                  CLINICAL_TRIAL_DESIGN_GROUP_SHELL_KEYS.clinicalDesign2
                )
                  return {
                    ...tableInfo,
                    [ENTRY_DATA_CLINICAL_TRIAL_DESIGN_KEYS.clinicalTrialDesign2]:
                      clinicalDesign2Data?.map(
                        (subGroupShellInfo, subGroupShellInfoIndex) => {
                          if (subGroupShellInfoIndex !== subGroupShellIndex)
                            return subGroupShellInfo;
                          if (
                            shellKey === "Arm Name" ||
                            shellKey === "Patients Number"
                          ) {
                            return {
                              ...subGroupShellInfo,
                              [shellKey]: {
                                ...subGroupShellInfo[shellKey],
                                value:
                                  (subGroupShellInfo[shellKey].value || [])
                                    .length > valueIndex
                                    ? (subGroupShellInfo[shellKey].value || [])
                                        .map((shellValue, shellValueIndex) => {
                                          if (shellValueIndex === valueIndex) {
                                            if (valueText === "") {
                                              return null;
                                            }
                                            return {
                                              text: valueText,
                                              is_modified: true,
                                            };
                                          }
                                          return shellValue;
                                        })
                                        .filter(
                                          (shellValue) => shellValue !== null,
                                        )
                                        .filter(
                                          (
                                            valueItem,
                                            valueItemIndex,
                                            valueList,
                                          ) => {
                                            const lastValueIndex =
                                              valueList.findIndex(
                                                (target) =>
                                                  target?.text.toLowerCase() ===
                                                  valueItem?.text.toLowerCase(),
                                              );
                                            return (
                                              valueItemIndex === lastValueIndex
                                            );
                                          },
                                        )
                                    : [
                                        ...(subGroupShellInfo[shellKey].value ||
                                          []),
                                        { text: valueText, is_modified: true },
                                      ]
                                        .map((shellValue, shellValueIndex) => {
                                          if (shellValueIndex === valueIndex) {
                                            if (valueText === "") {
                                              return null;
                                            }
                                            return {
                                              text: valueText,
                                              is_modified: true,
                                            };
                                          }
                                          return shellValue;
                                        })
                                        .filter(
                                          (shellValue) => shellValue !== null,
                                        )
                                        .filter(
                                          (
                                            valueItem,
                                            valueItemIndex,
                                            valueList,
                                          ) => {
                                            const lastValueIndex =
                                              valueList.findIndex(
                                                (target) =>
                                                  target?.text.toLowerCase() ===
                                                  valueItem?.text.toLowerCase(),
                                              );
                                            return (
                                              valueItemIndex === lastValueIndex
                                            );
                                          },
                                        ),
                              },
                            };
                          }

                          return {
                            ...subGroupShellInfo,
                            "Design Group Information": subGroupShellInfo?.[
                              "Design Group Information"
                            ]?.map((shellValueInfo, shellValueIndex) => {
                              if (shellValueIndex !== shellIndex)
                                return shellValueInfo;
                              return {
                                ...shellValueInfo,
                                [shellKey]: {
                                  ...shellValueInfo[
                                    shellKey as DesignGroupInformationShellKey
                                  ],
                                  value:
                                    (
                                      shellValueInfo[
                                        shellKey as DesignGroupInformationShellKey
                                      ].value || []
                                    ).length > valueIndex
                                      ? shellValueInfo[
                                          shellKey as DesignGroupInformationShellKey
                                        ].value
                                          .map((valueInfo, valueInfoIndex) => {
                                            if (valueInfoIndex === valueIndex) {
                                              if (valueText === "") {
                                                return null;
                                              }
                                              return {
                                                text: valueText,
                                                is_modified: true,
                                              };
                                            }
                                            return valueInfo;
                                          })
                                          .filter(
                                            (valueInfo) => valueInfo !== null,
                                          )
                                          .filter(
                                            (
                                              valueItem,
                                              valueItemIndex,
                                              valueList,
                                            ) => {
                                              const lastValueIndex =
                                                valueList.findIndex(
                                                  (target) =>
                                                    target?.text.toLowerCase() ===
                                                    valueItem?.text.toLowerCase(),
                                                );
                                              return (
                                                valueItemIndex ===
                                                lastValueIndex
                                              );
                                            },
                                          )
                                      : [
                                          ...(shellValueInfo[
                                            shellKey as DesignGroupInformationShellKey
                                          ].value || []),
                                          {
                                            text: valueText,
                                            is_modified: true,
                                          },
                                        ]
                                          .map((valueInfo, valueInfoIndex) => {
                                            if (valueInfoIndex === valueIndex) {
                                              if (valueText === "") {
                                                return null;
                                              }
                                              return {
                                                text: valueText,
                                                is_modified: true,
                                              };
                                            }
                                            return valueInfo;
                                          })
                                          .filter(
                                            (valueInfo) => valueInfo !== null,
                                          )
                                          .filter(
                                            (
                                              valueItem,
                                              valueItemIndex,
                                              valueList,
                                            ) => {
                                              const lastValueIndex =
                                                valueList.findIndex(
                                                  (target) =>
                                                    target?.text.toLowerCase() ===
                                                    valueItem?.text.toLowerCase(),
                                                );
                                              return (
                                                valueItemIndex ===
                                                lastValueIndex
                                              );
                                            },
                                          ),
                                },
                              };
                            }),
                          };
                        },
                      ),
                  };
              }

              return tableInfo;
            },
          ),
        },
      } as ArticleEntryData),

    "Clinical Results": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) =>
      ({
        ...data,
        "Clinical Trials": {
          ...data["Clinical Trials"],
          "Clinical Results": {
            ...data["Clinical Trials"]?.["Clinical Results"],
            [shellKey]: {
              ...data["Clinical Trials"]?.["Clinical Results"]?.[
                shellKey as ClinicalTrialsClinicalResultsShellKey
              ],

              value:
                [
                  ...(data["Clinical Trials"]?.["Clinical Results"]?.[
                    shellKey as ClinicalTrialsClinicalResultsShellKey
                  ].value || []),
                ].length > valueIndex
                  ? data["Clinical Trials"]?.["Clinical Results"]?.[
                      shellKey as ClinicalTrialsClinicalResultsShellKey
                    ].value
                      .map((valueItem, valueItemIndex) => {
                        if (valueItemIndex === valueIndex) {
                          if (valueText === "") {
                            return null;
                          }
                          return { text: valueText, is_modified: true };
                        }
                        return valueItem;
                      })
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      })
                  : [
                      ...(data["Clinical Trials"]?.["Clinical Results"]?.[
                        shellKey as ClinicalTrialsClinicalResultsShellKey
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      .map((valueItem, valueItemIndex) => {
                        if (valueItemIndex === valueIndex) {
                          if (valueText === "") {
                            return null;
                          }
                          return { text: valueText, is_modified: true };
                        }
                        return valueItem;
                      })
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      }),
            },
          },
        },
      } as ArticleEntryData),

    "Clinical Endpoints (Table)": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) =>
      ({
        ...data,
        "Clinical Trials": {
          ...data["Clinical Trials"],
          "Clinical Endpoints (Table)": data?.["Clinical Trials"]?.[
            "Clinical Endpoints (Table)"
          ]?.map((tableInfo, tableInfoIndex) => {
            if (tableInfoIndex === tableIndex) {
              if (shellKey === "Endpoints Table Notes") {
                return {
                  ...tableInfo,
                  [shellKey]: {
                    ...tableInfo[shellKey],
                    value:
                      valueText !== ""
                        ? [
                            {
                              text: valueText,
                              is_modified: true,
                            },
                          ]
                        : [],
                  },
                };
              }
              return {
                ...tableInfo,
                "Endpoints Table Information": tableInfo?.[
                  "Endpoints Table Information"
                ]?.map((groupShellInfo, groupShellInfoIndex) => {
                  if (
                    shellKey === "Endpoint Classification" ||
                    shellKey === "Endpoint Name including Measurement Timing"
                  ) {
                    if (groupShellInfoIndex !== groupShellIndex)
                      return groupShellInfo;
                    return {
                      ...groupShellInfo,
                      [shellKey]: {
                        ...groupShellInfo[shellKey as keyof EndpointsValue],
                        value:
                          valueText !== ""
                            ? [
                                {
                                  text: valueText,
                                  is_modified: true,
                                },
                              ]
                            : [],
                      },
                    };
                  }
                  if (shellKey === "Group Name") {
                    return {
                      ...groupShellInfo,
                      "Endpoint Group Information": groupShellInfo?.[
                        "Endpoint Group Information"
                      ]?.map((shellInfo, shellInfoIndex) => {
                        if (shellInfoIndex !== shellIndex) return shellInfo;
                        return {
                          ...shellInfo,
                          [shellKey]: {
                            ...shellInfo[
                              shellKey as keyof EndpointsGroupInformation
                            ],
                            value:
                              valueText !== ""
                                ? [
                                    {
                                      text: valueText,
                                      is_modified: true,
                                    },
                                  ]
                                : [],
                          },
                        };
                      }),
                    };
                  }
                  // NOTE : ShellKey === ("Group Data" || "P-Value")
                  if (groupShellInfoIndex !== groupShellIndex)
                    return groupShellInfo;
                  return {
                    ...groupShellInfo,
                    "Endpoint Group Information": groupShellInfo?.[
                      "Endpoint Group Information"
                    ]?.map((shellValueInfo, shellValueIndex) => {
                      if (shellValueIndex !== shellIndex) {
                        return shellValueInfo;
                      }
                      return {
                        ...shellValueInfo,
                        [shellKey]: {
                          ...shellValueInfo[
                            shellKey as keyof EndpointsGroupInformation
                          ],
                          value:
                            valueText !== ""
                              ? [
                                  {
                                    text: valueText,
                                    is_modified: true,
                                  },
                                ]
                              : [],
                        },
                      };
                    }),
                  };
                }),
              };
            }
            return tableInfo;
          }),
        },
      } as ArticleEntryData),

    "Clinical Endpoints (Description)": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) =>
      ({
        ...data,
        "Clinical Trials": {
          ...data["Clinical Trials"],
          "Clinical Endpoints (Description)": {
            ...data["Clinical Trials"]?.["Clinical Endpoints (Description)"],
            [shellKey]: {
              ...data["Clinical Trials"]?.[
                "Clinical Endpoints (Description)"
              ]?.[
                shellKey as ClinicalTrialsClinicalEndpointsDescriptionShellKey
              ],

              value:
                [
                  ...(data["Clinical Trials"]?.[
                    "Clinical Endpoints (Description)"
                  ]?.[
                    shellKey as ClinicalTrialsClinicalEndpointsDescriptionShellKey
                  ].value || []),
                ].length > valueIndex
                  ? data["Clinical Trials"]?.[
                      "Clinical Endpoints (Description)"
                    ]?.[
                      shellKey as ClinicalTrialsClinicalEndpointsDescriptionShellKey
                    ].value
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      })
                  : [
                      ...(data["Clinical Trials"]?.[
                        "Clinical Endpoints (Description)"
                      ]?.[
                        shellKey as ClinicalTrialsClinicalEndpointsDescriptionShellKey
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      }),
            },
          },
        },
      } as ArticleEntryData),

    "Key Comment": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) =>
      ({
        ...data,
        "Clinical Trials": {
          ...data["Clinical Trials"],
          "Key Comment": {
            ...data["Clinical Trials"]?.["Key Comment"],
            [shellKey]: {
              ...data["Clinical Trials"]?.["Key Comment"]?.[
                shellKey as ClinicalTrialsKeyCommentShellKey
              ],

              value:
                [
                  ...(data["Clinical Trials"]?.["Key Comment"]?.[
                    shellKey as QuoteAndSpokesPersonType
                  ].value || []),
                ].length > valueIndex
                  ? data["Clinical Trials"]?.["Key Comment"]?.[
                      shellKey as QuoteAndSpokesPersonType
                    ].value
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                  : [
                      ...(data["Clinical Trials"]?.["Key Comment"]?.[
                        shellKey as QuoteAndSpokesPersonType
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null),
            },
          },
        },
      } as ArticleEntryData),
  },
  "Clinical Trial Status": {
    "Drug Profile": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) => ({
      ...data,
      "Clinical Trial Status": {
        ...data["Clinical Trial Status"],
        "Drug Profile": data["Clinical Trial Status"]?.["Drug Profile"]?.map(
          (item, index) => {
            if (index === tableIndex) {
              if (shellKey === "Therapeutic Area") {
                return {
                  ...item,
                  attributionInfo: {
                    attributionId:
                      item?.attributionInfo?.attributionId ||
                      createRandomString(8),
                  },
                  "Therapeutic Area": {
                    ...item["Therapeutic Area"],
                    attributionId:
                      item["Therapeutic Area"]?.attributionId ||
                      createRandomString(8),
                    value:
                      item["Therapeutic Area"] &&
                      item["Therapeutic Area"]?.value.length > valueIndex
                        ? item["Therapeutic Area"].value
                            ?.map((valueItem, valueItemIndex) => {
                              if (valueItemIndex === valueIndex) {
                                const checkDictionaryList = dictionaryList
                                  .map((listItem) => {
                                    const currentListItem =
                                      listItem.toLocaleLowerCase();
                                    const currentValueText =
                                      valueText.toLocaleLowerCase();

                                    if (currentListItem === currentValueText)
                                      return listItem;

                                    return null;
                                  })
                                  .filter(
                                    (listValueItem) => listValueItem !== null,
                                  );

                                return {
                                  text: checkDictionaryList[0] || "",
                                  is_modified: true,
                                };
                              }

                              return valueItem;
                            })
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text.toLowerCase() ===
                                  valueItem?.text.toLowerCase(),
                              );

                              return valueItemIndex === lastValueIndex;
                            })
                        : [
                            ...item["Therapeutic Area"].value,
                            { text: valueText, is_modified: true },
                          ]
                            ?.map(
                              (
                                valueItem: {
                                  text: string;
                                  is_modified: boolean;
                                },
                                valueItemIndex: number,
                              ) => {
                                if (valueItemIndex === valueIndex) {
                                  const checkDictionaryList =
                                    dictionaryList.filter((listItem) => {
                                      const currentListItem =
                                        listItem.toLocaleLowerCase();
                                      const currentValueText =
                                        valueText.toLocaleLowerCase();

                                      return (
                                        currentListItem === currentValueText
                                      );
                                    });

                                  return {
                                    text: checkDictionaryList[0],
                                    is_modified: true,
                                  };
                                }

                                return valueItem;
                              },
                            )
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text?.toLowerCase() ===
                                  valueItem?.text?.toLowerCase(),
                              );
                              return valueItemIndex === lastValueIndex;
                            }),
                  },
                };
              }

              return {
                ...item,
                attributionInfo: {
                  attributionId:
                    item?.attributionInfo?.attributionId ||
                    createRandomString(8),
                },
                [shellKey as DrugProfileShellKeysNotIncludeAttributionId]: {
                  ...item[
                    shellKey as DrugProfileShellKeysNotIncludeAttributionId
                  ],
                  attributionId:
                    item[
                      shellKey as DrugProfileShellKeysNotIncludeAttributionId
                    ]?.attributionId || createRandomString(8),
                  value:
                    item[
                      shellKey as DrugProfileShellKeysNotIncludeAttributionId
                    ] &&
                    item[
                      shellKey as DrugProfileShellKeysNotIncludeAttributionId
                    ]?.value.length > valueIndex
                      ? item[
                          shellKey as DrugProfileShellKeysNotIncludeAttributionId
                        ].value
                          ?.map((valueItem, valueItemIndex) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return { text: valueText, is_modified: true };
                            }

                            return valueItem;
                          })
                          .filter((valueItem) => valueItem !== null)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          })
                      : [
                          ...item[
                            shellKey as DrugProfileShellKeysNotIncludeAttributionId
                          ].value,
                          { text: valueText, is_modified: true },
                        ]
                          ?.map(
                            (
                              valueItem: {
                                text: string;
                                is_modified: boolean;
                              },
                              valueItemIndex: number,
                            ) => {
                              if (valueItemIndex === valueIndex) {
                                if (valueText === "") {
                                  return null;
                                }
                                return { text: valueText, is_modified: true };
                              }

                              return valueItem;
                            },
                          )
                          .filter((valueItem) => valueItem !== null)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          }),
                },
              };
            }
            return item;
          },
        ),
      },
    }),

    "Clinical Trial Design-1": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) =>
      ({
        ...data,
        "Clinical Trial Status": {
          ...data["Clinical Trial Status"],
          "Clinical Trial Design-1": data["Clinical Trial Status"]?.[
            "Clinical Trial Design-1"
          ]?.map((item, index) => {
            if (index === tableIndex) {
              return {
                ...item,
                [shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention]:
                  {
                    ...item[
                      shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                    ],
                    attributionId:
                      item[
                        shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                      ]?.attributionId || createRandomString(8),
                    value:
                      item[
                        shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                      ] &&
                      item[
                        shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                      ]?.value.length > valueIndex
                        ? item[
                            shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                          ].value
                            ?.map((valueItem, valueItemIndex) => {
                              if (valueItemIndex === valueIndex) {
                                if (valueText === "") {
                                  return null;
                                }
                                return { text: valueText, is_modified: true };
                              }

                              return valueItem;
                            })
                            .filter((valueItem) => valueItem !== null)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text.toLowerCase() ===
                                  valueItem?.text.toLowerCase(),
                              );
                              return valueItemIndex === lastValueIndex;
                            })
                        : [
                            ...item[
                              shellKey as ClinicalTrialDesign1ShellKeysWithoutTrialIntervention
                            ].value,
                            { text: valueText, is_modified: true },
                          ]
                            ?.map(
                              (
                                valueItem: {
                                  text: string;
                                  is_modified: boolean;
                                },
                                valueItemIndex: number,
                              ) => {
                                if (valueItemIndex === valueIndex) {
                                  if (valueText === "") {
                                    return null;
                                  }
                                  return { text: valueText, is_modified: true };
                                }

                                return valueItem;
                              },
                            )
                            .filter((valueItem) => valueItem !== null)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text.toLowerCase() ===
                                  valueItem?.text.toLowerCase(),
                              );
                              return valueItemIndex === lastValueIndex;
                            }),
                  },
              };
            }
            return item;
          }),
        },
      } as ArticleEntryData),
  },
  Regulatory: {
    "Drug Profile": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) => ({
      ...data,
      Regulatory: {
        ...data.Regulatory,
        "Drug Profile": data.Regulatory?.["Drug Profile"]?.map(
          (item, index) => {
            if (index === tableIndex) {
              if (shellKey === "Therapeutic Area") {
                return {
                  ...item,
                  attributionInfo: {
                    attributionId:
                      item?.attributionInfo?.attributionId ||
                      createRandomString(8),
                  },
                  "Therapeutic Area": {
                    ...item["Therapeutic Area"],
                    attributionId:
                      item["Therapeutic Area"]?.attributionId ||
                      createRandomString(8),
                    value:
                      item["Therapeutic Area"] &&
                      item["Therapeutic Area"]?.value.length > valueIndex
                        ? item["Therapeutic Area"].value
                            ?.map((valueItem, valueItemIndex) => {
                              if (valueItemIndex === valueIndex) {
                                const checkDictionaryList = dictionaryList
                                  .map((listItem) => {
                                    const currentListItem =
                                      listItem.toLocaleLowerCase();
                                    const currentValueText =
                                      valueText.toLocaleLowerCase();

                                    if (currentListItem === currentValueText)
                                      return listItem;

                                    return null;
                                  })
                                  .filter(
                                    (listValueItem) => listValueItem !== null,
                                  );

                                return {
                                  text: checkDictionaryList[0] || "",
                                  is_modified: true,
                                };
                              }

                              return valueItem;
                            })
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text.toLowerCase() ===
                                  valueItem?.text.toLowerCase(),
                              );

                              return valueItemIndex === lastValueIndex;
                            })
                        : [
                            ...item["Therapeutic Area"].value,
                            { text: valueText, is_modified: true },
                          ]
                            ?.map(
                              (
                                valueItem: {
                                  text: string;
                                  is_modified: boolean;
                                },
                                valueItemIndex: number,
                              ) => {
                                if (valueItemIndex === valueIndex) {
                                  const checkDictionaryList =
                                    dictionaryList.filter((listItem) => {
                                      const currentListItem =
                                        listItem.toLocaleLowerCase();
                                      const currentValueText =
                                        valueText.toLocaleLowerCase();

                                      return (
                                        currentListItem === currentValueText
                                      );
                                    });

                                  return {
                                    text: checkDictionaryList[0],
                                    is_modified: true,
                                  };
                                }

                                return valueItem;
                              },
                            )
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text?.toLowerCase() ===
                                  valueItem?.text?.toLowerCase(),
                              );
                              return valueItemIndex === lastValueIndex;
                            }),
                  },
                };
              }

              return {
                ...item,
                attributionInfo: {
                  attributionId:
                    item?.attributionInfo?.attributionId ||
                    createRandomString(8),
                },
                [shellKey as RegulatoryDrugProfileShellKeysNotAttributionId]: {
                  ...item[
                    shellKey as RegulatoryDrugProfileShellKeysNotAttributionId
                  ],
                  attributionId:
                    item[
                      shellKey as RegulatoryDrugProfileShellKeysNotAttributionId
                    ]?.attributionId || createRandomString(8),
                  value:
                    item[
                      shellKey as RegulatoryDrugProfileShellKeysNotAttributionId
                    ] &&
                    item[
                      shellKey as RegulatoryDrugProfileShellKeysNotAttributionId
                    ]?.value.length > valueIndex
                      ? item[
                          shellKey as RegulatoryDrugProfileShellKeysNotAttributionId
                        ].value
                          ?.map((valueItem, valueItemIndex) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return { text: valueText, is_modified: true };
                            }

                            return valueItem;
                          })
                          .filter((valueItem) => valueItem !== null)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          })
                      : [
                          ...item[
                            shellKey as RegulatoryDrugProfileShellKeysNotAttributionId
                          ].value,
                          { text: valueText, is_modified: true },
                        ]
                          ?.map(
                            (
                              valueItem: {
                                text: string;
                                is_modified: boolean;
                              },
                              valueItemIndex: number,
                            ) => {
                              if (valueItemIndex === valueIndex) {
                                if (valueText === "") {
                                  return null;
                                }
                                return { text: valueText, is_modified: true };
                              }

                              return valueItem;
                            },
                          )
                          .filter((valueItem) => valueItem !== null)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          }),
                },
              };
            }
            return item;
          },
        ),
      },
    }),
    "Approval Process Contents": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) => {
      if (shellKey === "Additional Information on the Approval Process")
        return {
          ...data,
          Regulatory: {
            ...data.Regulatory,
            "Approval Process Contents": {
              ...data.Regulatory?.["Approval Process Contents"],
              "Additional Information on the Approval Process": {
                ...data.Regulatory?.["Approval Process Contents"]?.[
                  "Additional Information on the Approval Process"
                ],
                value:
                  [
                    ...(data.Regulatory?.["Approval Process Contents"]?.[
                      "Additional Information on the Approval Process"
                    ]?.value || []),
                  ].length > valueIndex
                    ? data.Regulatory?.["Approval Process Contents"]?.[
                        "Additional Information on the Approval Process"
                      ]?.value
                        .map(
                          (
                            valueItem: {
                              text: string;
                              is_modified: boolean;
                            },
                            valueItemIndex: number,
                          ) => {
                            if (
                              valueItemIndex === valueIndex &&
                              shellKey ===
                                "Additional Information on the Approval Process"
                            ) {
                              if (valueText === "") {
                                return null;
                              }
                              return { text: valueText, is_modified: true };
                            }
                            return valueItem;
                          },
                        )
                        .filter((valueItem) => valueItem !== null)
                        .filter((valueItem, valueItemIndex, valueList) => {
                          const lastValueIndex = valueList.findIndex(
                            (target) =>
                              target?.text.toLowerCase() ===
                              valueItem?.text.toLowerCase(),
                          );
                          return valueItemIndex === lastValueIndex;
                        })
                    : [
                        ...(data.Regulatory?.["Approval Process Contents"]?.[
                          "Additional Information on the Approval Process"
                        ]?.value || []),
                        { text: valueText, is_modified: true },
                      ]
                        .map(
                          (
                            valueItem: {
                              text: string;
                              is_modified: boolean;
                            },
                            valueItemIndex: number,
                          ) => {
                            if (
                              valueItemIndex === valueIndex &&
                              shellKey ===
                                "Additional Information on the Approval Process"
                            ) {
                              if (valueText === "") {
                                return null;
                              }
                              return { text: valueText, is_modified: true };
                            }
                            return valueItem;
                          },
                        )
                        .filter((valueItem) => valueItem !== null)
                        .filter((valueItem, valueItemIndex, valueList) => {
                          const lastValueIndex = valueList.findIndex(
                            (target) =>
                              target?.text.toLowerCase() ===
                              valueItem?.text.toLowerCase(),
                          );
                          return valueItemIndex === lastValueIndex;
                        }),
              },
            },
          },
        } as ArticleEntryData;

      return {
        ...data,
        Regulatory: {
          ...data.Regulatory,
          "Approval Process Contents": {
            ...data.Regulatory?.["Approval Process Contents"],
            "Approval Process": data?.Regulatory?.[
              "Approval Process Contents"
            ]?.["Approval Process"]?.map(
              (groupShellInfo, groupShellInfoIndex) => {
                if (groupShellInfoIndex === groupShellIndex) {
                  const currentData =
                    groupShellInfo[shellKey as ApprovalProcessTypeShellKey];

                  return {
                    ...groupShellInfo,
                    [shellKey]: {
                      ...currentData,
                      value:
                        [...(currentData?.value || [])].length > valueIndex
                          ? currentData?.value
                              .map(
                                (
                                  valueItem: {
                                    text: string;
                                    is_modified: boolean;
                                  },
                                  valueItemIndex: number,
                                ) => {
                                  if (valueItemIndex === valueIndex) {
                                    if (valueText === "") {
                                      return null;
                                    }
                                    return {
                                      text: valueText,
                                      is_modified: true,
                                    };
                                  }
                                  return valueItem;
                                },
                              )
                              .filter((valueItem) => valueItem !== null)
                              .filter(
                                (valueItem, valueItemIndex, valueList) => {
                                  const lastValueIndex = valueList.findIndex(
                                    (target) =>
                                      target?.text.toLowerCase() ===
                                      valueItem?.text.toLowerCase(),
                                  );
                                  return valueItemIndex === lastValueIndex;
                                },
                              )
                          : [
                              ...(currentData?.value || []),
                              { text: valueText, is_modified: true },
                            ]
                              .map(
                                (
                                  valueItem: {
                                    text: string;
                                    is_modified: boolean;
                                  },
                                  valueItemIndex: number,
                                ) => {
                                  if (valueItemIndex === valueIndex) {
                                    if (valueText === "") {
                                      return null;
                                    }
                                    return {
                                      text: valueText,
                                      is_modified: true,
                                    };
                                  }
                                  return valueItem;
                                },
                              )
                              .filter((valueItem) => valueItem !== null)
                              .filter(
                                (valueItem, valueItemIndex, valueList) => {
                                  const lastValueIndex = valueList.findIndex(
                                    (target) =>
                                      target?.text.toLowerCase() ===
                                      valueItem?.text.toLowerCase(),
                                  );
                                  return valueItemIndex === lastValueIndex;
                                },
                              ),
                    },
                  };
                }
                return groupShellInfo;
              },
            ),
          },
        },
      } as ArticleEntryData;
    },

    "Key Comment": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) =>
      ({
        ...data,
        Regulatory: {
          ...data.Regulatory,
          "Key Comment": {
            ...data.Regulatory?.["Key Comment"],
            [shellKey]: {
              ...data.Regulatory?.["Key Comment"]?.[
                shellKey as ClinicalTrialsKeyCommentShellKey
              ],

              value:
                [
                  ...(data.Regulatory?.["Key Comment"]?.[
                    shellKey as QuoteAndSpokesPersonType
                  ].value || []),
                ].length > valueIndex
                  ? data.Regulatory?.["Key Comment"]?.[
                      shellKey as QuoteAndSpokesPersonType
                    ].value
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                  : [
                      ...(data.Regulatory?.["Key Comment"]?.[
                        shellKey as QuoteAndSpokesPersonType
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null),
            },
          },
        },
      } as ArticleEntryData),
  },
  Deals: {
    "Drug Profile": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) => ({
      ...data,
      Deals: {
        ...data.Deals,
        "Drug Profile": data.Deals?.["Drug Profile"]?.map((item, index) => {
          if (index === tableIndex) {
            if (shellKey === "Therapeutic Area") {
              return {
                ...item,
                attributionInfo: {
                  attributionId:
                    item?.attributionInfo?.attributionId ||
                    createRandomString(8),
                },
                "Therapeutic Area": {
                  ...item["Therapeutic Area"],
                  attributionId:
                    item["Therapeutic Area"]?.attributionId ||
                    createRandomString(8),
                  value:
                    item["Therapeutic Area"] &&
                    item["Therapeutic Area"]?.value.length > valueIndex
                      ? item["Therapeutic Area"].value
                          ?.map((valueItem, valueItemIndex) => {
                            if (valueItemIndex === valueIndex) {
                              const checkDictionaryList = dictionaryList
                                .map((listItem) => {
                                  const currentListItem =
                                    listItem.toLocaleLowerCase();
                                  const currentValueText =
                                    valueText.toLocaleLowerCase();

                                  if (currentListItem === currentValueText)
                                    return listItem;

                                  return null;
                                })
                                .filter(
                                  (listValueItem) => listValueItem !== null,
                                );

                              return {
                                text: checkDictionaryList[0] || "",
                                is_modified: true,
                              };
                            }

                            return valueItem;
                          })
                          .filter((valueItem) => !!valueItem.text)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );

                            return valueItemIndex === lastValueIndex;
                          })
                      : [
                          ...item["Therapeutic Area"].value,
                          { text: valueText, is_modified: true },
                        ]
                          ?.map(
                            (
                              valueItem: {
                                text: string;
                                is_modified: boolean;
                              },
                              valueItemIndex: number,
                            ) => {
                              if (valueItemIndex === valueIndex) {
                                const checkDictionaryList =
                                  dictionaryList.filter((listItem) => {
                                    const currentListItem =
                                      listItem.toLocaleLowerCase();
                                    const currentValueText =
                                      valueText.toLocaleLowerCase();

                                    return currentListItem === currentValueText;
                                  });

                                return {
                                  text: checkDictionaryList[0],
                                  is_modified: true,
                                };
                              }

                              return valueItem;
                            },
                          )
                          .filter((valueItem) => !!valueItem.text)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text?.toLowerCase() ===
                                valueItem?.text?.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          }),
                },
              };
            }

            return {
              ...item,
              attributionInfo: {
                attributionId:
                  item?.attributionInfo?.attributionId || createRandomString(8),
              },
              [shellKey as DrugProfileShellKeysNotIncludeAttributionId]: {
                ...item[
                  shellKey as DrugProfileShellKeysNotIncludeAttributionId
                ],
                attributionId:
                  item[shellKey as DrugProfileShellKeysNotIncludeAttributionId]
                    ?.attributionId || createRandomString(8),
                value:
                  item[
                    shellKey as DrugProfileShellKeysNotIncludeAttributionId
                  ] &&
                  item[shellKey as DrugProfileShellKeysNotIncludeAttributionId]
                    ?.value.length > valueIndex
                    ? item[
                        shellKey as DrugProfileShellKeysNotIncludeAttributionId
                      ].value
                        ?.map((valueItem, valueItemIndex) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }

                          return valueItem;
                        })
                        .filter((valueItem) => valueItem !== null)
                        .filter((valueItem, valueItemIndex, valueList) => {
                          const lastValueIndex = valueList.findIndex(
                            (target) =>
                              target?.text.toLowerCase() ===
                              valueItem?.text.toLowerCase(),
                          );
                          return valueItemIndex === lastValueIndex;
                        })
                    : [
                        ...item[
                          shellKey as DrugProfileShellKeysNotIncludeAttributionId
                        ].value,
                        { text: valueText, is_modified: true },
                      ]
                        ?.map(
                          (
                            valueItem: {
                              text: string;
                              is_modified: boolean;
                            },
                            valueItemIndex: number,
                          ) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return { text: valueText, is_modified: true };
                            }

                            return valueItem;
                          },
                        )
                        .filter((valueItem) => valueItem !== null)
                        .filter((valueItem, valueItemIndex, valueList) => {
                          const lastValueIndex = valueList.findIndex(
                            (target) =>
                              target?.text.toLowerCase() ===
                              valueItem?.text.toLowerCase(),
                          );
                          return valueItemIndex === lastValueIndex;
                        }),
              },
            };
          }
          return item;
        }),
      },
    }),
    "Deal Type & Status": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) => {
      if (shellKey === ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealType) {
        const dealTypeAndStatusData =
          data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
            ENTRY_DATA_DEAL_TYPE_AND_STATUS
          ];

        const dealTypeShellData =
          data?.[ENTRY_DATA_SECTION_KEYS.deals]?.[
            ENTRY_DATA_DEAL_TYPE_AND_STATUS
          ]?.[ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealType];

        return {
          ...data,
          [ENTRY_DATA_SECTION_KEYS.deals]: {
            ...data[ENTRY_DATA_SECTION_KEYS.deals],
            [ENTRY_DATA_DEAL_TYPE_AND_STATUS]: {
              ...dealTypeAndStatusData,
              [ENTRY_DATA_DEAL_TYPE_AND_STATUS_KEYS.dealType]: {
                ...dealTypeShellData,
                value:
                  [...(dealTypeShellData?.value || [])].length > valueIndex
                    ? dealTypeShellData?.value
                        .map(
                          (
                            valueItem: {
                              text: string;
                              is_modified: boolean;
                            },
                            valueItemIndex: number,
                          ) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return {
                                text: valueText.toLocaleLowerCase(),
                                is_modified: true,
                              };
                            }
                            return valueItem;
                          },
                        )
                        .filter((valueItem) => valueItem !== null)
                        .filter((valueItem, valueItemIndex, valueList) => {
                          const lastValueIndex = valueList.findIndex(
                            (target) =>
                              target?.text.toLowerCase() ===
                              valueItem?.text.toLowerCase(),
                          );
                          return valueItemIndex === lastValueIndex;
                        })
                    : [
                        ...(dealTypeShellData?.value || []),
                        { text: valueText, is_modified: true },
                      ]
                        .map(
                          (
                            valueItem: {
                              text: string;
                              is_modified: boolean;
                            },
                            valueItemIndex: number,
                          ) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return {
                                text: valueText.toLocaleLowerCase(),
                                is_modified: true,
                              };
                            }
                            return valueItem;
                          },
                        )
                        .filter((valueItem) => valueItem !== null)
                        .filter((valueItem, valueItemIndex, valueList) => {
                          const lastValueIndex = valueList.findIndex(
                            (target) =>
                              target?.text.toLowerCase() ===
                              valueItem?.text.toLowerCase(),
                          );
                          return valueItemIndex === lastValueIndex;
                        }),
              },
            },
          },
        } as ArticleEntryData;
      }

      return {
        ...data,
        Deals: {
          ...data.Deals,
          "Deal Type & Status": {
            ...data.Deals?.["Deal Type & Status"],
            [shellKey]: {
              ...data.Deals?.["Deal Type & Status"]?.[
                shellKey as DealTypeAndStatusKey
              ],
              value:
                [
                  ...(data.Deals?.["Deal Type & Status"]?.[
                    shellKey as DealTypeAndStatusKey
                  ]?.value || []),
                ].length > valueIndex
                  ? data.Deals?.["Deal Type & Status"]?.[
                      shellKey as DealTypeAndStatusKey
                    ]?.value
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return {
                              text: valueText,
                              is_modified: true,
                            };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      })
                  : [
                      ...(data.Deals?.["Deal Type & Status"]?.[
                        shellKey as DealTypeAndStatusKey
                      ]?.value || []),
                      { text: valueText, is_modified: true },
                    ]
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return {
                              text: valueText,
                              is_modified: true,
                            };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      }),
            },
          },
        },
      } as ArticleEntryData;
    },

    "Licensee Table": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) => {
      const licensorShell =
        shellKey === "Licensor Name" ||
        shellKey === "Licensor Responsibility & Rights";

      const additionalInformationShell =
        shellKey === "Additional Information" ||
        shellKey === "Purpose of the Deal";

      if (licensorShell) {
        const currentLicensorData = data.Deals?.["Licensor Table"]?.[shellKey];

        return {
          ...data,
          Deals: {
            ...data.Deals,
            "Licensor Table": {
              ...data.Deals?.["Licensor Table"],
              [shellKey]: {
                ...currentLicensorData,
                value:
                  [...(currentLicensorData?.value || [])].length > valueIndex
                    ? currentLicensorData?.value
                        .map(
                          (
                            valueItem: {
                              text: string;
                              is_modified: boolean;
                            },
                            valueItemIndex: number,
                          ) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return {
                                text: valueText,
                                is_modified: true,
                              };
                            }
                            return valueItem;
                          },
                        )
                        .filter((valueItem) => valueItem !== null)
                        .filter((valueItem, valueItemIndex, valueList) => {
                          const lastValueIndex = valueList.findIndex(
                            (target) =>
                              target?.text.toLowerCase() ===
                              valueItem?.text.toLowerCase(),
                          );
                          return valueItemIndex === lastValueIndex;
                        })
                    : [
                        ...(currentLicensorData?.value || []),
                        { text: valueText, is_modified: true },
                      ]
                        .map(
                          (
                            valueItem: {
                              text: string;
                              is_modified: boolean;
                            },
                            valueItemIndex: number,
                          ) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return {
                                text: valueText,
                                is_modified: true,
                              };
                            }
                            return valueItem;
                          },
                        )
                        .filter((valueItem) => valueItem !== null)
                        .filter((valueItem, valueItemIndex, valueList) => {
                          const lastValueIndex = valueList.findIndex(
                            (target) =>
                              target?.text.toLowerCase() ===
                              valueItem?.text.toLowerCase(),
                          );
                          return valueItemIndex === lastValueIndex;
                        }),
              },
            },
          },
        } as ArticleEntryData;
      }

      if (additionalInformationShell) {
        return {
          ...data,
          Deals: {
            ...data.Deals,
            "Additional Information (Deal)": {
              ...data.Deals?.["Additional Information (Deal)"],
              [shellKey]: {
                ...data.Deals?.["Additional Information (Deal)"]?.[shellKey],
                value:
                  [
                    ...(data.Deals?.["Additional Information (Deal)"]?.[
                      shellKey
                    ]?.value || []),
                  ].length > valueIndex
                    ? data.Deals?.["Additional Information (Deal)"]?.[
                        shellKey
                      ]?.value
                        .map(
                          (
                            valueItem: {
                              text: string;
                              is_modified: boolean;
                            },
                            valueItemIndex: number,
                          ) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return {
                                text: valueText,
                                is_modified: true,
                              };
                            }
                            return valueItem;
                          },
                        )
                        .filter((valueItem) => valueItem !== null)
                        .filter((valueItem, valueItemIndex, valueList) => {
                          const lastValueIndex = valueList.findIndex(
                            (target) =>
                              target?.text.toLowerCase() ===
                              valueItem?.text.toLowerCase(),
                          );
                          return valueItemIndex === lastValueIndex;
                        })
                    : [
                        ...(data.Deals?.["Additional Information (Deal)"]?.[
                          shellKey
                        ]?.value || []),
                        { text: valueText, is_modified: true },
                      ]
                        .map(
                          (
                            valueItem: {
                              text: string;
                              is_modified: boolean;
                            },
                            valueItemIndex: number,
                          ) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return {
                                text: valueText,
                                is_modified: true,
                              };
                            }
                            return valueItem;
                          },
                        )
                        .filter((valueItem) => valueItem !== null)
                        .filter((valueItem, valueItemIndex, valueList) => {
                          const lastValueIndex = valueList.findIndex(
                            (target) =>
                              target?.text.toLowerCase() ===
                              valueItem?.text.toLowerCase(),
                          );
                          return valueItemIndex === lastValueIndex;
                        }),
              },
            },
          },
        } as ArticleEntryData;
      }

      return {
        ...data,
        Deals: {
          ...data.Deals,
          "Licensee Table": data.Deals?.["Licensee Table"]?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === tableIndex) {
                const scAndLcShell =
                  shellKey ===
                    ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.upfrontPayment ||
                  shellKey ===
                    ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.totalPaymentScLa ||
                  shellKey ===
                    ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.royalty ||
                  shellKey ===
                    ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestonePayment ||
                  shellKey ===
                    ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestoneConditionTitle ||
                  shellKey ===
                    ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherFinancialTermsScLa ||
                  shellKey ===
                    ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.licenseeResponsibilityAndRightsScLa ||
                  shellKey ===
                    ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa ||
                  shellKey ===
                    ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa ||
                  shellKey ===
                    ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa;

                const isRightsScopeShell =
                  shellKey === ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug ||
                  shellKey === ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication ||
                  shellKey === ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity ||
                  shellKey === ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory;

                const isMergeAcquisitionShell =
                  shellKey ===
                    MERGE_ACQUISITION_SHELL_KEYS.licenseeResponsibilityAndRightsMa ||
                  shellKey ===
                    MERGE_ACQUISITION_SHELL_KEYS.numberOfAcquiringShares ||
                  shellKey === MERGE_ACQUISITION_SHELL_KEYS.pricePerShare ||
                  shellKey === MERGE_ACQUISITION_SHELL_KEYS.totalPaymentMa ||
                  shellKey === MERGE_ACQUISITION_SHELL_KEYS.upfrontPaymentMa ||
                  shellKey ===
                    MERGE_ACQUISITION_SHELL_KEYS.upfrontPricePerShareMa ||
                  shellKey ===
                    MERGE_ACQUISITION_SHELL_KEYS.contingentPaymentMa ||
                  shellKey ===
                    MERGE_ACQUISITION_SHELL_KEYS.contingentPricePerShareMa ||
                  shellKey ===
                    MERGE_ACQUISITION_SHELL_KEYS.contingentMilestoneMa ||
                  shellKey ===
                    MERGE_ACQUISITION_SHELL_KEYS.mergerFinancialTermsMa ||
                  shellKey ===
                    MERGE_ACQUISITION_SHELL_KEYS.otherFinancialTermsMa ||
                  shellKey ===
                    MERGE_ACQUISITION_SHELL_KEYS.disclosedPaymentsSumMa ||
                  shellKey ===
                    MERGE_ACQUISITION_SHELL_KEYS.disclosedPaymentsSumPricePerShareMa;

                if (shellKey === "Licensee Name") {
                  const currentLicenseeName = tableInfo?.["Licensee Name"];

                  return {
                    ...tableInfo,
                    [shellKey]: {
                      ...currentLicenseeName,
                      value:
                        [...(currentLicenseeName?.value || [])].length >
                        valueIndex
                          ? currentLicenseeName?.value
                              .map(
                                (
                                  valueItem: {
                                    text: string;
                                    is_modified: boolean;
                                  },
                                  valueItemIndex: number,
                                ) => {
                                  if (valueItemIndex === valueIndex) {
                                    if (valueText === "") {
                                      return null;
                                    }
                                    return {
                                      text: valueText,
                                      is_modified: true,
                                    };
                                  }
                                  return valueItem;
                                },
                              )
                              .filter((valueItem) => valueItem !== null)
                              .filter(
                                (valueItem, valueItemIndex, valueList) => {
                                  const lastValueIndex = valueList.findIndex(
                                    (target) =>
                                      target?.text.toLowerCase() ===
                                      valueItem?.text.toLowerCase(),
                                  );
                                  return valueItemIndex === lastValueIndex;
                                },
                              )
                          : [
                              ...(currentLicenseeName?.value || []),
                              { text: valueText, is_modified: true },
                            ]
                              .map(
                                (
                                  valueItem: {
                                    text: string;
                                    is_modified: boolean;
                                  },
                                  valueItemIndex: number,
                                ) => {
                                  if (valueItemIndex === valueIndex) {
                                    if (valueText === "") {
                                      return null;
                                    }
                                    return {
                                      text: valueText,
                                      is_modified: true,
                                    };
                                  }
                                  return valueItem;
                                },
                              )
                              .filter((valueItem) => valueItem !== null)
                              .filter(
                                (valueItem, valueItemIndex, valueList) => {
                                  const lastValueIndex = valueList.findIndex(
                                    (target) =>
                                      target?.text.toLowerCase() ===
                                      valueItem?.text.toLowerCase(),
                                  );
                                  return valueItemIndex === lastValueIndex;
                                },
                              ),
                    },
                  };
                }

                if (isRightsScopeShell) {
                  return {
                    ...tableInfo,
                    "Rights Scope": tableInfo["Rights Scope"]?.map(
                      (groupShellInfo, groupShellInfoIndex) => {
                        if (groupShellInfoIndex === groupShellIndex) {
                          const currentRightsScopeShell =
                            groupShellInfo?.[shellKey];

                          return {
                            ...groupShellInfo,
                            [shellKey]: {
                              ...currentRightsScopeShell,
                              value:
                                [...(currentRightsScopeShell?.value || [])]
                                  .length > valueIndex
                                  ? currentRightsScopeShell?.value
                                      .map(
                                        (
                                          valueItem: {
                                            text: string;
                                            is_modified: boolean;
                                          },
                                          valueItemIndex: number,
                                        ) => {
                                          if (valueItemIndex === valueIndex) {
                                            if (valueText === "") {
                                              return null;
                                            }
                                            return {
                                              text: valueText,
                                              is_modified: true,
                                            };
                                          }
                                          return valueItem;
                                        },
                                      )
                                      .filter((valueItem) => valueItem !== null)
                                      .filter(
                                        (
                                          valueItem,
                                          valueItemIndex,
                                          valueList,
                                        ) => {
                                          const lastValueIndex =
                                            valueList.findIndex(
                                              (target) =>
                                                target?.text.toLowerCase() ===
                                                valueItem?.text.toLowerCase(),
                                            );
                                          return (
                                            valueItemIndex === lastValueIndex
                                          );
                                        },
                                      )
                                  : [
                                      ...(currentRightsScopeShell?.value || []),
                                      { text: valueText, is_modified: true },
                                    ]
                                      .map(
                                        (
                                          valueItem: {
                                            text: string;
                                            is_modified: boolean;
                                          },
                                          valueItemIndex: number,
                                        ) => {
                                          if (valueItemIndex === valueIndex) {
                                            if (valueText === "") {
                                              return null;
                                            }
                                            return {
                                              text: valueText,
                                              is_modified: true,
                                            };
                                          }
                                          return valueItem;
                                        },
                                      )
                                      .filter((valueItem) => valueItem !== null)
                                      .filter(
                                        (
                                          valueItem,
                                          valueItemIndex,
                                          valueList,
                                        ) => {
                                          const lastValueIndex =
                                            valueList.findIndex(
                                              (target) =>
                                                target?.text.toLowerCase() ===
                                                valueItem?.text.toLowerCase(),
                                            );
                                          return (
                                            valueItemIndex === lastValueIndex
                                          );
                                        },
                                      ),
                            },
                          };
                        }

                        return groupShellInfo;
                      },
                    ),
                  };
                }

                if (isMergeAcquisitionShell) {
                  const currentMerge =
                    tableInfo?.[
                      ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                    ];

                  const isContingentPaymentShellKeyList =
                    shellKey ===
                      MERGE_ACQUISITION_SHELL_KEYS.contingentPaymentMa ||
                    shellKey ===
                      MERGE_ACQUISITION_SHELL_KEYS.contingentPricePerShareMa ||
                    shellKey ===
                      MERGE_ACQUISITION_SHELL_KEYS.contingentMilestoneMa;

                  if (isContingentPaymentShellKeyList)
                    return {
                      ...tableInfo,
                      [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                        ...currentMerge,
                        [ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS.contingentPayment]:
                          tableInfo[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms
                          ][
                            ENTRY_DATA_MERGE_ACQUISITION_TERMS_KEYS
                              .contingentPayment
                          ]?.map(
                            (subGroupShellInfo, subGroupShellInfoIndex) => {
                              if (
                                subGroupShellInfoIndex === subGroupShellIndex
                              ) {
                                const currentContingentPaymentShell =
                                  subGroupShellInfo?.[shellKey];

                                return {
                                  ...subGroupShellInfo,
                                  [shellKey]: {
                                    ...currentContingentPaymentShell,
                                    value:
                                      [
                                        ...(currentContingentPaymentShell?.value ||
                                          []),
                                      ].length > valueIndex
                                        ? currentContingentPaymentShell?.value
                                            .map(
                                              (
                                                valueItem: {
                                                  text: string;
                                                  is_modified: boolean;
                                                },
                                                valueItemIndex: number,
                                              ) => {
                                                if (
                                                  valueItemIndex === valueIndex
                                                ) {
                                                  if (valueText === "") {
                                                    return null;
                                                  }
                                                  return {
                                                    text: valueText,
                                                    is_modified: true,
                                                  };
                                                }
                                                return valueItem;
                                              },
                                            )
                                            .filter(
                                              (valueItem) => valueItem !== null,
                                            )
                                            .filter(
                                              (
                                                valueItem,
                                                valueItemIndex,
                                                valueList,
                                              ) => {
                                                const lastValueIndex =
                                                  valueList.findIndex(
                                                    (target) =>
                                                      target?.text.toLowerCase() ===
                                                      valueItem?.text.toLowerCase(),
                                                  );
                                                return (
                                                  valueItemIndex ===
                                                  lastValueIndex
                                                );
                                              },
                                            )
                                        : [
                                            ...(currentContingentPaymentShell?.value ||
                                              []),
                                            {
                                              text: valueText,
                                              is_modified: true,
                                            },
                                          ]
                                            .map(
                                              (
                                                valueItem: {
                                                  text: string;
                                                  is_modified: boolean;
                                                },
                                                valueItemIndex: number,
                                              ) => {
                                                if (
                                                  valueItemIndex === valueIndex
                                                ) {
                                                  if (valueText === "") {
                                                    return null;
                                                  }
                                                  return {
                                                    text: valueText,
                                                    is_modified: true,
                                                  };
                                                }
                                                return valueItem;
                                              },
                                            )
                                            .filter(
                                              (valueItem) => valueItem !== null,
                                            )
                                            .filter(
                                              (
                                                valueItem,
                                                valueItemIndex,
                                                valueList,
                                              ) => {
                                                const lastValueIndex =
                                                  valueList.findIndex(
                                                    (target) =>
                                                      target?.text.toLowerCase() ===
                                                      valueItem?.text.toLowerCase(),
                                                  );
                                                return (
                                                  valueItemIndex ===
                                                  lastValueIndex
                                                );
                                              },
                                            ),
                                  },
                                };
                              }

                              return subGroupShellInfo;
                            },
                          ),
                      },
                    };

                  return {
                    ...tableInfo,
                    [ENTRY_DATA_LICENSEE_TABLE_KEYS.mergeAcquisitionTerms]: {
                      ...currentMerge,
                      [shellKey]: {
                        ...currentMerge?.[shellKey],
                        value:
                          [...(currentMerge?.[shellKey]?.value || [])].length >
                          valueIndex
                            ? currentMerge?.[shellKey]?.value
                                .map(
                                  (
                                    valueItem: {
                                      text: string;
                                      is_modified: boolean;
                                    },
                                    valueItemIndex: number,
                                  ) => {
                                    if (valueItemIndex === valueIndex) {
                                      if (valueText === "") {
                                        return null;
                                      }
                                      return {
                                        text: valueText,
                                        is_modified: true,
                                      };
                                    }
                                    return valueItem;
                                  },
                                )
                                .filter((valueItem) => valueItem !== null)
                                .filter(
                                  (valueItem, valueItemIndex, valueList) => {
                                    const lastValueIndex = valueList.findIndex(
                                      (target) =>
                                        target?.text.toLowerCase() ===
                                        valueItem?.text.toLowerCase(),
                                    );
                                    return valueItemIndex === lastValueIndex;
                                  },
                                )
                            : [
                                ...(currentMerge?.[shellKey]?.value || []),
                                { text: valueText, is_modified: true },
                              ]
                                .map(
                                  (
                                    valueItem: {
                                      text: string;
                                      is_modified: boolean;
                                    },
                                    valueItemIndex: number,
                                  ) => {
                                    if (valueItemIndex === valueIndex) {
                                      if (valueText === "") {
                                        return null;
                                      }
                                      return {
                                        text: valueText,
                                        is_modified: true,
                                      };
                                    }
                                    return valueItem;
                                  },
                                )
                                .filter((valueItem) => valueItem !== null)
                                .filter(
                                  (valueItem, valueItemIndex, valueList) => {
                                    const lastValueIndex = valueList.findIndex(
                                      (target) =>
                                        target?.text.toLowerCase() ===
                                        valueItem?.text.toLowerCase(),
                                    );
                                    return valueItemIndex === lastValueIndex;
                                  },
                                ),
                      },
                    },
                  };
                }

                if (scAndLcShell) {
                  const currentScAndLa =
                    tableInfo[
                      "Strategic Collaboration & License Agreement terms"
                    ];

                  const isMilestoneShell =
                    shellKey ===
                      ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestonePayment ||
                    shellKey ===
                      ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestoneConditionTitle;

                  const isOtherPaymentAndDetailShell =
                    shellKey ===
                      ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa ||
                    shellKey ===
                      ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa;

                  // NOTE : 대문자로 변경

                  if (isMilestoneShell) {
                    return {
                      ...tableInfo,
                      "Strategic Collaboration & License Agreement terms": {
                        ...currentScAndLa,
                        "Milestone Payment and Condition": currentScAndLa[
                          "Milestone Payment and Condition"
                        ]?.map((subGroupShellInfo, subGroupShellInfoIndex) => {
                          if (subGroupShellInfoIndex === subGroupShellIndex) {
                            const currentMilestone =
                              subGroupShellInfo?.[shellKey];

                            return {
                              ...subGroupShellInfo,
                              [shellKey]: {
                                ...currentMilestone,
                                value:
                                  valueText !== ""
                                    ? [
                                        {
                                          text: valueText,
                                          is_modified: true,
                                        },
                                      ]
                                    : [],
                              },
                            };
                          }
                          return subGroupShellInfo;
                        }),
                      },
                    };
                  }

                  if (isOtherPaymentAndDetailShell) {
                    return {
                      ...tableInfo,
                      [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                        {
                          ...currentScAndLa,
                          [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                            currentScAndLa[
                              ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                                .otherPaymentAndDetailScLa
                            ]?.map(
                              (subGroupShellInfo, subGroupShellInfoIndex) => {
                                if (
                                  subGroupShellInfoIndex === subGroupShellIndex
                                ) {
                                  const currentOtherPaymentShell =
                                    subGroupShellInfo?.[shellKey];

                                  return {
                                    ...subGroupShellInfo,
                                    [shellKey]: {
                                      ...currentOtherPaymentShell,
                                      value:
                                        valueText !== ""
                                          ? [
                                              {
                                                text: valueText,
                                                is_modified: true,
                                              },
                                            ]
                                          : [],
                                    },
                                  };
                                }
                                return subGroupShellInfo;
                              },
                            ),
                        },
                    };
                  }

                  return {
                    ...tableInfo,
                    "Strategic Collaboration & License Agreement terms": {
                      ...currentScAndLa,
                      [shellKey]: {
                        ...currentScAndLa?.[shellKey],
                        value:
                          [...(currentScAndLa?.[shellKey]?.value || [])]
                            .length > valueIndex
                            ? currentScAndLa?.[shellKey]?.value
                                .map(
                                  (
                                    valueItem: {
                                      text: string;
                                      is_modified: boolean;
                                    },
                                    valueItemIndex: number,
                                  ) => {
                                    if (valueItemIndex === valueIndex) {
                                      if (valueText === "") {
                                        return null;
                                      }
                                      return {
                                        text: valueText,
                                        is_modified: true,
                                      };
                                    }
                                    return valueItem;
                                  },
                                )
                                .filter((valueItem) => valueItem !== null)
                                .filter(
                                  (valueItem, valueItemIndex, valueList) => {
                                    const lastValueIndex = valueList.findIndex(
                                      (target) =>
                                        target?.text.toLowerCase() ===
                                        valueItem?.text.toLowerCase(),
                                    );
                                    return valueItemIndex === lastValueIndex;
                                  },
                                )
                            : [
                                ...(currentScAndLa?.[shellKey]?.value || []),
                                { text: valueText, is_modified: true },
                              ]
                                .map(
                                  (
                                    valueItem: {
                                      text: string;
                                      is_modified: boolean;
                                    },
                                    valueItemIndex: number,
                                  ) => {
                                    if (valueItemIndex === valueIndex) {
                                      if (valueText === "") {
                                        return null;
                                      }
                                      return {
                                        text: valueText,
                                        is_modified: true,
                                      };
                                    }
                                    return valueItem;
                                  },
                                )
                                .filter((valueItem) => valueItem !== null)
                                .filter(
                                  (valueItem, valueItemIndex, valueList) => {
                                    const lastValueIndex = valueList.findIndex(
                                      (target) =>
                                        target?.text.toLowerCase() ===
                                        valueItem?.text.toLowerCase(),
                                    );
                                    return valueItemIndex === lastValueIndex;
                                  },
                                ),
                      },
                    },
                  };
                }
              }

              return tableInfo;
            },
          ),
        },
      } as ArticleEntryData;
    },
    "Key Comment": (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) =>
      ({
        ...data,
        Deals: {
          ...data.Deals,
          "Key Comment": {
            ...data.Deals?.["Key Comment"],
            [shellKey]: {
              ...data.Deals?.["Key Comment"]?.[
                shellKey as ClinicalTrialsKeyCommentShellKey
              ],

              value:
                [
                  ...(data.Deals?.["Key Comment"]?.[
                    shellKey as QuoteAndSpokesPersonType
                  ].value || []),
                ].length > valueIndex
                  ? data.Deals?.["Key Comment"]?.[
                      shellKey as QuoteAndSpokesPersonType
                    ].value
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                  : [
                      ...(data.Deals?.["Key Comment"]?.[
                        shellKey as QuoteAndSpokesPersonType
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null),
            },
          },
        },
      } as ArticleEntryData),
  },
  [EXPEDITED_PROGRAM]: {
    [eg.drugProfile]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) => ({
      ...data,
      [EXPEDITED_PROGRAM]: {
        ...data[EXPEDITED_PROGRAM],
        [eg.drugProfile]: data[EXPEDITED_PROGRAM]?.[eg.drugProfile]?.map(
          (item, index) => {
            if (index === tableIndex) {
              if (shellKey === "Therapeutic Area") {
                return {
                  ...item,
                  attributionInfo: {
                    attributionId:
                      item?.attributionInfo?.attributionId ||
                      createRandomString(8),
                  },
                  "Therapeutic Area": {
                    ...item["Therapeutic Area"],
                    attributionId:
                      item["Therapeutic Area"]?.attributionId ||
                      createRandomString(8),
                    value:
                      item["Therapeutic Area"] &&
                      item["Therapeutic Area"]?.value.length > valueIndex
                        ? item["Therapeutic Area"].value
                            ?.map((valueItem, valueItemIndex) => {
                              if (valueItemIndex === valueIndex) {
                                const checkDictionaryList = dictionaryList
                                  .map((listItem) => {
                                    const currentListItem =
                                      listItem.toLocaleLowerCase();
                                    const currentValueText =
                                      valueText.toLocaleLowerCase();

                                    if (currentListItem === currentValueText)
                                      return listItem;

                                    return null;
                                  })
                                  .filter(
                                    (listValueItem) => listValueItem !== null,
                                  );

                                return {
                                  text: checkDictionaryList[0] || "",
                                  is_modified: true,
                                };
                              }

                              return valueItem;
                            })
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text.toLowerCase() ===
                                  valueItem?.text.toLowerCase(),
                              );

                              return valueItemIndex === lastValueIndex;
                            })
                        : [
                            ...item["Therapeutic Area"].value,
                            { text: valueText, is_modified: true },
                          ]
                            ?.map(
                              (
                                valueItem: {
                                  text: string;
                                  is_modified: boolean;
                                },
                                valueItemIndex: number,
                              ) => {
                                if (valueItemIndex === valueIndex) {
                                  const checkDictionaryList =
                                    dictionaryList.filter((listItem) => {
                                      const currentListItem =
                                        listItem.toLocaleLowerCase();
                                      const currentValueText =
                                        valueText.toLocaleLowerCase();

                                      return (
                                        currentListItem === currentValueText
                                      );
                                    });

                                  return {
                                    text: checkDictionaryList[0],
                                    is_modified: true,
                                  };
                                }

                                return valueItem;
                              },
                            )
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text?.toLowerCase() ===
                                  valueItem?.text?.toLowerCase(),
                              );
                              return valueItemIndex === lastValueIndex;
                            }),
                  },
                };
              }

              return {
                ...item,
                attributionInfo: {
                  attributionId:
                    item?.attributionInfo?.attributionId ||
                    createRandomString(8),
                },
                [shellKey as ExpeditedProgramDrugProfileShellKeysNotAttributionId]:
                  {
                    ...item[
                      shellKey as ExpeditedProgramDrugProfileShellKeysNotAttributionId
                    ],
                    attributionId:
                      item[
                        shellKey as ExpeditedProgramDrugProfileShellKeysNotAttributionId
                      ]?.attributionId || createRandomString(8),
                    value:
                      item[
                        shellKey as ExpeditedProgramDrugProfileShellKeysNotAttributionId
                      ] &&
                      item[
                        shellKey as ExpeditedProgramDrugProfileShellKeysNotAttributionId
                      ]?.value.length > valueIndex
                        ? item[
                            shellKey as ExpeditedProgramDrugProfileShellKeysNotAttributionId
                          ].value
                            ?.map((valueItem, valueItemIndex) => {
                              if (valueItemIndex === valueIndex) {
                                if (valueText === "") {
                                  return null;
                                }
                                return { text: valueText, is_modified: true };
                              }

                              return valueItem;
                            })
                            .filter((valueItem) => valueItem !== null)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text.toLowerCase() ===
                                  valueItem?.text.toLowerCase(),
                              );
                              return valueItemIndex === lastValueIndex;
                            })
                        : [
                            ...item[
                              shellKey as ExpeditedProgramDrugProfileShellKeysNotAttributionId
                            ].value,
                            { text: valueText, is_modified: true },
                          ]
                            ?.map(
                              (
                                valueItem: {
                                  text: string;
                                  is_modified: boolean;
                                },
                                valueItemIndex: number,
                              ) => {
                                if (valueItemIndex === valueIndex) {
                                  if (valueText === "") {
                                    return null;
                                  }
                                  return { text: valueText, is_modified: true };
                                }

                                return valueItem;
                              },
                            )
                            .filter((valueItem) => valueItem !== null)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text.toLowerCase() ===
                                  valueItem?.text.toLowerCase(),
                              );
                              return valueItemIndex === lastValueIndex;
                            }),
                  },
              };
            }
            return item;
          },
        ),
      },
    }),
    [eg.expeditedProgramContents]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) => {
      const currentData =
        data[EXPEDITED_PROGRAM]?.[eg.expeditedProgramContents];

      return {
        ...data,
        [EXPEDITED_PROGRAM]: {
          ...data[EXPEDITED_PROGRAM],
          [eg.expeditedProgramContents]: {
            ...currentData,
            [shellKey as ExpeditedProgramContentsGroupShellKey]: {
              ...currentData?.[
                shellKey as ExpeditedProgramContentsGroupShellKey
              ],
              attributionId:
                currentData?.[shellKey as ExpeditedProgramContentsGroupShellKey]
                  ?.attributionId || createRandomString(8),
              value:
                currentData?.[
                  shellKey as ExpeditedProgramContentsGroupShellKey
                ] &&
                currentData?.[shellKey as ExpeditedProgramContentsGroupShellKey]
                  ?.value.length > valueIndex
                  ? currentData?.[
                      shellKey as ExpeditedProgramContentsGroupShellKey
                    ].value
                      ?.map((valueItem, valueItemIndex) => {
                        if (valueItemIndex === valueIndex) {
                          if (valueText === "") {
                            return null;
                          }
                          return { text: valueText, is_modified: true };
                        }

                        return valueItem;
                      })
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      })
                  : [
                      ...(currentData?.[
                        shellKey as ExpeditedProgramContentsGroupShellKey
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      ?.map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }

                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      }),
            },
          },
        },
      } as ArticleEntryData;
    },

    [eg.keyComment]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) =>
      ({
        ...data,
        [EXPEDITED_PROGRAM]: {
          ...data[EXPEDITED_PROGRAM],
          [eg.keyComment]: {
            ...data[EXPEDITED_PROGRAM]?.[eg.keyComment],
            [shellKey]: {
              ...data[EXPEDITED_PROGRAM]?.[eg.keyComment]?.[
                shellKey as ExpeditedProgramKeyCommentShellKeys
              ],

              value:
                [
                  ...(data[EXPEDITED_PROGRAM]?.[eg.keyComment]?.[
                    shellKey as QuoteAndSpokesPersonType
                  ].value || []),
                ].length > valueIndex
                  ? data[EXPEDITED_PROGRAM]?.[eg.keyComment]?.[
                      shellKey as QuoteAndSpokesPersonType
                    ].value
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                  : [
                      ...(data[EXPEDITED_PROGRAM]?.[eg.keyComment]?.[
                        shellKey as QuoteAndSpokesPersonType
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null),
            },
          },
        },
      } as ArticleEntryData),
  },
  [SECTION_KEYS.ventureCapital]: {
    [DRUG_PROFILE]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
      dictionaryList: string[],
    ) => ({
      ...data,
      [SECTION_KEYS.ventureCapital]: {
        ...data[SECTION_KEYS.ventureCapital],
        [DRUG_PROFILE]: data[SECTION_KEYS.ventureCapital]?.[DRUG_PROFILE]?.map(
          (item, index) => {
            if (index === tableIndex) {
              if (shellKey === DRUG_PROFILE_SHELL_KEYS.therapeuticArea) {
                return {
                  ...item,
                  attributionInfo: {
                    attributionId:
                      item?.attributionInfo?.attributionId ||
                      createAttributionId(),
                  },
                  [DRUG_PROFILE_SHELL_KEYS.therapeuticArea]: {
                    ...item[DRUG_PROFILE_SHELL_KEYS.therapeuticArea],
                    attributionId:
                      item[DRUG_PROFILE_SHELL_KEYS.therapeuticArea]
                        ?.attributionId || createAttributionId(),
                    value:
                      item[DRUG_PROFILE_SHELL_KEYS.therapeuticArea] &&
                      item[DRUG_PROFILE_SHELL_KEYS.therapeuticArea]?.value
                        .length > valueIndex
                        ? item[DRUG_PROFILE_SHELL_KEYS.therapeuticArea].value
                            ?.map((valueItem, valueItemIndex) => {
                              if (valueItemIndex === valueIndex) {
                                const checkDictionaryList = dictionaryList
                                  .map((listItem) => {
                                    const currentListItem =
                                      listItem.toLocaleLowerCase();
                                    const currentValueText =
                                      valueText.toLocaleLowerCase();

                                    if (currentListItem === currentValueText)
                                      return listItem;

                                    return null;
                                  })
                                  .filter(
                                    (listValueItem) => listValueItem !== null,
                                  );

                                return {
                                  text: checkDictionaryList[0] || "",
                                  is_modified: true,
                                };
                              }

                              return valueItem;
                            })
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text.toLowerCase() ===
                                  valueItem?.text.toLowerCase(),
                              );

                              return valueItemIndex === lastValueIndex;
                            })
                        : [
                            ...item[DRUG_PROFILE_SHELL_KEYS.therapeuticArea]
                              .value,
                            { text: valueText, is_modified: true },
                          ]
                            ?.map(
                              (
                                valueItem: {
                                  text: string;
                                  is_modified: boolean;
                                },
                                valueItemIndex: number,
                              ) => {
                                if (valueItemIndex === valueIndex) {
                                  const checkDictionaryList =
                                    dictionaryList.filter((listItem) => {
                                      const currentListItem =
                                        listItem.toLocaleLowerCase();
                                      const currentValueText =
                                        valueText.toLocaleLowerCase();

                                      return (
                                        currentListItem === currentValueText
                                      );
                                    });

                                  return {
                                    text: checkDictionaryList[0],
                                    is_modified: true,
                                  };
                                }

                                return valueItem;
                              },
                            )
                            .filter((valueItem) => !!valueItem.text)
                            .filter((valueItem, valueItemIndex, valueList) => {
                              const lastValueIndex = valueList.findIndex(
                                (target) =>
                                  target?.text?.toLowerCase() ===
                                  valueItem?.text?.toLowerCase(),
                              );
                              return valueItemIndex === lastValueIndex;
                            }),
                  },
                };
              }

              return {
                ...item,
                attributionInfo: {
                  attributionId:
                    item?.attributionInfo?.attributionId ||
                    createAttributionId(),
                },
                [shellKey as DrugProfileShellKeysNotIncludeAttributionId]: {
                  ...item[
                    shellKey as DrugProfileShellKeysNotIncludeAttributionId
                  ],
                  attributionId:
                    item[
                      shellKey as DrugProfileShellKeysNotIncludeAttributionId
                    ]?.attributionId || createAttributionId(),
                  value:
                    item[
                      shellKey as DrugProfileShellKeysNotIncludeAttributionId
                    ] &&
                    item[
                      shellKey as DrugProfileShellKeysNotIncludeAttributionId
                    ]?.value.length > valueIndex
                      ? item[
                          shellKey as DrugProfileShellKeysNotIncludeAttributionId
                        ].value
                          ?.map((valueItem, valueItemIndex) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return { text: valueText, is_modified: true };
                            }

                            return valueItem;
                          })
                          .filter((valueItem) => valueItem !== null)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          })
                      : [
                          ...item[
                            shellKey as DrugProfileShellKeysNotIncludeAttributionId
                          ].value,
                          { text: valueText, is_modified: true },
                        ]
                          ?.map((valueItem, valueItemIndex) => {
                            if (valueItemIndex === valueIndex) {
                              if (valueText === "") {
                                return null;
                              }
                              return { text: valueText, is_modified: true };
                            }

                            return valueItem;
                          })
                          .filter((valueItem) => valueItem !== null)
                          .filter((valueItem, valueItemIndex, valueList) => {
                            const lastValueIndex = valueList.findIndex(
                              (target) =>
                                target?.text.toLowerCase() ===
                                valueItem?.text.toLowerCase(),
                            );
                            return valueItemIndex === lastValueIndex;
                          }),
                },
              };
            }
            return item;
          },
        ),
      },
    }),
    [FUNDED_COMPANY]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) => {
      const currentData = data[SECTION_KEYS.ventureCapital]?.[FUNDED_COMPANY];

      return {
        ...data,
        [SECTION_KEYS.ventureCapital]: {
          ...data[SECTION_KEYS.ventureCapital],
          [FUNDED_COMPANY]: {
            ...currentData,
            [shellKey as VentureCapitalFundedCompanyShellKeys]: {
              ...currentData?.[
                shellKey as VentureCapitalFundedCompanyShellKeys
              ],
              attributionId:
                currentData?.[shellKey as VentureCapitalFundedCompanyShellKeys]
                  ?.attributionId || createAttributionId(),
              value:
                currentData?.[
                  shellKey as VentureCapitalFundedCompanyShellKeys
                ] &&
                currentData?.[shellKey as VentureCapitalFundedCompanyShellKeys]
                  ?.value.length > valueIndex
                  ? currentData?.[
                      shellKey as VentureCapitalFundedCompanyShellKeys
                    ].value
                      ?.map((valueItem, valueItemIndex) => {
                        if (valueItemIndex === valueIndex) {
                          if (valueText === "") {
                            return null;
                          }
                          return { text: valueText, is_modified: true };
                        }

                        return valueItem;
                      })
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      })
                  : [
                      ...(currentData?.[
                        shellKey as VentureCapitalFundedCompanyShellKeys
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      ?.map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }

                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      }),
            },
          },
        },
      } as ArticleEntryData;
    },
    [ROUND_TYPE_AND_STATUS]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) => {
      const currentData =
        data[SECTION_KEYS.ventureCapital]?.[ROUND_TYPE_AND_STATUS];

      return {
        ...data,
        [SECTION_KEYS.ventureCapital]: {
          ...data[SECTION_KEYS.ventureCapital],
          [ROUND_TYPE_AND_STATUS]: {
            ...currentData,
            [shellKey as VentureCapitalRoundTypeAndStatusShellKeys]: {
              ...currentData?.[
                shellKey as VentureCapitalRoundTypeAndStatusShellKeys
              ],
              attributionId:
                currentData?.[
                  shellKey as VentureCapitalRoundTypeAndStatusShellKeys
                ]?.attributionId || createAttributionId(),
              value:
                currentData?.[
                  shellKey as VentureCapitalRoundTypeAndStatusShellKeys
                ] &&
                currentData?.[
                  shellKey as VentureCapitalRoundTypeAndStatusShellKeys
                ]?.value.length > valueIndex
                  ? currentData?.[
                      shellKey as VentureCapitalRoundTypeAndStatusShellKeys
                    ].value
                      ?.map((valueItem, valueItemIndex) => {
                        if (valueItemIndex === valueIndex) {
                          if (valueText === "") {
                            return null;
                          }
                          return { text: valueText, is_modified: true };
                        }

                        return valueItem;
                      })
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      })
                  : [
                      ...(currentData?.[
                        shellKey as VentureCapitalRoundTypeAndStatusShellKeys
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      ?.map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }

                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      }),
            },
          },
        },
      } as ArticleEntryData;
    },
    [FINANCING_INFORMATION]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) => {
      const currentData =
        data[SECTION_KEYS.ventureCapital]?.[FINANCING_INFORMATION];

      return {
        ...data,
        [SECTION_KEYS.ventureCapital]: {
          ...data[SECTION_KEYS.ventureCapital],
          [FINANCING_INFORMATION]: {
            ...currentData,
            [shellKey as VentureCapitalFinancingInformationShellKeys]: {
              ...currentData?.[
                shellKey as VentureCapitalFinancingInformationShellKeys
              ],
              attributionId:
                currentData?.[
                  shellKey as VentureCapitalFinancingInformationShellKeys
                ]?.attributionId || createAttributionId(),
              value:
                currentData?.[
                  shellKey as VentureCapitalFinancingInformationShellKeys
                ] &&
                currentData?.[
                  shellKey as VentureCapitalFinancingInformationShellKeys
                ]?.value.length > valueIndex
                  ? currentData?.[
                      shellKey as VentureCapitalFinancingInformationShellKeys
                    ].value
                      ?.map((valueItem, valueItemIndex) => {
                        if (valueItemIndex === valueIndex) {
                          if (valueText === "") {
                            return null;
                          }
                          return { text: valueText, is_modified: true };
                        }

                        return valueItem;
                      })
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      })
                  : [
                      ...(currentData?.[
                        shellKey as VentureCapitalFinancingInformationShellKeys
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      ?.map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }

                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      }),
            },
          },
        },
      } as ArticleEntryData;
    },
    [INVESTORS]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) => {
      const currentData = data[SECTION_KEYS.ventureCapital]?.[INVESTORS];
      const valueLength =
        currentData?.[shellKey as VentureCapitalInvestorsShellKeys]?.value
          .length || 0;

      const shellValueData =
        currentData?.[shellKey as VentureCapitalInvestorsShellKeys] &&
        currentData?.[shellKey as VentureCapitalInvestorsShellKeys]?.value
          .length > valueIndex
          ? currentData?.[shellKey as VentureCapitalInvestorsShellKeys].value
              ?.map((valueItem, valueItemIndex) => {
                if (valueItemIndex === valueIndex) {
                  return {
                    text: valueText,
                    is_modified: true,
                    is_lead_investor:
                      valueText === "" ? false : valueItem.is_lead_investor,
                  };
                }

                return valueItem;
              })
              .filter((valueItem) => valueItem !== null)
          : [
              ...(currentData?.[shellKey as VentureCapitalInvestorsShellKeys]
                .value || []),
              {
                text: valueText,
                is_modified: true,
                is_lead_investor: false,
              },
            ]
              ?.map((valueItem, valueItemIndex) => {
                if (valueItemIndex === valueIndex) {
                  return {
                    text: valueText,
                    is_modified: true,
                    is_lead_investor: false,
                  };
                }

                return valueItem;
              })
              .filter((valueItem) => valueItem !== null);

      const isLastEmptyValue = () => {
        if (valueIndex === valueLength - 1 && valueText !== "") return false;
        return true;
      };

      const uniqueInvestorValue = (investorValue: EntryDataInvestorValue[]) => {
        return investorValue.filter(
          (currentItem, currentIndex, self) =>
            currentItem.text.trim() === "" ||
            currentIndex ===
              self.findIndex((item) => item.text === currentItem.text),
        );
      };

      const updatedEntryData = {
        ...data,
        [SECTION_KEYS.ventureCapital]: {
          ...data[SECTION_KEYS.ventureCapital],
          [INVESTORS]: {
            ...currentData,
            [shellKey as VentureCapitalInvestorsShellKeys]: {
              ...currentData?.[shellKey as VentureCapitalInvestorsShellKeys],
              attributionId:
                currentData?.[shellKey as VentureCapitalInvestorsShellKeys]
                  ?.attributionId || createAttributionId(),
              value: !isLastEmptyValue()
                ? uniqueInvestorValue([
                    ...shellValueData,
                    {
                      text: "",
                      is_modified: true,
                      is_lead_investor: false,
                    },
                  ])
                : uniqueInvestorValue(shellValueData),
            },
          },
        },
      } as ArticleEntryData;

      return updatedEntryData;
    },
    [ADDITIONAL_INFORMATION_VENTURE_CAPITAL]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) => {
      const currentData =
        data[SECTION_KEYS.ventureCapital]?.[
          ADDITIONAL_INFORMATION_VENTURE_CAPITAL
        ];

      return {
        ...data,
        [SECTION_KEYS.ventureCapital]: {
          ...data[SECTION_KEYS.ventureCapital],
          [ADDITIONAL_INFORMATION_VENTURE_CAPITAL]: {
            ...currentData,
            [shellKey as VentureCapitalAdditionalInformationShellKeys]: {
              ...currentData?.[
                shellKey as VentureCapitalAdditionalInformationShellKeys
              ],
              attributionId:
                currentData?.[
                  shellKey as VentureCapitalAdditionalInformationShellKeys
                ]?.attributionId || createAttributionId(),
              value:
                currentData?.[
                  shellKey as VentureCapitalAdditionalInformationShellKeys
                ] &&
                currentData?.[
                  shellKey as VentureCapitalAdditionalInformationShellKeys
                ]?.value.length > valueIndex
                  ? currentData?.[
                      shellKey as VentureCapitalAdditionalInformationShellKeys
                    ].value
                      ?.map((valueItem, valueItemIndex) => {
                        if (valueItemIndex === valueIndex) {
                          if (valueText === "") {
                            return null;
                          }
                          return { text: valueText, is_modified: true };
                        }

                        return valueItem;
                      })
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      })
                  : [
                      ...(currentData?.[
                        shellKey as VentureCapitalAdditionalInformationShellKeys
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      ?.map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }

                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                      .filter((valueItem, valueItemIndex, valueList) => {
                        const lastValueIndex = valueList.findIndex(
                          (target) =>
                            target?.text.toLowerCase() ===
                            valueItem?.text.toLowerCase(),
                        );
                        return valueItemIndex === lastValueIndex;
                      }),
            },
          },
        },
      } as ArticleEntryData;
    },
    [KEY_COMMENT]: (
      data: ArticleEntryData,
      tableIndex: number,
      tableKey: string | null,
      groupShellKey: string | null,
      groupShellIndex: number | null,
      subGroupShellIndex: number | null,
      shellKey: string,
      shellIndex: number,
      valueText: string,
      valueIndex: number,
    ) =>
      ({
        ...data,
        [SECTION_KEYS.ventureCapital]: {
          ...data[SECTION_KEYS.ventureCapital],
          [KEY_COMMENT]: {
            ...data[SECTION_KEYS.ventureCapital]?.[KEY_COMMENT],
            [shellKey]: {
              ...data[SECTION_KEYS.ventureCapital]?.[KEY_COMMENT]?.[
                shellKey as ClinicalTrialsKeyCommentShellKey
              ],

              value:
                [
                  ...(data[SECTION_KEYS.ventureCapital]?.[KEY_COMMENT]?.[
                    shellKey as QuoteAndSpokesPersonType
                  ].value || []),
                ].length > valueIndex
                  ? data[SECTION_KEYS.ventureCapital]?.[KEY_COMMENT]?.[
                      shellKey as QuoteAndSpokesPersonType
                    ].value
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null)
                  : [
                      ...(data[SECTION_KEYS.ventureCapital]?.[KEY_COMMENT]?.[
                        shellKey as QuoteAndSpokesPersonType
                      ].value || []),
                      { text: valueText, is_modified: true },
                    ]
                      .map(
                        (
                          valueItem: {
                            text: string;
                            is_modified: boolean;
                          },
                          valueItemIndex: number,
                        ) => {
                          if (valueItemIndex === valueIndex) {
                            if (valueText === "") {
                              return null;
                            }
                            return { text: valueText, is_modified: true };
                          }
                          return valueItem;
                        },
                      )
                      .filter((valueItem) => valueItem !== null),
            },
          },
        },
      } as ArticleEntryData),
  },
};
