import {
  ARTICLE_CONTENTS_DEAL_TERMS_KEYS as DEAL_TERMS_KEYS,
  DEAL_TERMS_ATTRIBUTION_KEYS,
  DEAL_TERMS_DIRECTION_VALUE,
  PARAGRAPH_CODE,
} from "@frontend/common";

import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import {
  REF_CODE_LICENSEE_DRUG,
  REF_CODE_LICENSEE_EXCLUSIVITY,
  REF_CODE_LICENSEE_INDICATION,
  REF_CODE_LICENSEE_TERRITORY,
  REF_CODE_RIGHTS_SCOPE,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";
import { createEditArticleCrossLicenseShellList } from "@EditArticleContainer/shared/utils/createEditArticleShellList";
import {
  createIndexList,
  createIndexListByArray,
} from "@utils/createIndexList";
import { createMultiLineText } from "@utils/createMultiLineText/createMultiLineText";

import { deduplicateRightsScope } from "../utils/deduplicateRightsScope";

export const useCreateCrossLicenseRightsTables = () => {
  const dealTerms = useParagraphEditedData(PARAGRAPH_CODE.TY002.dealTerms);
  const LICENSEE_TABLE_PATH =
    dealTerms.text[DEAL_TERMS_KEYS.dealTerms][DEAL_TERMS_KEYS.licenseeTable];
  const partnerADataIndex =
    LICENSEE_TABLE_PATH.findIndex(
      (licenseeData) =>
        licenseeData[DEAL_TERMS_ATTRIBUTION_KEYS?.attributionInfo] &&
        licenseeData[DEAL_TERMS_ATTRIBUTION_KEYS?.attributionInfo]?.[
          DEAL_TERMS_ATTRIBUTION_KEYS.dealDirection
        ] === DEAL_TERMS_DIRECTION_VALUE.partnerA,
    ) || 0;
  const partnerBDataIndex =
    LICENSEE_TABLE_PATH.findIndex(
      (licenseeData) =>
        licenseeData[DEAL_TERMS_ATTRIBUTION_KEYS?.attributionInfo] &&
        licenseeData[DEAL_TERMS_ATTRIBUTION_KEYS?.attributionInfo]?.[
          DEAL_TERMS_ATTRIBUTION_KEYS.dealDirection
        ] === DEAL_TERMS_DIRECTION_VALUE.partnerB,
    ) || 0;

  const originalRightsScope = (dataIndex: number) =>
    LICENSEE_TABLE_PATH.at(dataIndex)?.[DEAL_TERMS_KEYS.rightsScope] ?? [];

  const targetRightsScope = (dataIndex: number) =>
    deduplicateRightsScope(originalRightsScope(dataIndex));
  const rightsScopeIndexList = (dataIndex: number) =>
    createIndexListByArray(targetRightsScope(dataIndex));

  const createDefaultRightsTables = (
    index: number,
    dataIndex: number,
    positionIndex: number,
  ) => {
    const drugText = (valueIndex: number) =>
      createMultiLineText({
        textList:
          targetRightsScope(dataIndex)
            ?.at(index)
            ?.[DEAL_TERMS_KEYS.drug]?.at(valueIndex)
            ?.value?.map((item) => item.text ?? "") ?? [],
        parseString: (text) => `- ${text?.trim()}`,
      });

    const indicationText = (valueIndex: number) =>
      createMultiLineText({
        textList:
          targetRightsScope(dataIndex)
            ?.at(index)
            ?.[DEAL_TERMS_KEYS.indication]?.at(valueIndex)
            ?.value?.map((item) => item.text ?? "") ?? [],
        parseString: (text) => `- ${text?.trim()}`,
        bulletStartIndex: 1,
      });

    const exclusivityText =
      targetRightsScope(dataIndex).at(index)?.Exclusivity.value.at(0)?.text ??
      "";

    const territoryText = createMultiLineText({
      textList:
        targetRightsScope(dataIndex)
          .at(index)
          ?.[DEAL_TERMS_KEYS.territory].value.map((item) => item.text ?? "") ??
        [],
      parseString: (text) => `- ${text?.trim()}`,
    });

    const drugRowCount = Math.max(
      targetRightsScope(partnerADataIndex).at(index)?.[DEAL_TERMS_KEYS.drug]
        .length ?? 0,
      targetRightsScope(partnerBDataIndex).at(index)?.[DEAL_TERMS_KEYS.drug]
        .length ?? 0,
    );
    const isDrugIndicationValue =
      createIndexList(drugRowCount)
        .flatMap((rowIndex) => [drugText(rowIndex), indicationText(rowIndex)])
        .filter((item) => !!item).length > 0;

    if (!isDrugIndicationValue) {
      return createEditArticleCrossLicenseShellList([
        {
          title: "Exclusivity",
          text: exclusivityText,
          labels: [],
          refKey: createRefCode(
            REF_CODE_RIGHTS_SCOPE,
            REF_CODE_LICENSEE_EXCLUSIVITY,
            index,
            positionIndex,
          ),
        },
        {
          title: "Territory",
          text: territoryText,
          labels: [],
          refKey: createRefCode(
            REF_CODE_RIGHTS_SCOPE,
            REF_CODE_LICENSEE_TERRITORY,
            index,
            positionIndex,
          ),
        },
      ]);
    }

    return createEditArticleCrossLicenseShellList([
      ...createIndexList(drugRowCount)
        .map((_, rowIndex) => {
          return [
            {
              title: "Asset(s)",
              text: drugText(rowIndex),
              labels: [],
              refKey: createRefCode(
                REF_CODE_RIGHTS_SCOPE,
                REF_CODE_LICENSEE_DRUG,
                index,
                rowIndex,
                positionIndex,
              ),
            },
            {
              title: "Main Usage",
              text: indicationText(rowIndex),
              labels: [],
              refKey: createRefCode(
                REF_CODE_RIGHTS_SCOPE,
                REF_CODE_LICENSEE_INDICATION,
                index,
                rowIndex,
                positionIndex,
              ),
            },
          ];
        })
        .filter((item) => item.length > 0),

      {
        title: "Exclusivity",
        text: exclusivityText,
        labels: [],
        refKey: createRefCode(
          REF_CODE_RIGHTS_SCOPE,
          REF_CODE_LICENSEE_EXCLUSIVITY,
          index,
          positionIndex,
        ),
      },
      {
        title: "Territory",
        text: territoryText,
        labels: [],
        refKey: createRefCode(
          REF_CODE_RIGHTS_SCOPE,
          REF_CODE_LICENSEE_TERRITORY,
          index,
          positionIndex,
        ),
      },
    ]);
  };

  const rightsScopeTables = (dataIndex: number, positionIndex: number) => {
    return rightsScopeIndexList(dataIndex).map((index) =>
      createDefaultRightsTables(index, dataIndex, positionIndex),
    );
  };

  const PARTNER_A_POS = 1;
  const PARTNER_B_POS = 2;

  return {
    partnerARightsScope: rightsScopeTables(partnerADataIndex, PARTNER_A_POS),
    partnerBRightsScope: rightsScopeTables(partnerBDataIndex, PARTNER_B_POS),
  };
};
