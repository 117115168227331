import { useCallback } from "react";

import {
  CROSS_LICENSE_AGREEMENT_TABLE_KEYS,
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL,
  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_LICENSEE_TABLE_KEYS,
  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS,
  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS,
  ENTRY_DATA_RIGHTS_SCOPE_KEYS,
  ENTRY_DATA_SECTION_KEYS,
  ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import {
  DEFAULT_FOCUS_STATUS,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import { useTableCreatorCurrentHistory } from "@FillInTableContainer/shared/context/TableCreatorHistoryContext";
import { useTableCreatorPositionInfo } from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { useEntryDataState } from "@stores/entryData";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import type { ArticleEntryData } from "@/types/articleEntryData.types";

interface Returns {
  handleClickLicenseeTableClearButton: () => void;
  handleKeyDownToClearLicenseeTable: (event: KeyboardEvent) => void;
}

export interface TableInfoProps {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}

export const useClearCrossLicenseTable = (
  tablePropsInfo: TableInfoProps,
): Returns => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex } =
    tablePropsInfo;

  const { changeCurrentHistory } = useTableCreatorCurrentHistory();

  const { info } = useTableCreatorPositionInfo();
  const [entryData, setEntryData] = useEntryDataState(getPressIdFromURL());
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();

  const PARTNER_A_INDEX = 1;
  const PARTNER_B_INDEX = 0;

  const handleClearTable = useCallback(() => {
    const targetLicenseeData = entryData.Deals?.[ENTRY_DATA_LICENSEE_TABLE];

    const clearOtherFinancialTermsTable = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: targetLicenseeData?.map(
            (tableInfo, tableInfoIndex) => {
              if (tableInfoIndex === PARTNER_A_INDEX) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                    {
                      ...tableInfo?.[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ],
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherFinancialTermsScLa]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ][
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .otherFinancialTermsScLa
                          ],
                          value: [],
                        },
                    },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    const clearLicensingPartnerTable = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: targetLicenseeData?.map(
            (tableInfo, tableInfoIndex) => {
              if (
                tableInfoIndex === PARTNER_A_INDEX ||
                tableInfoIndex === PARTNER_B_INDEX
              ) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName]: {
                    ...tableInfo?.[ENTRY_DATA_LICENSEE_TABLE_KEYS.licenseeName],
                    value: [],
                  },
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                    {
                      ...tableInfo?.[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ],
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.licenseeResponsibilityAndRightsScLa]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ]?.[
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .licenseeResponsibilityAndRightsScLa
                          ],
                          value: [],
                        },
                    },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    const clearRightScopeTable = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: targetLicenseeData?.map(
            (tableInfo, tableInfoIndex) => {
              if (
                tableInfoIndex === PARTNER_A_INDEX ||
                tableInfoIndex === PARTNER_B_INDEX
              ) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope]: tableInfo?.[
                    ENTRY_DATA_LICENSEE_TABLE_KEYS.rightsScope
                  ]?.map((groupShellInfo) => {
                    return {
                      ...groupShellInfo,
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug]: {
                        ...groupShellInfo?.[ENTRY_DATA_RIGHTS_SCOPE_KEYS.drug],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity]: {
                        ...groupShellInfo?.[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.exclusivity
                        ],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication]: {
                        ...groupShellInfo?.[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.indication
                        ],
                        value: [],
                      },
                      [ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory]: {
                        ...groupShellInfo?.[
                          ENTRY_DATA_RIGHTS_SCOPE_KEYS.territory
                        ],
                        value: [],
                      },
                    };
                  }),
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    const clearMainFinancialTermsTable = (): ArticleEntryData => {
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData.Deals,
          [ENTRY_DATA_LICENSEE_TABLE]: targetLicenseeData?.map(
            (tableInfo, tableInfoIndex) => {
              if (
                tableInfoIndex === PARTNER_A_INDEX ||
                tableInfoIndex === PARTNER_B_INDEX
              ) {
                return {
                  ...tableInfo,
                  [ENTRY_DATA_LICENSEE_TABLE_KEYS.strategicCollaborationLicenseAgreementTerms]:
                    {
                      ...tableInfo?.[
                        ENTRY_DATA_LICENSEE_TABLE_KEYS
                          .strategicCollaborationLicenseAgreementTerms
                      ],
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.royalty]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ]?.[
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .royalty
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.totalPaymentScLa]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ]?.[
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .totalPaymentScLa
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.disclosedPaymentsSumScLa]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ]?.[
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .disclosedPaymentsSumScLa
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.upfrontPayment]:
                        {
                          ...tableInfo?.[
                            ENTRY_DATA_LICENSEE_TABLE_KEYS
                              .strategicCollaborationLicenseAgreementTerms
                          ]?.[
                            ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                              .upfrontPayment
                          ],
                          value: [],
                        },
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.milestonePaymentAndCondition]:
                        tableInfo[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS
                            .strategicCollaborationLicenseAgreementTerms
                        ][
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .milestonePaymentAndCondition
                        ].map((subGroupShellInfo) => {
                          return {
                            ...subGroupShellInfo,
                            [ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestoneConditionTitle]:
                              {
                                ...subGroupShellInfo?.[
                                  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                                    .milestoneConditionTitle
                                ],
                                value: [],
                              },
                            [ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS.milestonePayment]:
                              {
                                ...subGroupShellInfo?.[
                                  ENTRY_DATA_MILESTONE_PAYMENT_CONDITION_KEYS
                                    .milestonePayment
                                ],
                                value: [],
                              },
                          };
                        }),
                      [ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS.otherPaymentAndDetailScLa]:
                        tableInfo[
                          ENTRY_DATA_LICENSEE_TABLE_KEYS
                            .strategicCollaborationLicenseAgreementTerms
                        ][
                          ENTRY_DATA_STRATEGIC_COLLABORATION_LICENSE_AGREEMENT_TERMS_KEYS
                            .otherPaymentAndDetailScLa
                        ].map((subGroupShellInfo) => {
                          return {
                            ...subGroupShellInfo,
                            [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentDetailScLa]:
                              {
                                ...subGroupShellInfo?.[
                                  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                    .otherPaymentDetailScLa
                                ],
                                value: [],
                              },
                            [ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS.otherPaymentScLa]:
                              {
                                ...subGroupShellInfo?.[
                                  ENTRY_DATA_OTHER_PAYMENT_DETAIL_KEYS
                                    .otherPaymentScLa
                                ],
                                value: [],
                              },
                          };
                        }),
                    },
                };
              }
              return tableInfo;
            },
          ),
        },
      };
    };

    const clearAdditionalInformationTable = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.additionalInformation]:
              {
                ...additionalInformationTableData?.[
                  ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS
                    .additionalInformation
                ],
                value: [],
              },
          },
        },
      } as ArticleEntryData;
    };

    const clearDealPurposeTable = (): ArticleEntryData => {
      const additionalInformationTableData =
        entryData[ENTRY_DATA_SECTION_KEYS.deals]?.[
          ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL
        ];
      return {
        ...entryData,
        [ENTRY_DATA_SECTION_KEYS.deals]: {
          ...entryData[ENTRY_DATA_SECTION_KEYS.deals],
          [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL]: {
            ...additionalInformationTableData,
            [ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal]: {
              ...additionalInformationTableData?.[
                ENTRY_DATA_ADDITIONAL_INFORMATION_DEAL_KEYS.purposeOfTheDeal
              ],
              value: [],
            },
          },
        },
      } as ArticleEntryData;
    };

    if (info.tableKey === "Deal Purpose") {
      setEntryData(clearDealPurposeTable());
      changeCurrentHistory({
        entryData: clearDealPurposeTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (info.tableKey === CROSS_LICENSE_AGREEMENT_TABLE_KEYS.licensee) {
      setEntryData(clearLicensingPartnerTable());
      changeCurrentHistory({
        entryData: clearLicensingPartnerTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (info.tableKey === CROSS_LICENSE_AGREEMENT_TABLE_KEYS.rightsScope) {
      setEntryData(clearRightScopeTable());
      changeCurrentHistory({
        entryData: clearRightScopeTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (info.tableKey === CROSS_LICENSE_AGREEMENT_TABLE_KEYS.financialTerms) {
      setEntryData(clearMainFinancialTermsTable());
      changeCurrentHistory({
        entryData: clearMainFinancialTermsTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (
      info.tableKey === CROSS_LICENSE_AGREEMENT_TABLE_KEYS.otherFinancialTerms
    ) {
      setEntryData(clearOtherFinancialTermsTable());
      changeCurrentHistory({
        entryData: clearOtherFinancialTermsTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }

    if (info.tableKey === "Additional Information") {
      setEntryData(clearAdditionalInformationTable());
      changeCurrentHistory({
        entryData: clearAdditionalInformationTable(),
        changedDataInfo: {
          target: "table",
          action: "clear",
          position: {
            groupTableKey,
            groupTableIndex,
            tableKey,
            tableIndex,
            groupShellKey: null,
            groupShellIndex: null,
            subGroupShellIndex: null,
            subGroupShellKey: null,
            shellKey: null,
            shellIndex: null,
            valueIndex: null,
            value: null,
          },
        },
      });

      changeFocusStatus(DEFAULT_FOCUS_STATUS);
    }
  }, [
    changeCurrentHistory,
    changeFocusStatus,
    entryData,
    groupTableIndex,
    groupTableKey,
    info.tableKey,
    setEntryData,
    tableIndex,
    tableKey,
  ]);

  const handleClickLicenseeTableClearButton = () => {
    if (focusStatus.type === "clear") handleClearTable();
  };

  const handleKeyDownToClearLicenseeTable = useCallback(
    (event: KeyboardEvent) => {
      if (focusStatus.type === "clear" && event.key === "Delete") {
        handleClearTable();
      }
      changeValidatedInfo(DEFAULT_VALIDATED_INFO);
    },
    [changeValidatedInfo, focusStatus.type, handleClearTable],
  );

  return {
    handleClickLicenseeTableClearButton,
    handleKeyDownToClearLicenseeTable,
  };
};
