import React from "react";

import {
  PARAGRAPH_CODE,
  DEAL_TERMS_DIRECTION_VALUE,
  DealTermsCrossLicenseTableContainer,
} from "@frontend/common";

import EditableTextContainer from "@EditArticleContainer/shared/components/EditableTextContainer/EditableTextContainer";
import { useCreateCompanyLogoComponentList } from "@EditArticleContainer/shared/hooks/useCreateCompanyLogoComponentList";
import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";
import {
  REF_CODE_LICENSEE_NAME,
  REF_CODE_LICENSEE_RESPONSIBILITY,
  REF_CODE_UPFRONT,
  REF_CODE_MILESTONE,
  REF_CODE_TOTAL_PRICE_UP_TO,
  REF_CODE_ROYALTY,
  REF_CODE_OTHER_FINANCIAL_TERMS,
  REF_CODE_DEAL_OTHER_PAYMENT_SC_LA,
} from "@EditArticleContainer/shared/libs/refCode/constants/refCode.constants";
import { createRefCode } from "@EditArticleContainer/shared/libs/refCode/utils/createRefCode";

import {
  licenseeMilestoneText,
  licenseeNameText,
  licenseeOtherFinancialTermsText,
  licenseeOtherPaymentText,
  licenseeResponsibilityText,
  licenseeRoyaltyText,
  licenseeTotalPriceText,
  licenseeUpfrontText,
} from "./utils/getCrossLicenseAgreementShellValue";
import { useCreateCrossLicenseRightsTables } from "../../hooks/useCreateCrossLicenseRightsTables";

const CrossLicenseAgreementTable = () => {
  const { createCompanyLogoComponentList } =
    useCreateCompanyLogoComponentList();
  const { partnerA, partnerB } = DEAL_TERMS_DIRECTION_VALUE;
  const dealTerms = useParagraphEditedData(PARAGRAPH_CODE.TY002.dealTerms);

  const { partnerARightsScope, partnerBRightsScope } =
    useCreateCrossLicenseRightsTables();

  const SHELL_TEXT_PARTNER_A = {
    licenseeNameText: licenseeNameText(dealTerms, partnerA),
    licenseeResponsibilityText: licenseeResponsibilityText(dealTerms, partnerA),
    licenseeUpfrontText: licenseeUpfrontText(dealTerms, partnerA),
    licenseeMilestoneText: licenseeMilestoneText(dealTerms, partnerA),
    licenseeOtherPaymentText: licenseeOtherPaymentText(dealTerms, partnerA),
    licenseeTotalPriceText: licenseeTotalPriceText(dealTerms, partnerA),
    licenseeRoyaltyText: licenseeRoyaltyText(dealTerms, partnerA),
    licenseeOtherFinancialTermsText: licenseeOtherFinancialTermsText(
      dealTerms,
      partnerA,
    ),
  };

  const SHELL_TEXT_PARTNER_B = {
    licenseeNameText: licenseeNameText(dealTerms, partnerB),
    licenseeResponsibilityText: licenseeResponsibilityText(dealTerms, partnerB),
    licenseeUpfrontText: licenseeUpfrontText(dealTerms, partnerB),
    licenseeMilestoneText: licenseeMilestoneText(dealTerms, partnerB),
    licenseeOtherPaymentText: licenseeOtherPaymentText(dealTerms, partnerB),
    licenseeTotalPriceText: licenseeTotalPriceText(dealTerms, partnerB),
    licenseeRoyaltyText: licenseeRoyaltyText(dealTerms, partnerB),
    licenseeOtherFinancialTermsText: licenseeOtherFinancialTermsText(
      dealTerms,
      partnerB,
    ),
  };

  const COMMON_EDITABLE_TEXT_STYLE = {
    padding: "0.8rem",
    borderRadius: "0",
  };

  const licensingInfoData = {
    partnerA: {
      licensingName: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_A.licenseeNameText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_LICENSEE_NAME, 0) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
          imagePosition="left"
          imageNodeList={createCompanyLogoComponentList(
            SHELL_TEXT_PARTNER_A.licenseeNameText
              .replaceAll("- ", "")
              .split(`\n`) ?? "",
          )}
          isDisabled
        />
      ),
      responsibilityAndRights: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_A.licenseeResponsibilityText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_LICENSEE_RESPONSIBILITY, 0) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
    },
    partnerB: {
      licensingName: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_B.licenseeNameText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_LICENSEE_NAME, 1) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
          imagePosition="left"
          imageNodeList={createCompanyLogoComponentList(
            SHELL_TEXT_PARTNER_B.licenseeNameText
              .replaceAll("- ", "")
              .split(`\n`) ?? "",
          )}
          isDisabled
        />
      ),
      responsibilityAndRights: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_B.licenseeResponsibilityText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_LICENSEE_RESPONSIBILITY, 1) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
    },
  };

  const financialTermsData = {
    partnerA: {
      upfrontPayment: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_A.licenseeUpfrontText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_UPFRONT, 0) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
      milestonePayment: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_A.licenseeMilestoneText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_MILESTONE, 0) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
      otherPayment: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_A.licenseeOtherPaymentText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_DEAL_OTHER_PAYMENT_SC_LA, 0) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
      totalDealValue: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_A.licenseeTotalPriceText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_TOTAL_PRICE_UP_TO, 0) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
      royalty: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_A.licenseeRoyaltyText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_ROYALTY, 0) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
    },

    partnerB: {
      upfrontPayment: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_B.licenseeUpfrontText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_UPFRONT, 1) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
      milestonePayment: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_B.licenseeMilestoneText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_MILESTONE, 1) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
      otherPayment: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_B.licenseeOtherPaymentText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_DEAL_OTHER_PAYMENT_SC_LA, 1) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
      totalDealValue: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_B.licenseeTotalPriceText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_TOTAL_PRICE_UP_TO, 1) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
      royalty: (
        <EditableTextContainer
          item={{
            text: SHELL_TEXT_PARTNER_B.licenseeRoyaltyText,
            labels: [],
          }}
          refKey={createRefCode(REF_CODE_ROYALTY, 1) ?? ""}
          canTextEdit
          style={COMMON_EDITABLE_TEXT_STYLE}
        />
      ),
    },
  };

  const otherFinancialTermsData = {
    otherFinancialTerms: (
      <EditableTextContainer
        item={{
          text: SHELL_TEXT_PARTNER_A.licenseeOtherFinancialTermsText,
          labels: [],
        }}
        refKey={createRefCode(REF_CODE_OTHER_FINANCIAL_TERMS, 0)}
        canTextEdit
        style={COMMON_EDITABLE_TEXT_STYLE}
      />
    ),
  };

  const rightsScopeData = {
    partnerA: partnerARightsScope.map((tableData) => {
      const territoryData = (
        tableData?.find(
          (itemData) =>
            !Array.isArray(itemData) && itemData.title === "Territory",
        ) as {
          title: string;
          value: React.ReactNode;
        }
      )?.value;
      const exclusivityData = (
        tableData?.find(
          (itemData) =>
            !Array.isArray(itemData) && itemData.title === "Exclusivity",
        ) as {
          title: string;
          value: React.ReactNode;
        }
      )?.value;

      const drugInfoData = tableData
        .filter((data) => Array.isArray(data))
        .map((itemData) => {
          const drugData = itemData.find(
            (item) => item.title === "Asset(s)",
          )?.value;
          const mainUsageData = itemData.find(
            (item) => item.title === "Main Usage",
          )?.value;
          return {
            drug: drugData,
            mainUsage: mainUsageData,
          };
        });

      return {
        territory: territoryData,
        exclusivity: exclusivityData,
        drugInfo: drugInfoData,
      };
    }),
    partnerB: partnerBRightsScope.map((tableData) => {
      const territoryData = (
        tableData?.find(
          (itemData) =>
            !Array.isArray(itemData) && itemData.title === "Territory",
        ) as {
          title: string;
          value: React.ReactNode;
        }
      )?.value;
      const exclusivityData = (
        tableData?.find(
          (itemData) =>
            !Array.isArray(itemData) && itemData.title === "Exclusivity",
        ) as {
          title: string;
          value: React.ReactNode;
        }
      )?.value;

      const drugInfoData = tableData
        .filter((data) => Array.isArray(data))
        .map((itemData) => {
          const drugData = itemData.find(
            (item) => item.title === "Asset(s)",
          )?.value;
          const mainUsageData = itemData.find(
            (item) => item.title === "Main Usage",
          )?.value;
          return {
            drug: drugData,
            mainUsage: mainUsageData,
          };
        });

      return {
        territory: territoryData,
        exclusivity: exclusivityData,
        drugInfo: drugInfoData,
      };
    }),
  };

  return (
    <>
      <DealTermsCrossLicenseTableContainer
        licensingInfoData={licensingInfoData}
        otherFinancialTermsData={otherFinancialTermsData}
        financialTermsData={financialTermsData}
        rightScopeData={rightsScopeData}
      />
    </>
  );
};

export default CrossLicenseAgreementTable;
