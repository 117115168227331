import React from "react";

import { DEAL_TYPE_KEYS } from "@frontend/common";

import { useCurrentDealTypeValue } from "@FillInTableContainer/shared/context/CurrentDealTypeContext";

import DealTermsCrossLicenseGroupTable from "./components/DealTermsCrossLicenseGroupTable/DealTermsCrossLicenseGroupTable";
import DealTermsLicenseAgreementGroupTable from "./components/DealTermsLicenseAgreementGroupTable/DealTermsLicenseAgreementGroupTable";
import DealTermsMergeGroupTable from "./components/DealTermsMergeGroupTable/DealTermsMergeGroupTable";
import { DEAL_TERMS_LAYOUT } from "./constants/dealTermsLayout";
import { useSetCrossLicenseLayout } from "./hooks/useSetCrossLicenseLayout";

const GROUP_TABLE_INDEX = 2;

const DealsDealTermsTableCreatorContainer = () => {
  const licenseAgreementLayout = DEAL_TERMS_LAYOUT["License Agreement"];
  const mergeLayout = DEAL_TERMS_LAYOUT["M&A"];
  const crossLicenseLayout = DEAL_TERMS_LAYOUT["Cross License Agreement"];
  const { isMergeAndAcquisition, currentDealTypeList } =
    useCurrentDealTypeValue();

  const { isLayoutValid } = useSetCrossLicenseLayout({
    groupTableKey: licenseAgreementLayout.groupTableKey,
  });

  if (isMergeAndAcquisition)
    return (
      <DealTermsMergeGroupTable
        key={`group-table-${String(GROUP_TABLE_INDEX)}`}
        groupTableKey={mergeLayout.groupTableKey}
        tableList={mergeLayout.tableList}
        groupTableIndex={GROUP_TABLE_INDEX}
      />
    );

  if (currentDealTypeList.includes(DEAL_TYPE_KEYS.crossLicenseAgreement)) {
    if (!isLayoutValid) return null;

    return (
      <DealTermsCrossLicenseGroupTable
        key={`group-table-${String(GROUP_TABLE_INDEX)}`}
        groupTableKey={crossLicenseLayout.groupTableKey}
        tableList={crossLicenseLayout.tableList}
        groupTableIndex={GROUP_TABLE_INDEX}
      />
    );
  }

  return (
    <>
      <DealTermsLicenseAgreementGroupTable
        key={`group-table-${String(GROUP_TABLE_INDEX)}`}
        groupTableKey={licenseAgreementLayout.groupTableKey}
        tableList={licenseAgreementLayout.tableList}
        groupTableIndex={GROUP_TABLE_INDEX}
      />
    </>
  );
};

export default DealsDealTermsTableCreatorContainer;
