import { useState } from "react";

import { ProcessStatusCode } from "@/types/code.types";
import { usePublishableArticleInfoMutation } from "@api/articleInfo/useArticleInfo";
import { useCreateEntryDataMutation } from "@api/createEntryData/createEntryData";
import { useUnsuitablePressCancelMutation } from "@api/unsuitablePress/unsuitablePress";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import {
  checkSuperAdmin,
  useAuthorityType,
} from "@libs/roles/hooks/useAuthorityType";
import { useEditorRoles } from "@libs/roles/hooks/useEditorRoles";

import PublishableCheckboxIcon from "./components/PublishableCheckboxIcon/PublishableCheckboxIcon";

type Props = {
  data: {
    pressId: number;
    publishable: boolean;
    draftArticleStatus: ProcessStatusCode;
    aiDone: boolean;
  };
};

export const PressListPublishableContainer = ({ data }: Props) => {
  const { mutateAsync: updatePublishable } =
    usePublishableArticleInfoMutation();
  const { mutateAsync: createEntryData } = useCreateEntryDataMutation();

  const editorRoles = useEditorRoles();

  const authorityType = useAuthorityType();
  const isSuperAdmin = checkSuperAdmin(authorityType);

  const { mutateAsync: turnOffUnsuitable } = useUnsuitablePressCancelMutation();

  const [, setTargetUnsuitablePressId] = useState<number>(0);

  const checkDisabledPublishableByRoles = () => {
    const publishEditorCodes = [
      "EDTK0204",
      "EDTK0304",
      "EDTK0404",
      "EDTK0504",
      "EDTK0604",
      "EDTK0704",
      "EDTK0804",
    ];

    const isPublishEditor =
      editorRoles?.filter((role) => publishEditorCodes.includes(role)).length >
      0;
    if (isSuperAdmin) return false;
    if (isPublishEditor) return false;
    return true;
  };

  const createDAE = (id: number) => {
    if (!isSuperAdmin) return;
    createEntryData(id);
  };

  const handlePublishable = (
    id: number,
    publishable: boolean,
    status: ProcessStatusCode,
    aiDone: boolean,
  ) => {
    if (status === DRAFT_STATUS_CODE.unsuitable) {
      turnOffUnsuitable(id);
      updatePublishable({
        draftArticleId: id,
        isPublishable: publishable,
      });

      setTargetUnsuitablePressId(0);

      if (publishable === true && aiDone === false) {
        createDAE(id);
      }
      return;
    }
    updatePublishable({
      draftArticleId: id,
      isPublishable: publishable,
    });
    setTargetUnsuitablePressId(0);
    if (publishable === true && status === DRAFT_STATUS_CODE.notForAiEntry) {
      createDAE(id);
    }
  };

  return (
    <PublishableCheckboxIcon
      isChecked={data.publishable || false}
      onClick={() =>
        handlePublishable(
          data.pressId,
          !data.publishable,
          data.draftArticleStatus,
          data.aiDone || false,
        )
      }
      disabled={checkDisabledPublishableByRoles()}
    />
  );
};
