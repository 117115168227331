import styled, { css } from "styled-components";

import {
  DeleteButton,
  DeleteButtonWrapper,
  FixedWrapper,
} from "@FillInTableContainer/shared/styles/deleteButton.style";
import {
  DefaultDraggableGroupShellWrapper,
  GroupShellTitleWrapper,
  OutsideWrapper,
} from "@FillInTableContainer/shared/styles/groupShell.style";
import {
  SelectButton,
  SelectButtonDivider,
  SelectButtonDividerWrapper,
  SelectContentWrapper,
} from "@FillInTableContainer/shared/styles/rowSelectButton.style";

export const GroupShellOutsideWrapper = styled.div`
  position: relative;
  padding-left: 0;
`;

export const GroupShellWrapper = styled(OutsideWrapper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 0;
`;

export const GroupShell = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FixedDeleteButtonWrapper = styled(FixedWrapper)<{
  isShellTitle?: boolean;
}>`
  top: -2.4rem;
  left: -0.2rem;
  right: auto;

  ${({ isShellTitle }) =>
    isShellTitle &&
    css`
      top: -2.4rem;
      left: -0.2rem;
    `}
`;

export const SubGroupShellDivider = styled.div`
  width: 100%;
  height: 0.2rem;
  background: var(--Color-Common-Border-Tertiary, #e5e7eb);
`;

export const DeleteTableButtonWrapper = styled(DeleteButtonWrapper)``;

export const DeleteTableButton = styled(DeleteButton)``;

export const SelectWrapper = styled(SelectContentWrapper)``;

export const GroupShellSelectButton = styled(SelectButton)``;

export const GroupShellSelectButtonDivider = styled(SelectButtonDivider)``;

export const GroupShellSelectButtonDividerWrapper = styled(
  SelectButtonDividerWrapper,
)<{ isFinancialTerms: boolean; isPossibleSelected?: boolean }>`
  ${({ isFinancialTerms }) =>
    isFinancialTerms &&
    css`
      display: none;
    `}
  ${({ isPossibleSelected }) =>
    !isPossibleSelected &&
    css`
      display: none;
    `}
`;
export const TitleWrapper = styled(GroupShellTitleWrapper)``;

export const DraggableSubGroupShellWrapper = styled(
  DefaultDraggableGroupShellWrapper,
)``;
