import {
  CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS,
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  LICENSE_AGREEMENT_SHELL_KEYS,
} from "@frontend/common";

import { checkIsFinancialTermsGroup } from "./checkIsFinancialTermsGroup";

export const checkClearStrategicCollaborationTable = (info: {
  groupShellKey: string | null;
  subGroupShellKey: string | null;
  shellKey: string | null;
}): boolean => {
  const hasFinancialTerms =
    checkIsFinancialTermsGroup(info.groupShellKey) ||
    info.groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.otherFinancialTerms;

  const hasSpecialSubGroups =
    info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone ||
    info.subGroupShellKey ===
      CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail;

  const isLicenseeResponsibilityShell =
    info.shellKey ===
    LICENSE_AGREEMENT_SHELL_KEYS.licenseeResponsibilityRightsScLa;

  return (
    (hasFinancialTerms && !hasSpecialSubGroups) || isLicenseeResponsibilityShell
  );
};
