import { useState } from "react";

import { ProcessStatusCode } from "@/types/code.types";
import Modal from "@atoms/Modal/Modal";
import IconAlert from "@images/pressList/statusModal/icon-alert-red.svg";
import IconClose from "@images/pressList/statusModal/icon-close.svg";
import { DRAFT_STATUS_INFO } from "@PressListContainer/draftStatusInfo.constants";
import { PressListData } from "@PressListContainer/pressList.types";

import * as S from "./PressListCheckStatusGuide.style";
import { useMovePressDetailPage } from "../../hooks/useMovePressDetailPage";

interface Props {
  pressListData: PressListData[];
  draftArticleId: number;
  closeModal: () => void;
  updatePressList: () => void;
  openAccessDeniedModal: () => void;
  openConcurrentAccessDeniedModal: () => void;
  currentStatus: ProcessStatusCode | "";
}

const PressListCheckStatusGuide = ({
  pressListData,
  closeModal,
  draftArticleId,
  updatePressList,
  openAccessDeniedModal,
  openConcurrentAccessDeniedModal,
  currentStatus,
}: Props) => {
  const pressData = pressListData.filter(
    (press) => press.pressId === draftArticleId,
  )?.[0];

  const [initPressStatus] = useState(pressData?.draftArticleStatusHighest);

  const { movePressDetailPage } = useMovePressDetailPage({
    draftArticleId: pressData.pressId.toString(),
    draftArticleStatus: pressData.draftArticleStatusHighest,
    classificationAi: pressData.classificationAi,
    classificationManual: pressData.classificationManual,
    ftEditorName: pressData.ftEditorName,
    onAccessDenied: openAccessDeniedModal,
    onConcurrentAccessDenied: openConcurrentAccessDeniedModal,
  });

  const handleCancelEvent = () => {
    updatePressList();
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <S.ModalContentsWrapper>
        <S.ModalTitle>
          <img src={IconAlert} alt="" />
          Status Change Notification
        </S.ModalTitle>
        <S.ModalText>
          The status of the selected Press has been changed from ‘
          <strong>
            {initPressStatus && DRAFT_STATUS_INFO[initPressStatus].text}
          </strong>
          ’ to ‘
          <strong>
            {currentStatus && DRAFT_STATUS_INFO[currentStatus].text}
          </strong>
          ’. Would you like to continue?
        </S.ModalText>
        <S.ModalButtonWrapper>
          <S.CancelButton type="button" onClick={handleCancelEvent}>
            Back to Press List
          </S.CancelButton>
          <S.RefreshButton type="button" onClick={movePressDetailPage}>
            Continue
          </S.RefreshButton>
          <S.CloseButton type="button" onClick={handleCancelEvent}>
            <img src={IconClose} alt="Close" />
          </S.CloseButton>
        </S.ModalButtonWrapper>
      </S.ModalContentsWrapper>
    </Modal>
  );
};

export default PressListCheckStatusGuide;
