import React from "react";

import ArticleDetailTitleInShell from "@frontend/common/src/business/ArticleDetail/components/ArticleDetailTitleInShell/ArticleDetailTitleInShell";

import EditableTextContainer from "../components/EditableTextContainer/EditableTextContainer";
import { Label } from "../components/EditableTextContainer/types/editableText.types";

const COMMON_EDITABLE_TEXT_STYLE = {
  padding: "0.8rem",
  borderRadius: "0",
};

export const createEditArticleShellList = (
  items: (
    | {
        title: string;
        text: string;
        labels: Label[];
        refKey: string;
        imagePosition?: "left" | "bottom";
        imageNodeList?: React.ReactNode[];
        isDisabled?: boolean;
        displayForTitle?: boolean;
        titleInShell?: boolean;
      }
    | {
        title: string;
        text: string;
        labels: Label[];
        refKey: string;
        imagePosition?: "left" | "bottom";
        imageNodeList?: React.ReactNode[];
        isDisabled?: boolean;
        displayForTitle?: boolean;
        titleInShell?: boolean;
      }[]
  )[],
) => {
  return items
    .map((item) => {
      if (!Array.isArray(item) && !item.text) return null;
      if (!Array.isArray(item) && item.displayForTitle === true) {
        return {
          title: item.title,
          value: null,
        };
      }
      if (!Array.isArray(item))
        return {
          title: item.titleInShell ? "" : item.title,
          value: (
            <>
              {item.titleInShell && (
                <ArticleDetailTitleInShell className="editable-title-in-shell">
                  {item.title}
                </ArticleDetailTitleInShell>
              )}
              <EditableTextContainer
                item={{
                  text: item.text,
                  labels: item.labels,
                }}
                refKey={item.refKey ?? ""}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
                imagePosition={item.imagePosition || undefined}
                imageNodeList={item.imageNodeList || []}
                isDisabled={item.isDisabled}
              />
            </>
          ),
        };

      return item.map((subItem) => {
        if (subItem.displayForTitle === true) {
          return {
            title: subItem.title,
            value: null,
          };
        }
        return {
          title: subItem.titleInShell ? "" : subItem.title,
          value: (
            <>
              {subItem.titleInShell && (
                <ArticleDetailTitleInShell className="editable-title-in-shell">
                  {subItem.title}
                </ArticleDetailTitleInShell>
              )}
              <EditableTextContainer
                item={{
                  text: subItem.text,
                  labels: subItem.labels,
                }}
                refKey={subItem.refKey ?? ""}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
                imagePosition={subItem.imagePosition || undefined}
                imageNodeList={subItem.imageNodeList || []}
                isDisabled={subItem.isDisabled}
              />
            </>
          ),
        };
      });
    })
    .filter((item) => !!item) as (
    | {
        title: string;
        value: React.ReactNode;
      }[]
    | {
        title: string;
        value: React.ReactNode;
      }
  )[];
};

export const createEditArticleCrossLicenseShellList = (
  items: (
    | {
        title: string;
        text: string;
        labels: Label[];
        refKey: string;
        imagePosition?: "left" | "bottom";
        imageNodeList?: React.ReactNode[];
        isDisabled?: boolean;
        displayForTitle?: boolean;
        titleInShell?: boolean;
      }
    | {
        title: string;
        text: string;
        labels: Label[];
        refKey: string;
        imagePosition?: "left" | "bottom";
        imageNodeList?: React.ReactNode[];
        isDisabled?: boolean;
        displayForTitle?: boolean;
        titleInShell?: boolean;
      }[]
  )[],
) => {
  return items
    .map((item) => {
      if (!Array.isArray(item) && item.displayForTitle === true) {
        return {
          title: item.title,
          value: null,
        };
      }
      if (!Array.isArray(item))
        return {
          title: item.titleInShell ? "" : item.title,
          value: (
            <>
              {item.titleInShell && (
                <ArticleDetailTitleInShell className="editable-title-in-shell">
                  {item.title}
                </ArticleDetailTitleInShell>
              )}
              <EditableTextContainer
                item={{
                  text: item.text,
                  labels: item.labels,
                }}
                refKey={item.refKey ?? ""}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
                imagePosition={item.imagePosition || undefined}
                imageNodeList={item.imageNodeList || []}
                isDisabled={item.isDisabled}
              />
            </>
          ),
        };

      return item.map((subItem) => {
        if (subItem.displayForTitle === true) {
          return {
            title: subItem.title,
            value: null,
          };
        }
        return {
          title: subItem.titleInShell ? "" : subItem.title,
          value: (
            <>
              {subItem.titleInShell && (
                <ArticleDetailTitleInShell className="editable-title-in-shell">
                  {subItem.title}
                </ArticleDetailTitleInShell>
              )}
              <EditableTextContainer
                item={{
                  text: subItem.text,
                  labels: subItem.labels,
                }}
                refKey={subItem.refKey ?? ""}
                canTextEdit
                style={COMMON_EDITABLE_TEXT_STYLE}
                imagePosition={subItem.imagePosition || undefined}
                imageNodeList={subItem.imageNodeList || []}
                isDisabled={subItem.isDisabled}
              />
            </>
          ),
        };
      });
    })
    .filter((item) => !!item) as (
    | {
        title: string;
        value: React.ReactNode;
      }[]
    | {
        title: string;
        value: React.ReactNode;
      }
  )[];
};

export const createEditArticleSingleTableShellList = (
  items: {
    title: string;
    text: string;
    labels: Label[];
    refKey: string;
    imagePosition?: "left" | "bottom";
    imageNodeList?: React.ReactNode[];
    isDisabled?: boolean;
    titleInShell?: boolean;
  }[],
) => {
  return items
    .map((item) => {
      if (!item.text) return null;

      return {
        title: item.titleInShell ? "" : item.title,
        value: (
          <>
            {item.titleInShell && (
              <ArticleDetailTitleInShell className="editable-title-in-shell">
                {item.title}
              </ArticleDetailTitleInShell>
            )}
            <EditableTextContainer
              item={{
                text: item.text,
                labels: item.labels,
              }}
              refKey={item.refKey ?? ""}
              canTextEdit
              style={COMMON_EDITABLE_TEXT_STYLE}
              imagePosition={item.imagePosition || undefined}
              imageNodeList={item.imageNodeList || []}
              isDisabled={item.isDisabled}
            />
          </>
        ),
      };
    })
    .filter((item) => !!item) as {
    title: string;
    value: React.ReactNode;
  }[];
};
