import { usePressListFilterQuery } from "@/queries/hooks/usePressListFilter";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import { CLASSIFICATION_GROUP } from "@PressListContainer/classificationInfo.constants";
import { formatDate } from "@utils/formatDate";
import { handleDraftError } from "@utils/handleDraftError";

import PressListCompanyShell from "./components/PressListCompanyShell/PressListCompanyShell";
import PressListPressTitleContainer from "./components/PressListPressTitleContainer/PressListPressTitleContainer";
import { PressListPublishableContainer } from "./components/PressListPublishableContainer/PressListPublishableContainer";
import PressListStatusContainer from "./components/PressListStatusContainer/PressListStatusContainer";
import PressListUnsuitableContainer from "./components/PressListUnsuitableContainer/PressListUnsuitableContainer";
import * as S from "./PressListTableBody.style";
import { EMPTY_SHELL_VALUE } from "../../constants/pressListTable.constants";

const EDITOR_LIST = [
  "mcEditorName",
  "ftEditorName",
  "ftEditor2Name",
  "pbEditorName",
];

const WRITE_DATE_LIST = ["writeStartDt", "writeEndDt"];

const PressListTableBody = () => {
  const { data: pressListData, refetch: updatePressList } =
    usePressListFilterQuery();

  return (
    <S.PressListTableBody>
      {pressListData?.data.map((pressListItem) => (
        <S.PressListTableTr
          key={`row-${pressListItem.pressId}`}
          isError={handleDraftError(pressListItem)[0]}
        >
          <S.PressListTablePressId>
            #{pressListItem.pressId}
          </S.PressListTablePressId>
          <S.PressListTableDate>{pressListItem.pressDate}</S.PressListTableDate>
          <S.PressListTableCompany>
            <PressListCompanyShell
              companyName={pressListItem.companyName}
              targetGroup={pressListItem.targetGroup}
            />
          </S.PressListTableCompany>

          <S.PressListTablePublishable>
            <PressListPublishableContainer
              data={{
                ...pressListItem,
                publishable: pressListItem.publishable ?? false,
                aiDone: pressListItem.aiDone ?? false,
              }}
            />
          </S.PressListTablePublishable>

          <S.PressListTableUnsuitable
            isUnsuitable={
              pressListItem.draftArticleStatusHighest ===
              DRAFT_STATUS_CODE.unsuitable
            }
          >
            <PressListUnsuitableContainer {...pressListItem} />
          </S.PressListTableUnsuitable>

          <PressListPressTitleContainer
            pressListItem={pressListItem}
            updatePressList={updatePressList}
            pressListData={pressListData?.data || []}
          />

          <S.PressListTableClassification>
            {CLASSIFICATION_GROUP[pressListItem.classificationAi] ||
              EMPTY_SHELL_VALUE}
          </S.PressListTableClassification>

          <S.ClassificationTableData status={pressListItem.draftArticleStatus}>
            {CLASSIFICATION_GROUP[pressListItem.classificationManual] ||
              EMPTY_SHELL_VALUE}
          </S.ClassificationTableData>

          <S.StatusTableData
            key={pressListItem.pressId}
            code={pressListItem.draftArticleStatusHighest}
          >
            <PressListStatusContainer data={pressListItem} />
          </S.StatusTableData>

          {WRITE_DATE_LIST.map((dateKey) => (
            <S.PressListTableDate key={dateKey}>
              {pressListItem[dateKey as keyof typeof pressListItem]
                ? formatDate(
                    pressListItem[
                      dateKey as keyof typeof pressListItem
                    ] as string,
                    "YY.MM.DD HH:mm:ss",
                  )
                : EMPTY_SHELL_VALUE}
            </S.PressListTableDate>
          ))}

          {EDITOR_LIST.map((editor) => (
            <S.PressListTableEditor key={editor}>
              {pressListItem[editor as keyof typeof pressListItem] ??
                EMPTY_SHELL_VALUE}
            </S.PressListTableEditor>
          ))}
        </S.PressListTableTr>
      ))}
    </S.PressListTableBody>
  );
};

export default PressListTableBody;
