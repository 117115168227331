import { useState } from "react";

import { ProcessStatusCode } from "@/types/code.types";
import { useUnsuitablePressCancelMutation } from "@api/unsuitablePress/unsuitablePress";
import { DRAFT_STATUS_CODE } from "@constants/draftStatusCode.constants";
import {
  checkSuperAdmin,
  useAuthorityType,
} from "@libs/roles/hooks/useAuthorityType";
import { PressListData } from "@PressListContainer/pressList.types";

import UnsuitableCheckboxIcon from "./components/UnsuitableCheckboxIcon/UnsuitableCheckboxIcon";

const PressListUnsuitableContainer = (data: PressListData) => {
  const [targetUnsuitablePressId, setTargetUnsuitablePressId] =
    useState<number>(0);
  const authorityType = useAuthorityType();
  const isSuperAdmin = checkSuperAdmin(authorityType);
  const { mutateAsync: turnOffUnsuitable } = useUnsuitablePressCancelMutation();

  const checkDisabledUnsuitablePressByRoles = () => {
    if (isSuperAdmin) return false;
    return true;
  };

  const checkDisabledUnsuitablePressByStatus = (status: ProcessStatusCode) => {
    if (status !== DRAFT_STATUS_CODE.published) return false;
    return true;
  };

  const clickUnsuitableCheckbox = (
    pressId: number,
    status: ProcessStatusCode,
  ) => {
    if (status === DRAFT_STATUS_CODE.unsuitable) {
      turnOffUnsuitable(pressId);
    }
    if (status !== DRAFT_STATUS_CODE.unsuitable) {
      setTargetUnsuitablePressId(pressId);
    }
  };

  return (
    <>
      {data?.articleRejectReason ? (
        <>
          {data?.articleRejectReason}
          {data?.duplArticleId && `\n(${data?.duplArticleId})`}
        </>
      ) : (
        "-"
      )}
      <UnsuitableCheckboxIcon
        isChecked={data?.draftArticleStatus === "S930" || false}
        onClick={() =>
          clickUnsuitableCheckbox(data?.pressId, data?.draftArticleStatus)
        }
        disabled={
          checkDisabledUnsuitablePressByRoles() === true ||
          checkDisabledUnsuitablePressByStatus(
            data?.draftArticleStatusHighest,
          ) === true
        }
        draftArticleId={data?.pressId}
        isOpenModalUnsuitablePress={
          targetUnsuitablePressId === data?.pressId &&
          !data?.articleRejectReason
        }
        articleRejectReason={data?.articleRejectReason}
        closeModalUnsuitablePress={() => setTargetUnsuitablePressId(0)}
        mcEditorName={data?.mcEditorName}
        aiDone={data?.aiDone || false}
      />
    </>
  );
};

export default PressListUnsuitableContainer;
