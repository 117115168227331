import React, { useEffect, useRef } from "react";

import {
  CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS,
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
  LICENSE_AGREEMENT_GROUP_SHELL_KEYS,
  LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS,
} from "@frontend/common";

import { DealsSection } from "@/types/deals.types";
import useArticleSection from "@FillInTableContainer/hooks/useArticleSection";
import { TableCreatorGroupShellTitle } from "@FillInTableContainer/shared";
import TableDragDivider from "@FillInTableContainer/shared/components/TableDragDivider/TableDragDivider";
import { useHandleTableCreatorSubGroupShellDrag } from "@FillInTableContainer/shared/hooks/useHandleTableCreatorSubGroupShellDrag";
import { TableCreatorSubGroupShellList } from "@FillInTableContainer/shared/types/tableCreator.types";
import { isAddDeleteGroupShell } from "@FillInTableContainer/shared/utils/getOptionsFromData";
import {
  getAddedGroupShellTitle,
  getGroupShellTitle,
} from "@FillInTableContainer/shared/utils/getTextFromData";
import IconXMark from "@images/fillInTable/tableCreator/icon-x-mark-white.svg";
import { newArrayToCountValue } from "@utils/newArrayToCountValue";

import DealTermsCrossLicenseSubGroupShell from "./components/DealTermsCrossLicenseSubGroupShell/DealTermsCrossLicenseSubGroupShell";
import * as S from "./DealTermsCrossLicenseGroupShell.style";
import useCheckIsDisplaySubGroupShellDragDivider from "./hooks/useCheckIsDisplaySubGroupShellDragDivider";
import { useCreateCrossLicenseGroupShell } from "./hooks/useCreateCrossLicenseGroupShell";
import { useGetNumberOfSupGroupShell } from "./hooks/useGetNumberOfSubGroupShell";

interface Props {
  groupTableKey: DealsSection;
  groupTableIndex: number;
  groupShellKey: string | null;
  subGroupShellList: TableCreatorSubGroupShellList;
  tableKey: string | null;
  tableIndex: number;
  groupShellIndex: number;
}

const isNotFocusGroupShell: string[] = [
  CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScopePartnerA,
  CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScopePartnerB,
  CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTermsPartnerA,
  CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTermsPartnerB,
];
const dragSubGroupShellList: string[] = [
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.milestone,
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail,
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerA,
  CROSS_LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.rightsScopePartnerB,
];
const ARTICLE_SECTION = "Deals";

const DealTermsCrossLicenseGroupShell = ({
  groupShellIndex,
  groupShellKey,
  groupTableIndex,
  groupTableKey,
  subGroupShellList,
  tableIndex,
  tableKey,
}: Props) => {
  const positionPropsInfo = {
    groupTableKey,
    groupTableIndex,
    groupShellKey,
    groupShellIndex,
    tableKey,
    tableIndex,
  };
  const { subGroupShellCount } = useGetNumberOfSupGroupShell();
  const articleSection = useArticleSection();
  const selectButtonRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const {
    focusStatus,
    currentInfoStatus,
    isFocused,
    isClearGroupShell,
    isDeleteGroupShell,
    isAlertGroupShell,
    isEnableEdit,
    handleFocusGroupShell,
    handleClickToDeleteGroupShell,
    handleDefaultFocus,
  } = useCreateCrossLicenseGroupShell({
    positionPropsInfo,
  });

  const {
    handleSubGroupShellDragEnd,
    handleSubGroupShellDragOn,
    handleSubGroupShellDragOver,
    handleSubGroupShellDragStart,
    subGroupShellDraggedIndexInfo,
    isDraggingSubGroupShell,
  } = useHandleTableCreatorSubGroupShellDrag(ARTICLE_SECTION, tableIndex);

  const { isLastSubGroupShell, handleCheckDragDividerDisplay } =
    useCheckIsDisplaySubGroupShellDragDivider({
      tableIndex,
      groupShellIndex,
      isDraggingSubGroupShell,
    });

  useEffect(() => {
    const handleCheckClickOutsideShell = (e: MouseEvent) => {
      if (isFocused) {
        const target = e.target as HTMLElement;
        const clickSelectButton = selectButtonRef.current?.contains(target);
        const clickGroupShellTitle = titleRef.current?.contains(target);

        if (clickSelectButton || clickGroupShellTitle) return;
        if (target.dataset.button) return;

        handleDefaultFocus();
      }
    };

    window.addEventListener("click", handleCheckClickOutsideShell);

    return () =>
      window.removeEventListener("click", handleCheckClickOutsideShell);
  }, [handleDefaultFocus, isDeleteGroupShell, isFocused]);

  const isPossibleSelected = groupShellKey
    ? !isNotFocusGroupShell.includes(groupShellKey)
    : true;

  const checkIsDisplayDragDividerSubGroupShell = (
    subGroupShellKey: string | null,
  ) => {
    if (!subGroupShellKey) return false;

    return dragSubGroupShellList.includes(subGroupShellKey || "");
  };

  const isRightScopeGroupShell =
    groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScopePartnerA ||
    groupShellKey ===
      CROSS_LICENSE_AGREEMENT_GROUP_SHELL_KEYS.rightsScopePartnerB;

  return (
    <>
      <S.GroupShellOutsideWrapper>
        {(isClearGroupShell || isDeleteGroupShell) && isEnableEdit && (
          <S.FixedDeleteButtonWrapper
            isShellTitle={!!getGroupShellTitle(articleSection, groupShellKey)}
          >
            <S.DeleteTableButtonWrapper>
              <S.DeleteTableButton
                data-button="delete-button"
                onClick={handleClickToDeleteGroupShell}
              >
                <img src={IconXMark} alt="button for clear Row" />
                {focusStatus.type === "clear" ? `Clear` : null}
              </S.DeleteTableButton>
            </S.DeleteTableButtonWrapper>
          </S.FixedDeleteButtonWrapper>
        )}

        <S.GroupShellWrapper
          isFocused={isFocused}
          isClearGroupShell={isClearGroupShell}
          isDeleteGroupShell={isDeleteGroupShell}
          isAlertGroupShell={isAlertGroupShell}
        >
          <S.TitleWrapper ref={titleRef}>
            {getAddedGroupShellTitle(articleSection, groupShellKey) && (
              <TableCreatorGroupShellTitle
                groupShellKey={groupShellKey}
                groupShellCount={0}
                groupShellIndex={0}
                isAlertGroupShell={isAlertGroupShell}
                onClickHandler={handleFocusGroupShell}
                groupTableKey={groupTableKey}
              />
            )}
          </S.TitleWrapper>
          <S.SelectWrapper
            $isAddedGroupShell={isAddDeleteGroupShell(
              articleSection,
              groupShellKey,
            )}
          >
            <S.GroupShellSelectButtonDividerWrapper
              ref={selectButtonRef}
              onClick={handleFocusGroupShell}
              isPossibleSelected={isPossibleSelected}
              isFinancialTerms={
                groupShellKey ===
                LICENSE_AGREEMENT_GROUP_SHELL_KEYS.financialTerms
              }
            >
              <S.GroupShellSelectButtonDivider className="select-button divider" />
              <S.GroupShellSelectButton className="select-button button">
                SELECT
              </S.GroupShellSelectButton>
            </S.GroupShellSelectButtonDividerWrapper>
            <S.GroupShell>
              {subGroupShellList.map(
                ({ subGroupShellKey, shellList }, shellListIndex) => (
                  <React.Fragment key={`shellList-${String(shellListIndex)}`}>
                    {subGroupShellKey ===
                      LICENSE_AGREEMENT_SUP_GROUP_SHELL_KEYS.otherPaymentAndDetail && (
                      <S.SubGroupShellDivider />
                    )}
                    {newArrayToCountValue(
                      subGroupShellCount(
                        subGroupShellKey,
                        tableIndex,
                        groupShellIndex,
                      ),
                    ).map((_, subGroupShellIndex) => (
                      <S.DraggableSubGroupShellWrapper
                        key={`shellWrapper-${String(groupTableIndex)}-${String(
                          tableIndex,
                        )}-${String(groupShellIndex)}-${String(
                          subGroupShellIndex,
                        )}`}
                        isTargetDraggingTable={
                          subGroupShellKey ===
                            currentInfoStatus.subGroupShellKey &&
                          isDraggingSubGroupShell &&
                          subGroupShellDraggedIndexInfo[0] !==
                            subGroupShellIndex &&
                          checkIsDisplayDragDividerSubGroupShell(
                            subGroupShellKey,
                          )
                        }
                        onDragOver={(e) =>
                          handleSubGroupShellDragOver(e, subGroupShellIndex)
                        }
                      >
                        {checkIsDisplayDragDividerSubGroupShell(
                          subGroupShellKey,
                        ) && (
                          <TableDragDivider
                            isDraggingEndPosition={
                              subGroupShellKey ===
                                currentInfoStatus.subGroupShellKey &&
                              handleCheckDragDividerDisplay({
                                moveDragIndex: subGroupShellDraggedIndexInfo[1],
                                startDragIndex:
                                  subGroupShellDraggedIndexInfo[0],
                                subGroupShellIndex,
                                subGroupShellKey,
                              })
                            }
                          />
                        )}
                        <DealTermsCrossLicenseSubGroupShell
                          key={`shell-${String(groupTableIndex)}-${String(
                            tableIndex,
                          )}-${String(groupShellIndex)}-${String(
                            subGroupShellIndex,
                          )}`}
                          groupTableKey={groupTableKey}
                          groupTableIndex={groupTableIndex}
                          groupShellKey={groupShellKey}
                          groupShellIndex={
                            isRightScopeGroupShell
                              ? subGroupShellIndex
                              : groupShellIndex
                          }
                          subGroupShellKey={subGroupShellKey}
                          shellList={shellList}
                          subGroupShellIndex={subGroupShellIndex}
                          tableKey={tableKey}
                          tableIndex={tableIndex}
                          subGroupShellCount={subGroupShellCount(
                            subGroupShellKey,
                            tableIndex,
                            groupShellIndex,
                          )}
                          isFocusedGroupShell={isFocused}
                          isClearGroupShell={isClearGroupShell}
                          isDeleteGroupShell={isDeleteGroupShell}
                          isAlertGroupShell={isAlertGroupShell}
                          handleDragEnd={handleSubGroupShellDragEnd}
                          handleDragOn={handleSubGroupShellDragOn}
                          handleDragStart={handleSubGroupShellDragStart}
                        />
                        {checkIsDisplayDragDividerSubGroupShell(
                          subGroupShellKey,
                        ) &&
                          isLastSubGroupShell(
                            subGroupShellIndex,
                            subGroupShellKey,
                          ) && (
                            <TableDragDivider
                              isDraggingEndPosition={
                                subGroupShellKey ===
                                  currentInfoStatus.subGroupShellKey &&
                                handleCheckDragDividerDisplay({
                                  moveDragIndex:
                                    subGroupShellDraggedIndexInfo[1],
                                  startDragIndex:
                                    subGroupShellDraggedIndexInfo[0],
                                  subGroupShellIndex,
                                  subGroupShellKey,
                                  lastIndex: newArrayToCountValue(
                                    subGroupShellCount(
                                      subGroupShellKey,
                                      tableIndex,
                                      groupShellIndex,
                                    ),
                                  ).length,
                                })
                              }
                            />
                          )}
                      </S.DraggableSubGroupShellWrapper>
                    ))}
                  </React.Fragment>
                ),
              )}
            </S.GroupShell>
          </S.SelectWrapper>
        </S.GroupShellWrapper>
      </S.GroupShellOutsideWrapper>
    </>
  );
};

export default DealTermsCrossLicenseGroupShell;
