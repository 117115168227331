import React, { useCallback, useEffect, useRef } from "react";

import { DealsSection } from "@/types/deals.types";
import { useArticleInfoQuery } from "@api/articleInfo/useArticleInfo";
import { KEY_DEALS_TABLE } from "@FillInTableContainer/shared/constants/dealsKey.constants";
import { ENABLE_GROUP_TABLE_KEY_LIST } from "@FillInTableContainer/shared/constants/tableCreatorGroupTable.constants";
import {
  DEFAULT_FOCUS_STATUS,
  FocusStatus,
  useTableCreatorFocusStatus,
} from "@FillInTableContainer/shared/context/TableCreatorFocusStatusContext";
import {
  DEFAULT_POSITION_INFO,
  PositionInfo,
  useTableCreatorPositionInfo,
} from "@FillInTableContainer/shared/context/TableCreatorPositionInfoContext";
import {
  useValidatedInTableCreator,
  DEFAULT_VALIDATED_INFO,
} from "@FillInTableContainer/shared/context/ValidatedInTableCreatorContext";
import { getPressIdFromURL } from "@utils/getStateFromURL";

import { useClearCrossLicenseTable } from "./useClearCrossLicenseTable";

export interface TableInfoProps {
  groupTableKey: DealsSection;
  tableKey: string | null;
  groupTableIndex: number;
  tableIndex: number;
  tableCount: number;
}

interface Returns {
  info: PositionInfo;
  focusStatus: FocusStatus;
  isFocused: boolean;
  isLastTable: boolean;
  tableWrapperRef: React.RefObject<HTMLDivElement>;
  isEnableEdit: boolean;
  isFocusedAddButton: boolean;
  focusEventToChangePositionInfo: (focusEnterType: FocusStatus) => void;
  handleClickDeleteButton: () => void;
  changePositionInfo: () => void;
  changePositionInfoToDefault: () => void;
  changeFocusStatus: (focusStatus: FocusStatus) => void;
  handleChangeDefaultFocus: () => void;
}

export const useCreateCrossLicenseTable = (
  tablePropsInfo: TableInfoProps,
): Returns => {
  const { groupTableKey, tableKey, groupTableIndex, tableIndex, tableCount } =
    tablePropsInfo;

  const articleSection = "Deals";

  const { info, changeInfo } = useTableCreatorPositionInfo();
  const { focusStatus, changeFocusStatus } = useTableCreatorFocusStatus();
  const { changeValidatedInfo } = useValidatedInTableCreator();
  const { data: articleInfo } = useArticleInfoQuery(
    Number(getPressIdFromURL()),
  );

  const {
    handleClickLicenseeTableClearButton,
    handleKeyDownToClearLicenseeTable,
  } = useClearCrossLicenseTable(tablePropsInfo);

  const isEnableEdit = articleInfo
    ? ENABLE_GROUP_TABLE_KEY_LIST[articleSection][groupTableKey].includes(
        articleInfo.draftArticleStatus,
      )
    : false;

  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const isLastTable = tableIndex === tableCount - 1;

  const isFocused =
    info.groupTableKey === groupTableKey &&
    info.tableKey === tableKey &&
    !info.groupShellSectionKey &&
    info.groupShellKey === null &&
    info.groupShellKey === null &&
    info.groupShellIndex === null &&
    info.subGroupShellIndex === null &&
    info.subGroupShellKey === null &&
    info.shellKey === null &&
    info.shellIndex === null &&
    info.valueIndex === null &&
    isEnableEdit;

  const isFocusedAddButton =
    focusStatus.container === "noFocus" && isEnableEdit;

  const changePositionInfo = () => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellSectionIndex: null,
      groupShellSectionKey: null,
      groupShellKey: null,
      groupShellIndex: null,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
  };

  const changePositionInfoToDefault = () => {
    changeInfo(DEFAULT_POSITION_INFO);
  };

  const handleChangeDefaultFocus = () => {
    changeInfo(DEFAULT_POSITION_INFO);
    changeFocusStatus(DEFAULT_FOCUS_STATUS);
  };

  const focusEventToChangePositionInfo = (focusEnterType: FocusStatus) => {
    changeInfo({
      groupTableKey,
      groupTableIndex,
      tableKey,
      tableIndex,
      groupShellKey: null,
      groupShellIndex: null,
      subGroupShellIndex: null,
      subGroupShellKey: null,
      shellKey: null,
      shellIndex: null,
      valueIndex: null,
    });
    changeFocusStatus({ type: "clear", container: "table" });

    if (focusEnterType.type === "clear") {
      changeFocusStatus({ type: "clear", container: "table" });
    }
    changeValidatedInfo(DEFAULT_VALIDATED_INFO);
  };

  const handleClickDeleteButton = () => {
    handleClickLicenseeTableClearButton();
  };

  const handleKeyDownToDeleteTable = useCallback(
    (event: KeyboardEvent) => {
      if (info.groupTableKey === KEY_DEALS_TABLE.licenseeTable)
        handleKeyDownToClearLicenseeTable(event);
    },
    [handleKeyDownToClearLicenseeTable, info.groupTableKey],
  );

  useEffect(() => {
    if (focusStatus.container !== "table") return;
    window.addEventListener("keydown", handleKeyDownToDeleteTable);

    return () => {
      window.removeEventListener("keydown", handleKeyDownToDeleteTable);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusStatus]);

  return {
    tableWrapperRef,
    info,
    focusStatus,
    isFocused,
    isLastTable,
    isEnableEdit,
    isFocusedAddButton,
    focusEventToChangePositionInfo,
    handleClickDeleteButton,
    changePositionInfo,
    changeFocusStatus,
    changePositionInfoToDefault,
    handleChangeDefaultFocus,
  };
};
