import { ParagraphTitle } from "@frontend/common/src/components/ArticleContent";

import { useParagraphEditedData } from "@EditArticleContainer/shared/hooks/useParagraph";

import DrugProfileTableGroup from "./components/DrugProfileTableGroup/DrugProfileTableGroup";
import { checkDrugProfileValue } from "./components/DrugProfileTableGroup/utils/checkDrugProfileValue";
import * as S from "./RegulatoryParagraphDrugProfile.style";
import { checkAllDrugProfileTableFieldsEmpty } from "../../utils/checkAllDrugProfileTableFieldsEmpty";

const PARAGRAPH_TITLE = "Drug Profile";
const PARAGRAPH_CODE = "TC203";

const RegulatoryParagraphDrugProfile = () => {
  const paragraph = useParagraphEditedData(PARAGRAPH_CODE);
  const isCheckValue = checkDrugProfileValue(paragraph);

  if (!paragraph) return null;
  if (!isCheckValue) return null;

  const isAllDrugProfileTableEmpty =
    checkAllDrugProfileTableFieldsEmpty(paragraph);

  const isHideDrugProfileSection = !!(isAllDrugProfileTableEmpty === true);

  return (
    <S.ParagraphDrugProfileWrapper
      className={`is-hide-drug-profile-table-${isHideDrugProfileSection}`}
    >
      <ParagraphTitle title={PARAGRAPH_TITLE} />
      <S.ParagraphDrugProfileContentWrapper>
        <DrugProfileTableGroup
          key={paragraph.updateDt}
          drugProfile={paragraph}
        />
      </S.ParagraphDrugProfileContentWrapper>
    </S.ParagraphDrugProfileWrapper>
  );
};

export default RegulatoryParagraphDrugProfile;
