export const MIN_GROUP_SIZE = 1000000;
export const MIN_ITEM_SIZE = 70000;
export const INIT_GROUP_SIZE = 160000;
export const TREE_MAP_RADIUS = 8;

export const GROUP_TOOLTIP_BACKGROUND = {
  MODALITY: {
    other: "#EAD6FA",
  },
  THERAPEUTIC_AREA: {
    other: "#FDE7F2",
  },
  PHASE: {
    other: "#FFFAA3",
  },
  TERRITORY_BUYER: {
    asia: "#F1E7DD",
    "europe/russia/cis": "#D0D4DF",
    "north america": "#CCD6D2",
    "oceania/africa": "#ECD8DD",
    "south america": "#DBE7D6",
    other: "#CBCED3",
  },
  TERRITORY_SELLER: {
    asia: "#F1E7DD",
    "europe/russia/cis": "#D0D4DF",
    "north america": "#CCD6D2",
    "oceania/africa": "#ECD8DD",
    "south america": "#DBE7D6",
    other: "#CBCED3",
  },
  BIG_PHARMA: {
    bms: "#EBBFEA",
    abbvie: "#B4BBC8",
    novartis: "#F9E1BB",
    roche: "#B2D6EC",
    astrazeneca: "#FAE6B2",
    msd: "#B2DAD8",
    lilly: "#F2BFBB",
    vertex: "#C5B8CF",
    "boehringer ingelheim": "#BAC2D9",
    gsk: "#FBCBBA",
    sanofi: "#D7B2F7",
    "merck kgaa": "#B7D2E7",
    "j&j": "#F9B9B3",
    takeda: "#FABBBD",
    "novo nordisk": "#B2BAD1",
    biogen: "#BDD5EB",
    otsuka: "#C7D3EB",
    pfizer: "#B2B2EF",
    bayer: "#DCF2BF",
    regeneron: "#B2CEE2",
    chugai: "#B5CAE3",
    amgen: "#BCD0E4",
    gilead: "#EEB5C4",
    "daiichi sankyo": "#B4C9E0",
    other: "#fff",
  },
};

export const GROUP_TOOLTIP_BORDER_COLOR = {
  MODALITY: {
    other: "#EAD6FA",
  },
  THERAPEUTIC_AREA: {
    other: "#FDE7F2",
  },
  PHASE: {
    other: "#FFFAA3",
  },
  TERRITORY_BUYER: {
    asia: "#F1E7DD",
    "europe/russia/cis": "#D0D4DF",
    "north america": "#CCD6D2",
    "oceania/africa": "#ECD8DD",
    "south america": "#DBE7D6",
    other: "#CBCED3",
  },
  TERRITORY_SELLER: {
    asia: "#F1E7DD",
    "europe/russia/cis": "#D0D4DF",
    "north america": "#CCD6D2",
    "oceania/africa": "#ECD8DD",
    "south america": "#DBE7D6",
    other: "#CBCED3",
  },
  BIG_PHARMA: {
    bms: "#EBBFEA",
    abbvie: "#B4BBC8",
    novartis: "#F9E1BB",
    roche: "#B2D6EC",
    astrazeneca: "#FAE6B2",
    msd: "#B2DAD8",
    lilly: "#F2BFBB",
    vertex: "#C5B8CF",
    "boehringer ingelheim": "#BAC2D9",
    gsk: "#FBCBBA",
    sanofi: "#D7B2F7",
    "merck kgaa": "#B7D2E7",
    "j&j": "#F9B9B3",
    takeda: "#FABBBD",
    "novo nordisk": "#B2BAD1",
    biogen: "#BDD5EB",
    otsuka: "#C7D3EB",
    pfizer: "#B2B2EF",
    bayer: "#DCF2BF",
    regeneron: "#B2CEE2",
    chugai: "#B5CAE3",
    amgen: "#BCD0E4",
    gilead: "#EEB5C4",
    "daiichi sankyo": "#B4C9E0",
    other: "#fff",
  },
};
export const GROUP_TOOLTIP_TITLE_COLOR = {
  MODALITY: "#8219D2",
  THERAPEUTIC_AREA: "#DB0F72",
  PHASE: "#615C00",
  TERRITORY_BUYER: "#2A2F37",
  TERRITORY_SELLER: "#2A2F37",
  BIG_PHARMA: "#2A2F37",
} as const;

export const GROUP_TOOLTIP_CONTENTS_BACKGROUND = {
  MODALITY: {
    other: "#F5EBFD",
  },
  THERAPEUTIC_AREA: {
    other: "#FFF4F9",
  },
  PHASE: {
    other: "#fffdd1",
  },
  TERRITORY_BUYER: {
    asia: "#FAF7F4",
    "europe/russia/cis": "#EFF1F4",
    "north america": "#EEF1F0",
    "oceania/africa": "#F9F2F4",
    "south america": "#F3F7F1",
    other: "#EEEFF0",
  },
  TERRITORY_SELLER: {
    asia: "#FAF7F4",
    "europe/russia/cis": "#EFF1F4",
    "north america": "#EEF1F0",
    "oceania/africa": "#F9F2F4",
    "south america": "#F3F7F1",
    other: "#EEEFF0",
  },
  BIG_PHARMA: {
    bms: "#F8EAF8",
    abbvie: "#E6E8ED",
    novartis: "#FDF5E8",
    roche: "#E5F1F9",
    astrazeneca: "#FDF7E5",
    msd: "#E5F3F2",
    lilly: "#FBEAE8",
    vertex: "#ECE7EF",
    "boehringer ingelheim": "#E8EBF2",
    gsk: "#FDEEE8",
    sanofi: "#F2E5FC",
    "merck kgaa": "#E7F0F7",
    "j&j": "#FDE8E6",
    takeda: "#FDE8E9",
    "novo nordisk": "#E5E8F0",
    biogen: "#E9F1F8",
    otsuka: "#ECF0F8",
    pfizer: "#E5E5FA",
    bayer: "#F3FBEA",
    regeneron: "#E5EFF5",
    chugai: "#E6EDF6",
    amgen: "#E9EFF6",
    gilead: "#F9E6EB",
    "daiichi sankyo": "#E6EDF5",
    other: "#fff",
  },
} as const;

export const NO_FOCUS_ITEM_HOVER_BACKGROUND = {
  MODALITY: {
    other: "#faf5fe",
  },
  THERAPEUTIC_AREA: {
    other: "#FFF9FC",
  },
  PHASE: {
    other: "#fffae5",
  },
  TERRITORY_BUYER: {
    asia: "#FAF7F4",
    "europe/russia/cis": "#EFF1F4",
    "north america": "#EEF1F0",
    "oceania/africa": "#F9F2F4",
    "south america": "#F3F7F1",
    other: "#EEEFF0",
  },
  TERRITORY_SELLER: {
    asia: "#FAF7F4",
    "europe/russia/cis": "#EFF1F4",
    "north america": "#EEF1F0",
    "oceania/africa": "#F9F2F4",
    "south america": "#F3F7F1",
    other: "#EEEFF0",
  },
  BIG_PHARMA: {
    bms: "#F8EAF8",
    abbvie: "#E6E8ED",
    novartis: "#FDF5E8",
    roche: "#E5F1F9",
    astrazeneca: "#FDF7E5",
    msd: "#E5F3F2",
    lilly: "#FBEAE8",
    vertex: "#ECE7EF",
    "boehringer ingelheim": "#E8EBF2",
    gsk: "#FDEEE8",
    sanofi: "#F2E5FC",
    "merck kgaa": "#E7F0F7",
    "j&j": "#FDE8E6",
    takeda: "#FDE8E9",
    "novo nordisk": "#E5E8F0",
    biogen: "#E9F1F8",
    otsuka: "#ECF0F8",
    pfizer: "#E5E5FA",
    bayer: "#F3FBEA",
    regeneron: "#E5EFF5",
    chugai: "#E6EDF6",
    amgen: "#E9EFF6",
    gilead: "#F9E6EB",
    daiichiSankyo: "#E6EDF5",
    other: "#fff",
  },
} as const;

export const ITEM_HOVER_BACKGROUND = {
  MODALITY: {
    other: "#faf5fe",
  },
  THERAPEUTIC_AREA: {
    other: "#FFF9FC",
  },
  PHASE: {
    other: "#fffae5",
  },
  TERRITORY_BUYER: {
    asia: "#F1E7DD",
    "europe/russia/cis": "#D0D4DF",
    "north america": "#CCD6D2",
    "oceania/africa": "#ECD8DD",
    "south america": "#DBE7D6",
    other: "#CBCED3",
  },
  TERRITORY_SELLER: {
    asia: "#F1E7DD",
    "europe/russia/cis": "#D0D4DF",
    "north america": "#CCD6D2",
    "oceania/africa": "#ECD8DD",
    "south america": "#DBE7D6",
    other: "#CBCED3",
  },
  BIG_PHARMA: {
    bms: "#EBBFEA",
    abbvie: "#B4BBC8",
    novartis: "#F9E1BB",
    roche: "#B2D6EC",
    astrazeneca: "#FAE6B2",
    msd: "#B2DAD8",
    lilly: "#F2BFBB",
    vertex: "#C5B8CF",
    "boehringer ingelheim": "#BAC2D9",
    gsk: "#FBCBBA",
    sanofi: "#D7B2F7",
    "merck kgaa": "#B7D2E7",
    "j&j": "#F9B9B3",
    takeda: "#FABBBD",
    "novo nordisk": "#B2BAD1",
    biogen: "#BDD5EB",
    otsuka: "#C7D3EB",
    pfizer: "#B2B2EF",
    bayer: "#DCF2BF",
    regeneron: "#B2CEE2",
    chugai: "#B5CAE3",
    amgen: "#BCD0E4",
    gilead: "#EEB5C4",
    "daiichi sankyo": "#B4C9E0",
    other: "#fff",
  },
} as const;

export const FONT_SIZE_BY_RANK = {
  1: 24 + 10,
  2: 18 + 10,
  3: 14 + 10,
  4: 12 + 10,
  5: 11 + 10,
  6: 10 + 18,
  7: 6 + 18,
};

export const LINE_HEIGHT_BY_RANK = {
  1: 27,
  2: 24,
  3: 15.6,
  4: 15.6,
  5: 14.3,
  6: 13,
  7: 10,
};
