import React from "react";

import * as S from "./ArticleDetailCompanyProfileTable.style";
import {
  CompanyImage,
  CountryFlag,
  TableContainer,
  TableRowContainer,
  TableShellTitle,
  TableShellValue,
  useCheckWindowSize,
} from "../../../../shared";
import { IconExternalLink } from "../../../../shared/Icons";

type Props = {
  tableData: {
    companyName: string;
    summary: string;
    pressCompanyId: number;
    companyTypeCategory: string;
    companyTypeBusiness: string;
    ticker: string;
    yearOfEstablishment: string;
    location: string;
    country: string;
    siteUrl: string;
    companyLogoUri: string;
    countryCode: string | null;
  };
  isEditMode?: boolean;
  isVentureCapital?: boolean;
};

const DEFAULT_EMPTY_TEXT = "-";

const TickerValue = ({ ticker }: { ticker: string }) => {
  if (ticker === "") return DEFAULT_EMPTY_TEXT;
  const tickerValueToArray = [
    ...new Set(ticker.split("__").filter((value) => value !== "")),
  ];

  if (tickerValueToArray.length === 0) return DEFAULT_EMPTY_TEXT;
  return (
    <S.TickerValueWrapper
      className={`is-bullet-list-${tickerValueToArray.length > 1}`}
    >
      {tickerValueToArray.map((value) => (
        <S.TickerValueLink
          key={value}
          href={`https://www.google.com/search?q=${value}`}
          target="_blank"
          rel="noreferrer"
        >
          <S.TickerText>{value}</S.TickerText>
          <IconExternalLink width={20} height={20} />
        </S.TickerValueLink>
      ))}
    </S.TickerValueWrapper>
  );
};

const CountryValue = ({
  location,
  country,
  countryCode,
}: {
  location: string;
  country: string;
  countryCode: string | null;
}) => {
  const textValue = [location.trim(), country.trim()]
    .filter((value) => value !== "")
    .join(", ");
  if (textValue === "") return DEFAULT_EMPTY_TEXT;
  return (
    <S.CountryValueWrapper>
      <S.CountryFlagWrapper>
        <CountryFlag
          countryCode={countryCode || "empty"}
          isTopMargin
          style={{
            width: "auto",
            height: "1.8rem",
          }}
        />
      </S.CountryFlagWrapper>

      <S.CountryText>{textValue}</S.CountryText>
    </S.CountryValueWrapper>
  );
};

const bulletValue = (text: string, isNeedShell: boolean) => {
  if (!isNeedShell) return text;
  if (text === "") return DEFAULT_EMPTY_TEXT;

  const textList = text?.split("\n") || [];
  if (textList.length === 0) return DEFAULT_EMPTY_TEXT;
  if (textList.length === 1) return text;

  return (
    <S.BulletListWrapper>
      {textList.map((textValue) => (
        <S.BulletItem key={textValue}>{textValue}</S.BulletItem>
      ))}
    </S.BulletListWrapper>
  );
};

const COMPANY_PROFILE_SHELL_TITLE = {
  BUSINESS_STRUCTURE: "Business Structure",
  CATEGORIES_BY_INDUSTRY: "Categories by Industry",
  TICKER: "Ticker",
  LOCATION_COUNTRY: "Headquarters",
  YEAR_OF_ESTABLISHMENT: "Year of Establishment",
  SUMMARY: "Company Description",
};

const checkEmptyText = (value: string) => {
  if (value.trim() === "") return DEFAULT_EMPTY_TEXT;
  return value;
};

const ArticleDetailCompanyProfileTable = ({
  tableData,
  isEditMode = false,
  isVentureCapital = false,
}: Props) => {
  const { isMobileScreen, isTabletScreen, isDesktopScreen } =
    useCheckWindowSize();
  const VALUE_SHELL_PADDING = undefined;

  const COMPANY_PROFILE_SHELL_VALUES = {
    COMPANY_NAME: (
      <S.CompanyNameWrapper>
        <S.CompanyLink
          href={tableData.siteUrl ?? "#"}
          target="_blank"
          rel="noreferrer"
          className={`disable-link-${tableData.siteUrl === ""}`}
          onClick={(event) => {
            if (tableData.siteUrl === "") event.preventDefault();
          }}
          $isDisabled={tableData.siteUrl === ""}
        >
          <CompanyImage
            imageSize={30}
            containerSize={30}
            logoUrl={tableData.companyLogoUri}
          />
          <p>
            {tableData.companyName}
            <span>
              {!!tableData.siteUrl && (
                <IconExternalLink width={20} height={20} />
              )}
            </span>
          </p>
        </S.CompanyLink>
      </S.CompanyNameWrapper>
    ),
    BUSINESS_STRUCTURE: checkEmptyText(tableData.companyTypeBusiness),
    CATEGORIES_BY_INDUSTRY: checkEmptyText(tableData.companyTypeCategory),
    TICKER: <TickerValue ticker={tableData.ticker} />,
    LOCATION_COUNTRY: (
      <CountryValue
        location={tableData.location}
        country={tableData.country}
        countryCode={tableData.countryCode}
      />
    ),
    YEAR_OF_ESTABLISHMENT: checkEmptyText(tableData.yearOfEstablishment),
    SUMMARY: bulletValue(tableData.summary, isVentureCapital),
  } as const;

  const checkValueEmpty = (value: string) => value !== "";

  const IS_NOT_EMPTY_VALUE = {
    BUSINESS_STRUCTURE: checkValueEmpty(tableData.companyTypeBusiness),
    CATEGORIES_BY_INDUSTRY: checkValueEmpty(tableData.companyTypeCategory),
    TICKER: checkValueEmpty(tableData.ticker),
    LOCATION_COUNTRY: checkValueEmpty(
      [tableData.location, tableData.country]
        .filter((value) => value !== "")
        .join(", "),
    ),
    YEAR_OF_ESTABLISHMENT: checkValueEmpty(tableData.yearOfEstablishment),
  };

  const isFirstRowDataInDesktop =
    IS_NOT_EMPTY_VALUE.BUSINESS_STRUCTURE ||
    IS_NOT_EMPTY_VALUE.CATEGORIES_BY_INDUSTRY;

  const isSecondRowDataInDesktop =
    IS_NOT_EMPTY_VALUE.TICKER ||
    IS_NOT_EMPTY_VALUE.LOCATION_COUNTRY ||
    IS_NOT_EMPTY_VALUE.YEAR_OF_ESTABLISHMENT;

  const isSecondRowDataInTablet =
    IS_NOT_EMPTY_VALUE.TICKER || IS_NOT_EMPTY_VALUE.LOCATION_COUNTRY;

  const isThirdRowDataInTablet =
    COMPANY_PROFILE_SHELL_VALUES.YEAR_OF_ESTABLISHMENT !== DEFAULT_EMPTY_TEXT;

  if (isVentureCapital && isEditMode) {
    return (
      <S.TableWrapper $isEditMode={isEditMode}>
        {COMPANY_PROFILE_SHELL_VALUES.COMPANY_NAME}
        <TableContainer>
          {COMPANY_PROFILE_SHELL_VALUES.BUSINESS_STRUCTURE !==
            DEFAULT_EMPTY_TEXT &&
            COMPANY_PROFILE_SHELL_VALUES.CATEGORIES_BY_INDUSTRY !==
              DEFAULT_EMPTY_TEXT && (
              <>
                <TableRowContainer>
                  <TableShellTitle>
                    {COMPANY_PROFILE_SHELL_TITLE.BUSINESS_STRUCTURE}
                  </TableShellTitle>
                  <TableShellTitle>
                    {COMPANY_PROFILE_SHELL_TITLE.CATEGORIES_BY_INDUSTRY}
                  </TableShellTitle>
                </TableRowContainer>
                <TableRowContainer>
                  <TableShellValue
                    padding={VALUE_SHELL_PADDING}
                    className="company-profile-shell"
                  >
                    {COMPANY_PROFILE_SHELL_VALUES.BUSINESS_STRUCTURE}
                  </TableShellValue>
                  <TableShellValue
                    padding={VALUE_SHELL_PADDING}
                    className="company-profile-shell"
                  >
                    {COMPANY_PROFILE_SHELL_VALUES.CATEGORIES_BY_INDUSTRY}
                  </TableShellValue>
                </TableRowContainer>
              </>
            )}

          {COMPANY_PROFILE_SHELL_VALUES.TICKER !==
            (DEFAULT_EMPTY_TEXT as React.ReactNode) &&
            COMPANY_PROFILE_SHELL_VALUES.LOCATION_COUNTRY !==
              (DEFAULT_EMPTY_TEXT as React.ReactNode) &&
            COMPANY_PROFILE_SHELL_VALUES.YEAR_OF_ESTABLISHMENT !==
              (DEFAULT_EMPTY_TEXT as React.ReactNode) && (
              <>
                <TableRowContainer>
                  <TableShellTitle>
                    {COMPANY_PROFILE_SHELL_TITLE.TICKER}
                  </TableShellTitle>
                  <TableShellTitle>
                    {COMPANY_PROFILE_SHELL_TITLE.LOCATION_COUNTRY}
                  </TableShellTitle>
                  <TableShellTitle>
                    {COMPANY_PROFILE_SHELL_TITLE.YEAR_OF_ESTABLISHMENT}
                  </TableShellTitle>
                </TableRowContainer>

                <TableRowContainer>
                  <TableShellValue
                    padding={VALUE_SHELL_PADDING}
                    className="company-profile-shell"
                  >
                    {COMPANY_PROFILE_SHELL_VALUES.TICKER}
                  </TableShellValue>
                  <TableShellValue
                    padding={VALUE_SHELL_PADDING}
                    className="company-profile-shell"
                  >
                    {COMPANY_PROFILE_SHELL_VALUES.LOCATION_COUNTRY}
                  </TableShellValue>
                  <TableShellValue
                    padding={VALUE_SHELL_PADDING}
                    className="company-profile-shell"
                  >
                    {COMPANY_PROFILE_SHELL_VALUES.YEAR_OF_ESTABLISHMENT}
                  </TableShellValue>
                </TableRowContainer>
              </>
            )}
          <>
            <TableRowContainer>
              <TableShellTitle>
                {COMPANY_PROFILE_SHELL_TITLE.SUMMARY}
              </TableShellTitle>
            </TableRowContainer>
            <TableRowContainer>
              <TableShellValue
                padding={VALUE_SHELL_PADDING}
                className="company-profile-shell"
              >
                {COMPANY_PROFILE_SHELL_VALUES.SUMMARY || DEFAULT_EMPTY_TEXT}
              </TableShellValue>
            </TableRowContainer>
          </>
        </TableContainer>
      </S.TableWrapper>
    );
  }

  if (isEditMode || isDesktopScreen)
    return (
      <S.TableWrapper $isEditMode={isEditMode}>
        {COMPANY_PROFILE_SHELL_VALUES.COMPANY_NAME}
        <TableContainer>
          {isFirstRowDataInDesktop && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.BUSINESS_STRUCTURE}
                </TableShellTitle>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.CATEGORIES_BY_INDUSTRY}
                </TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.BUSINESS_STRUCTURE}
                </TableShellValue>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.CATEGORIES_BY_INDUSTRY}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {isSecondRowDataInDesktop && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.TICKER}
                </TableShellTitle>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.LOCATION_COUNTRY}
                </TableShellTitle>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.YEAR_OF_ESTABLISHMENT}
                </TableShellTitle>
              </TableRowContainer>

              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.TICKER}
                </TableShellValue>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.LOCATION_COUNTRY}
                </TableShellValue>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.YEAR_OF_ESTABLISHMENT}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {!!COMPANY_PROFILE_SHELL_VALUES.SUMMARY && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.SUMMARY}
                </TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.SUMMARY}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
        </TableContainer>
      </S.TableWrapper>
    );
  if (isTabletScreen) {
    return (
      <S.TableWrapper>
        {COMPANY_PROFILE_SHELL_VALUES.COMPANY_NAME}
        <TableContainer>
          {isFirstRowDataInDesktop && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.BUSINESS_STRUCTURE}
                </TableShellTitle>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.CATEGORIES_BY_INDUSTRY}
                </TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.BUSINESS_STRUCTURE}
                </TableShellValue>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.CATEGORIES_BY_INDUSTRY}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {isSecondRowDataInTablet && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.TICKER}
                </TableShellTitle>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.LOCATION_COUNTRY}
                </TableShellTitle>
              </TableRowContainer>

              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.TICKER}
                </TableShellValue>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.LOCATION_COUNTRY}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {isThirdRowDataInTablet && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.YEAR_OF_ESTABLISHMENT}
                </TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.YEAR_OF_ESTABLISHMENT}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {!!COMPANY_PROFILE_SHELL_VALUES.SUMMARY && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.SUMMARY}
                </TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.SUMMARY}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
        </TableContainer>
      </S.TableWrapper>
    );
  }
  if (isMobileScreen) {
    return (
      <S.TableWrapper>
        {COMPANY_PROFILE_SHELL_VALUES.COMPANY_NAME}
        <TableContainer>
          {IS_NOT_EMPTY_VALUE.BUSINESS_STRUCTURE && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.BUSINESS_STRUCTURE}
                </TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.BUSINESS_STRUCTURE}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {IS_NOT_EMPTY_VALUE.CATEGORIES_BY_INDUSTRY && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.CATEGORIES_BY_INDUSTRY}
                </TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.CATEGORIES_BY_INDUSTRY}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {IS_NOT_EMPTY_VALUE.TICKER && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.TICKER}
                </TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.TICKER}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {IS_NOT_EMPTY_VALUE.LOCATION_COUNTRY && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.LOCATION_COUNTRY}
                </TableShellTitle>
              </TableRowContainer>

              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.LOCATION_COUNTRY}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}

          {IS_NOT_EMPTY_VALUE.YEAR_OF_ESTABLISHMENT && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.YEAR_OF_ESTABLISHMENT}
                </TableShellTitle>
              </TableRowContainer>

              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.YEAR_OF_ESTABLISHMENT}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
          {!!COMPANY_PROFILE_SHELL_VALUES.SUMMARY && (
            <>
              <TableRowContainer>
                <TableShellTitle>
                  {COMPANY_PROFILE_SHELL_TITLE.SUMMARY}
                </TableShellTitle>
              </TableRowContainer>
              <TableRowContainer>
                <TableShellValue
                  padding={VALUE_SHELL_PADDING}
                  className="company-profile-shell"
                >
                  {COMPANY_PROFILE_SHELL_VALUES.SUMMARY}
                </TableShellValue>
              </TableRowContainer>
            </>
          )}
        </TableContainer>
      </S.TableWrapper>
    );
  }
};

export default ArticleDetailCompanyProfileTable;
