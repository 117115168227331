import { useEffect, useMemo } from "react";

import {
  CROSS_LICENSE_DEAL_TERMS_DIRECTION,
  DEAL_TYPE_KEYS,
  ENTRY_DATA_LICENSEE_TABLE,
  ENTRY_DATA_SECTION_KEYS,
} from "@frontend/common";

import { ArticleEntryData } from "@/types/articleEntryData.types";
import { DealsSection } from "@/types/deals.types";
import { BASIC_DATA_FOR_ADDING_TABLES } from "@FillInTableContainer/shared/constants/tableCreatorTable.constants";
import { useCurrentDealTypeValue } from "@FillInTableContainer/shared/context/CurrentDealTypeContext";
import { createAttributionId } from "@FillInTableContainer/shared/utils/createAttributionId";
import { useUpdateEntryData } from "@hooks/useUpdateArticleData";
import { useEntryDataState } from "@stores/entryData";
import { useInitEntryDataValue } from "@stores/initEntryData";
import { deepCopy } from "@utils/deepCopy";
import { getPressIdFromURL } from "@utils/getStateFromURL";

type Props = {
  groupTableKey: DealsSection;
};

/** NOTE:
 * 1. deal type이 cross license가 아닌 경우 return.
 * 2. license table이 2개 이상인지 확인.
 * 3. 2개 이상이 아닌 경우 2개로 테이블 생성
 * 4. 2개 이상인 경우 각 테이블 마다 attributionInfo 객체 내의 dealDirection이 있는지 확인.
 * 5. [0], [1]번 이외의 테이블에 dealDirection이 있는지 확인.
 * 6. 없는 경우 생성. [0]번 - B -> A, [1]번 - A -> B
 * 7. [0], [1]번 이외의 테이블에 있는 dealDirection 소거
 */

export const useSetCrossLicenseLayout = ({ groupTableKey }: Props) => {
  const draftArticleId = getPressIdFromURL();
  const [entryData, setEntryData] = useEntryDataState(draftArticleId);
  const { currentDealTypeList } = useCurrentDealTypeValue();
  const initEntryData = useInitEntryDataValue(draftArticleId);
  const updateEntryData = useUpdateEntryData();
  const articleSection = ENTRY_DATA_SECTION_KEYS.deals;

  const isLayoutValid = useMemo(() => {
    if (!entryData?.Deals) return false;
    if (!currentDealTypeList.includes(DEAL_TYPE_KEYS.crossLicenseAgreement))
      return true;

    const licenseTable = entryData.Deals?.[ENTRY_DATA_LICENSEE_TABLE];

    // 테이블이 2개 이상 있는지 확인
    if (!licenseTable || licenseTable.length < 2) return false;

    // dealDirection이 올바르게 설정되어 있는지 확인
    const isFirstTwoTablesCorrect =
      licenseTable[0]?.attributionInfo?.dealDirection ===
        CROSS_LICENSE_DEAL_TERMS_DIRECTION.partnerB &&
      licenseTable[1]?.attributionInfo?.dealDirection ===
        CROSS_LICENSE_DEAL_TERMS_DIRECTION.partnerA;

    // 나머지 테이블에 dealDirection이 없는지 확인
    const isOtherTablesCorrect = licenseTable
      .slice(2)
      .every((table) => !table.attributionInfo?.dealDirection);

    return isFirstTwoTablesCorrect && isOtherTablesCorrect;
  }, [entryData?.Deals, currentDealTypeList]);

  const returnSetDealDirection = (tableIndex: number) => {
    if (tableIndex === 0) return CROSS_LICENSE_DEAL_TERMS_DIRECTION.partnerB;
    if (tableIndex === 1) return CROSS_LICENSE_DEAL_TERMS_DIRECTION.partnerA;
    return undefined;
  };

  const addLicenseTableDealDirection = (data: ArticleEntryData) => {
    const dealData = data[ENTRY_DATA_SECTION_KEYS.deals];
    const licenseTablePath = dealData?.[ENTRY_DATA_LICENSEE_TABLE];

    const updateDealData = {
      ...data,
      [ENTRY_DATA_SECTION_KEYS.deals]: {
        ...dealData,
        [ENTRY_DATA_LICENSEE_TABLE]: licenseTablePath?.map(
          (table, tableIndex) => {
            const dealDirection = returnSetDealDirection(tableIndex);

            return {
              ...table,
              attributionInfo: {
                attributionId:
                  table.attributionInfo?.attributionId ?? createAttributionId(),
                ...(dealDirection && { dealDirection }),
              },
            };
          },
        ),
      },
    };

    updateEntryData(updateDealData);
    setEntryData(updateDealData);
  };

  const checkHaveDealDirection = (dealData: ArticleEntryData) => {
    const licenseTable = dealData.Deals?.[ENTRY_DATA_LICENSEE_TABLE];

    // NOTE: 0, 1번 테이블의 dealDirection 확인
    const isFirstTwoTablesHaveCorrectDirection =
      licenseTable?.[0]?.attributionInfo?.dealDirection ===
        CROSS_LICENSE_DEAL_TERMS_DIRECTION.partnerB &&
      licenseTable?.[1]?.attributionInfo?.dealDirection ===
        CROSS_LICENSE_DEAL_TERMS_DIRECTION.partnerA;

    // NOTE: 나머지 테이블들에 dealDirection이 없는지 확인
    const isOtherTablesHaveNoDirection = licenseTable
      ?.slice(2)
      .every((table) => !table.attributionInfo?.dealDirection);

    if (isFirstTwoTablesHaveCorrectDirection && isOtherTablesHaveNoDirection)
      return;

    addLicenseTableDealDirection(dealData);
  };

  const addFirstLicenseTable = () => {
    const targetTableListInEntryData =
      deepCopy(entryData?.[articleSection]?.[groupTableKey]) || [];

    if (!Array.isArray(targetTableListInEntryData)) return;

    const newTable =
      BASIC_DATA_FOR_ADDING_TABLES[articleSection]?.[groupTableKey](
        initEntryData,
      );

    const addTableData = [newTable, ...targetTableListInEntryData];

    const updatedEntryData = {
      ...entryData,
      [articleSection]: {
        ...entryData[articleSection],
        [groupTableKey]: addTableData,
      },
    };

    updateEntryData(updatedEntryData);
    setEntryData(updatedEntryData);

    checkHaveDealDirection(updatedEntryData);
  };

  const checkHaveTwoLicenseTable = (data: ArticleEntryData) => {
    const dealData = data[ENTRY_DATA_SECTION_KEYS.deals];

    if (!currentDealTypeList.includes(DEAL_TYPE_KEYS.crossLicenseAgreement))
      return;

    const licenseTableList = dealData?.[ENTRY_DATA_LICENSEE_TABLE];

    if (licenseTableList && licenseTableList.length >= 2)
      return checkHaveDealDirection(data);

    addFirstLicenseTable();
  };

  useEffect(() => {
    if (!entryData?.Deals) return;
    if (!currentDealTypeList.includes(DEAL_TYPE_KEYS.crossLicenseAgreement)) {
      return;
    }
    checkHaveTwoLicenseTable(entryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDealTypeList]);

  return { isLayoutValid };
};
